import React, { FC, useCallback, useMemo } from 'react';
import { Button, Col, Form, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import {
  AsyncSelectComponent,
  FORM_INSURANCE_APPROVAL_ADD_DIAGNOSIS_FORM,
  FORMS_CREATE_INSURANCE_REQUEST,
  required,
} from 'app/shared';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { ICD10Code } from 'app/types';
import { useFetchOptions } from 'app/features/doctorAssessment/components/billingSection/forms/createInsuranceRequest/forms/helpers';
import { useIcd10OptionFormatter } from 'app/features/doctorAssessment/components/billingSection/forms/createInsuranceRequest/forms/helpers/useIcd10OptionFormatter';
import { AddInsuranceDiagnosisFormData } from 'app/features/doctorAssessment/components/billingSection/forms/createInsuranceRequest/forms/types';

interface Props {
  insuranceId: string | undefined;
}

const FormComponent: FC<Props & InjectedFormProps<AddInsuranceDiagnosisFormData, Props>> = ({
  handleSubmit,
  submitting,
  pristine,
  insuranceId,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const insuranceDiagnosis: ICD10Code[] | undefined = useAppSelector(
    (state) => state.form[FORMS_CREATE_INSURANCE_REQUEST]?.values?.insuranceDiagnosis,
  );
  const icd10Codes = useMemo(() => insuranceDiagnosis?.map((item) => item.icd10Code), [insuranceDiagnosis]);

  // Handlers
  const onClose = useCallback(() => {
    dispatch(toggleModal('addInsuranceDiagnosis', false));
  }, [dispatch]);

  // Helper Hooks
  const { fetchIcd10CodeOptions } = useFetchOptions(insuranceId, icd10Codes);
  const formatIcd10Option = useIcd10OptionFormatter();

  return (
    <Form onSubmit={handleSubmit}>
      <ModalHeader toggle={onClose}>{formatMessage({ id: 'TREATMENT-PLAN.TEXT.ADD-DIAGNOSIS' })}</ModalHeader>
      <ModalBody>
        <Row xs={1}>
          <Col>
            <Field
              component={AsyncSelectComponent}
              fetchOptions={fetchIcd10CodeOptions}
              formatOptionLabel={formatIcd10Option}
              isClearable
              isRequired
              label={formatMessage({ id: 'TREATMENT-PLAN.LABEL.ICD10-CODE' })}
              name="icd10Code"
              noOptionsMessage={formatMessage({ id: 'CORE.TEXT.NO-OPTIONS' })}
              placeholder={formatMessage({ id: 'CORE.PLACEHOLDER.SELECT' })}
              validate={[required]}
              isMulti
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button type="button" disabled={submitting} color="secondary" onClick={onClose}>
          {formatMessage({ id: 'CORE.BUTTON.CANCEL' })}
        </Button>
        <Button type="submit" disabled={submitting || pristine} color="primary-gradient">
          {formatMessage({ id: 'CORE.BUTTON.ADD' })}
        </Button>
      </ModalFooter>
    </Form>
  );
};

export const InsuranceApprovalAddDiagnosisForm = reduxForm<AddInsuranceDiagnosisFormData, Props>({
  form: FORM_INSURANCE_APPROVAL_ADD_DIAGNOSIS_FORM,
})(FormComponent);
