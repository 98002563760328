import { useCallback } from 'react';
import { push } from 'connected-react-router';
import { change, reset } from 'redux-form';
import { useAppDispatch, useCurrentUser } from 'app/helpers';
import { FORMS_CALENDAR_DAY_FILTER, FORMS_CALENDAR_WEEK_FILTER } from 'app/shared';
import { PatientSearchField } from 'app/shared/patientSearch/types';
import { setPreselectedPatient } from 'app/redux/appointment/appointment.actions';
import { PreselectedPatient } from 'app/redux/appointment/types';
import { setPatient } from 'app/redux/book-appointment/book-appointment.actions';
import { resetCalendar } from 'app/redux/calendar/calendar.actions';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { reset as resetRemoteData } from 'app/redux/remoteData/remoteData.actions';
import { formConfig } from 'app/features/bookAppointment/shared';

export const useCreateAppointmentForPreselectedPatient = () => {
  const dispatch = useAppDispatch();
  const { isAdminGroup } = useCurrentUser();

  return useCallback(
    (patient: PreselectedPatient) => {
      dispatch(setPreselectedPatient(patient));
      if (isAdminGroup) {
        dispatch(resetCalendar());
        dispatch(reset(FORMS_CALENDAR_DAY_FILTER));
        dispatch(reset(FORMS_CALENDAR_WEEK_FILTER));
        dispatch(resetRemoteData('dayCalendars'));
        dispatch(resetRemoteData('weekCalendar'));
        dispatch(push(`/calendar/week`));
      } else {
        const defaultNumber = patient.phoneNumbers.find((number) => number.isDefault) || patient.phoneNumbers[0];
        dispatch(setPatient(patient));
        dispatch(change(formConfig.form, PatientSearchField.patientId, patient.id));
        dispatch(
          change(formConfig.form, PatientSearchField.selectedPhoneNumber, {
            label: defaultNumber.phoneNumber,
            value: defaultNumber.phoneNumber,
          }),
        );
        dispatch(toggleModal('bookAppointment', true));
      }
    },
    [dispatch, isAdminGroup],
  );
};
