import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Col,
  Fade,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap';
import { RowProps } from 'reactstrap/types/lib/Row';
import { BaseFieldProps, Field, FieldArray, Form, reduxForm, submit } from 'redux-form';
import { WrappedFieldArrayProps } from 'redux-form/lib/FieldArray';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { useAppDispatch, useAppIntl, useDate, useModalState } from 'app/helpers';
import {
  AsyncSelectComponent,
  ConfirmationModal,
  DatePickerComponent,
  InputComponent,
  Loader,
  PhoneNumber,
  PhoneNumberProps,
  SelectComponent,
} from 'app/shared';
import { PatientEmergencyContactRelation, PatientMaritalStatus, PatientPhoneNumber } from 'app/types';
import { asyncValidate } from 'app/features/patient/patientForm/asyncValidate';
import {
  PrimaryPatientsFieldArray,
  PrimaryPatientsFieldArrayProps,
} from 'app/features/patient/patientForm/fields/primaryPatientsFieldArrayTable/primaryPatientsFieldArrayTable';
import { getIsRequiredFlags } from 'app/features/patient/patientForm/helpers/getIsRequiredFlags';
import { useAutoTranslate } from 'app/features/patient/patientForm/helpers/useAutoTranslate';
import { useChange } from 'app/features/patient/patientForm/helpers/useChange';
import { useCheckRequiredPatientData } from 'app/features/patient/patientForm/helpers/useCheckRequiredPatientData';
import { useFetchOptions } from 'app/features/patient/patientForm/helpers/useFetchOptions';
import { useFormValues } from 'app/features/patient/patientForm/helpers/useFormValues';
import { useOptions } from 'app/features/patient/patientForm/helpers/useOptions';
import { useSourceOfPatientFormatter } from 'app/features/patient/patientForm/helpers/useSourceOfPatientFormatter';
import {
  PatientFormData,
  PatientFormErrorType,
  PatientFormField,
  PrimaryPatientBaseInfo,
} from 'app/features/patient/patientForm/types';
import { MobileNumbersFieldArray } from 'app/features/patients/mergeProfiles/mobileNumbersFieldArray/mobileNumbersFieldArray';

export interface PatientFormProps {
  clinicId?: string;
  clinicPatientId?: string;
  closeModal: () => void;
  isDependent: boolean;
  isEdit: boolean;
  isFetchingPatientData?: boolean;
  isRequesting: boolean;
  isVerify?: boolean;
  onSubmit: (values: PatientFormData) => void;
  patientId?: string;
}

const FormComponent: FC<
  PatientFormProps & InjectedFormProps<PatientFormData, PatientFormProps, PatientFormErrorType>
> = ({
  change,
  clinicId,
  closeModal,
  form,
  handleSubmit,
  initialValues,
  isDependent,
  isEdit,
  isFetchingPatientData,
  isRequesting,
  isVerify,
  onSubmit,
  patientId,
  pristine,
  submitting,
  touch,
}) => {
  const { formatMessage } = useAppIntl();
  const {
    firstNameEnValue,
    firstNameArValue,
    grandFatherNameArValue,
    grandFatherNameEnValue,
    fatherNameArValue,
    fatherNameEnValue,
    familyNameArValue,
    familyNameEnValue,
    preferredNameArValue,
    preferredNameEnValue,
    selectedMaritalStatus,
    selectedGovernmentIdType,
    selectedEmergencyRelation,
    emergencyContactName,
    mobileNumbers,
  } = useFormValues(form);
  const {
    isOpen: isDefaultNumberWarningOpen,
    open: openDefaultNumberWarning,
    close: closeDefaultNumberWarning,
  } = useModalState();
  const dispatch = useAppDispatch();
  const [isAutoTranslateActive, setIsAutoTranslateActive] = useState<boolean>(false);

  const { isGrandFatherNameRequired, isNationalIdRequired, isFatherNameRequired, checkingPatientDataRequirements } =
    useCheckRequiredPatientData(clinicId);

  const namesSectionValues: Partial<PatientFormData> = {
    firstNameAr: firstNameArValue,
    familyNameAr: familyNameArValue,
    fatherNameAr: fatherNameArValue,
    grandFatherNameAr: grandFatherNameArValue,
    firstNameEn: firstNameEnValue,
    familyNameEn: familyNameEnValue,
    fatherNameEn: fatherNameEnValue,
    grandFatherNameEn: grandFatherNameEnValue,
  };

  const {
    isFirstNameArRequired,
    isFirstNameEnRequired,
    isFamilyNameArRequired,
    isFamilyNameEnRequired,
    isFatherNameArRequired,
    isFatherNameEnRequired,
    isGrandFatherNameArRequired,
    isGrandFatherNameEnRequired,
  } = getIsRequiredFlags(namesSectionValues, isFatherNameRequired, isGrandFatherNameRequired);

  // Helper Variables
  const patientModalHeaderTextId = isEdit ? 'PATIENTS.TEXT.EDIT-PATIENT' : 'PATIENTS.TEXT.ADD-NEW-PATIENT';
  const patientSubmitButtonTextId = isEdit ? 'CORE.BUTTON.UPDATE' : 'PATIENTS.BUTTON.ADD-PATIENT';
  const dependentModalHeaderTextId = isEdit ? 'PATIENTS.TEXT.EDIT-DEPENDENT' : 'PATIENTS.TEXT.ADD-DEPENDENT';
  const dependentSubmitButtonTextId = isEdit ? 'CORE.BUTTON.UPDATE' : 'PATIENTS.BUTTON.ADD-DEPENDENT';
  const rowProps: RowProps = { xs: 1, lg: 2, xl: 3 };
  const autoTranslateId = 'autoTranslate';

  // Helper Hooks
  const autoTranslate = useAutoTranslate(form, isAutoTranslateActive);
  const {
    onEmergencyContactNameChange,
    onEmergencyContactRelationChange,
    onMaritalStatusChange,
    onGovernmentIdTypeChange,
  } = useChange(change);
  const { todayStart } = useDate();

  const {
    cityOptions,
    genderOptions,
    languageOptions,
    martialStatusOptions,
    nationalityOptions,
    occupationOptions,
    emergencyRelations,
    governmentIssuedTypeOptions,
  } = useOptions();
  const { fetchPatientCategories, fetchPatientSources } = useFetchOptions(clinicId);
  const formatSourceOfPatientLabel = useSourceOfPatientFormatter();

  // Conditions
  const isEmergencyContactName = !!emergencyContactName?.trim();
  const isOtherRelationSelected = selectedEmergencyRelation?.value === PatientEmergencyContactRelation.Other;
  const isOtherMaritalStatusSelected = selectedMaritalStatus?.value === PatientMaritalStatus.Other;
  const isSubmitDisabled =
    submitting || pristine || isRequesting || isFetchingPatientData || checkingPatientDataRequirements;
  const isVerifySubmitDisabled = isRequesting || isFetchingPatientData || checkingPatientDataRequirements;
  const isClinic = !!clinicId;
  const hasPreferredName = !!preferredNameArValue?.trim() || !!preferredNameEnValue?.trim();

  useEffect(() => {
    if (isVerify) {
      touch(PatientFormField.firstNameAr);
      touch(PatientFormField.firstNameEn);
      touch(PatientFormField.fatherNameAr);
      touch(PatientFormField.fatherNameEn);
      touch(PatientFormField.dateOfBirth);
      touch(PatientFormField.gender);
      touch(PatientFormField.governmentIssuedIdNumber);
      touch(PatientFormField.typeOfGovernmentIssuedId);
    }
  }, [isVerify, touch]);

  const onConfirm = useCallback(() => {
    dispatch(submit(form));
  }, [dispatch, form]);

  const customOnSubmit = useCallback<(values: PatientFormData) => void>(
    (values) => {
      const initialDefaultNumber = initialValues?.patientMobileNumbers?.find((number) => number.isDefault);
      const defaultNumber = mobileNumbers?.find((number: PatientPhoneNumber) => number.isDefault);

      if (!isDefaultNumberWarningOpen && initialDefaultNumber?.phoneNumber !== defaultNumber?.phoneNumber && isClinic) {
        openDefaultNumberWarning();
      } else {
        onSubmit(values);
      }
    },
    [
      initialValues?.patientMobileNumbers,
      isDefaultNumberWarningOpen,
      mobileNumbers,
      onSubmit,
      openDefaultNumberWarning,
      isClinic,
    ],
  );

  return (
    <Form onSubmit={handleSubmit(customOnSubmit)}>
      <ModalHeader toggle={closeModal}>
        <span className="me-2">
          {isVerify
            ? formatMessage({ id: 'PATIENTS.TEXT.PATIENT-VERIFICATION' })
            : formatMessage({
                id: isDependent ? dependentModalHeaderTextId : patientModalHeaderTextId,
              })}
        </span>
        {isEdit && patientId && (
          <Badge className="fs-6 me-2" color="light-primary">
            {patientId}
          </Badge>
        )}
      </ModalHeader>
      <ModalBody>
        {isFetchingPatientData ? (
          <Loader />
        ) : (
          <>
            <div className="d-block d-lg-flex mb-2">
              <h4 className="me-3">{formatMessage({ id: 'CORE.LABEL.NAMES' })}</h4>
              <FormGroup switch inline>
                <Input
                  checked={isAutoTranslateActive}
                  id={autoTranslateId}
                  onChange={() => {
                    setIsAutoTranslateActive(!isAutoTranslateActive);
                  }}
                  role="switch"
                  type="checkbox"
                />
                <Label className="text-nowrap" for={autoTranslateId}>
                  {formatMessage({ id: 'CORE.LABEL.AUTO-TRANSLATE' })}
                </Label>
              </FormGroup>
              {isAutoTranslateActive && (
                <Fade className="mb-3 mb-lg-0" tag="div">
                  <Badge color="light-danger">{formatMessage({ id: 'CORE.TEXT.TRANSLATION-NOT-ACCURATE' })}</Badge>
                </Fade>
              )}
            </div>
            <Row {...rowProps}>
              <Col>
                <Field
                  name={PatientFormField.firstNameAr}
                  component={InputComponent}
                  isRequired={isFirstNameArRequired}
                  type="text"
                  label={formatMessage({ id: 'CORE.LABEL.FIRST-NAME-AR' })}
                  isRtl
                  onBlur={autoTranslate}
                />
              </Col>
              <Col>
                <Field
                  name={PatientFormField.fatherNameAr}
                  component={InputComponent}
                  isRequired={isFatherNameArRequired}
                  type="text"
                  label={formatMessage({ id: 'CORE.LABEL.FATHER-NAME-AR' })}
                  isRtl
                  onBlur={autoTranslate}
                />
              </Col>
              <Col>
                <Field
                  name={PatientFormField.grandFatherNameAr}
                  component={InputComponent}
                  type="text"
                  isRequired={isGrandFatherNameArRequired}
                  label={formatMessage({ id: 'CORE.LABEL.GRAND-FATHER-NAME-AR' })}
                  isRtl
                  onBlur={autoTranslate}
                />
              </Col>
              <Col>
                <Field
                  name={PatientFormField.familyNameAr}
                  component={InputComponent}
                  type="text"
                  label={formatMessage({ id: 'CORE.LABEL.FAMILY-NAME-AR' })}
                  isRequired={isFamilyNameArRequired}
                  isRtl
                  onBlur={autoTranslate}
                />
              </Col>
              <Col>
                <Field
                  name={PatientFormField.preferredNameAr}
                  component={InputComponent}
                  type="text"
                  label={formatMessage({ id: 'CORE.LABEL.PREFERRED-NAME-AR' })}
                  isRequired={hasPreferredName}
                  isRtl={true}
                  onBlur={autoTranslate}
                />
              </Col>
            </Row>
            <hr className="mt-2 mb-3" />

            <Row {...rowProps}>
              <Col>
                <Field
                  name={PatientFormField.firstNameEn}
                  component={InputComponent}
                  isRequired={isFirstNameEnRequired}
                  type="text"
                  label={formatMessage({ id: 'CORE.LABEL.FIRST-NAME-EN' })}
                  isRtl={false}
                  onBlur={autoTranslate}
                />
              </Col>
              <Col>
                <Field
                  name={PatientFormField.fatherNameEn}
                  component={InputComponent}
                  isRequired={isFatherNameEnRequired}
                  type="text"
                  label={formatMessage({ id: 'CORE.LABEL.FATHER-NAME-EN' })}
                  isRtl={false}
                  onBlur={autoTranslate}
                />
              </Col>
              <Col>
                <Field
                  name={PatientFormField.grandFatherNameEn}
                  component={InputComponent}
                  type="text"
                  isRequired={isGrandFatherNameEnRequired}
                  label={formatMessage({ id: 'CORE.LABEL.GRAND-FATHER-NAME-EN' })}
                  isRtl={false}
                  onBlur={autoTranslate}
                />
              </Col>
              <Col>
                <Field
                  name={PatientFormField.familyNameEn}
                  component={InputComponent}
                  type="text"
                  label={formatMessage({ id: 'CORE.LABEL.FAMILY-NAME-EN' })}
                  isRequired={isFamilyNameEnRequired}
                  isRtl={false}
                  onBlur={autoTranslate}
                />
              </Col>
              <Col>
                <Field
                  name={PatientFormField.preferredNameEn}
                  component={InputComponent}
                  type="text"
                  label={formatMessage({ id: 'CORE.LABEL.PREFERRED-NAME-EN' })}
                  isRequired={hasPreferredName}
                  isRtl={false}
                  onBlur={autoTranslate}
                />
              </Col>
            </Row>
            <h4 className="mt-2">{formatMessage({ id: 'CORE.TEXT.PERSONAL-DETAILS' })}</h4>
            <Row {...rowProps}>
              <Col>
                <Field
                  name={PatientFormField.email}
                  component={InputComponent}
                  type="email"
                  label={formatMessage({ id: 'CORE.LABEL.EMAIL' })}
                  isRtl={false}
                />
              </Col>
              {isClinic && (
                <Col>
                  <Field
                    name={PatientFormField.internalPatientFileNumber}
                    component={InputComponent}
                    type="text"
                    label={formatMessage({ id: 'CORE.LABEL.PATIENT-INTERNAL-FILE-NUMBER' })}
                  />
                </Col>
              )}
              <Col>
                <Field
                  name={PatientFormField.gender}
                  component={SelectComponent}
                  isRequired={!!isVerify}
                  label={formatMessage({ id: 'CORE.LABEL.GENDER' })}
                  options={genderOptions}
                />
              </Col>
              <Col>
                <Field
                  name={PatientFormField.dateOfBirth}
                  component={DatePickerComponent}
                  label={formatMessage({ id: 'CORE.LABEL.DATE-OF-BIRTH' })}
                  placeholder={formatMessage({ id: 'CORE.PLACEHOLDER.SELECT-DATE' })}
                  isRequired={!!isVerify}
                  maxDate={todayStart}
                />
              </Col>
              <Col>
                <Field
                  name={PatientFormField.nationality}
                  component={SelectComponent}
                  label={formatMessage({ id: 'CORE.LABEL.NATIONALITY' })}
                  options={nationalityOptions}
                />
              </Col>
              <Col>
                <Field
                  name={PatientFormField.typeOfGovernmentIssuedId}
                  component={SelectComponent}
                  disabled={!isClinic && isEdit}
                  label={formatMessage({ id: 'CORE.LABEL.GOVERNMENT-ID-TYPE' })}
                  options={governmentIssuedTypeOptions}
                  isClearable
                  isRequired={isNationalIdRequired || !!isVerify}
                  onChange={onGovernmentIdTypeChange}
                />
              </Col>
              <Col>
                <Field
                  name={PatientFormField.governmentIssuedIdNumber}
                  component={InputComponent}
                  disabled={(!isClinic && isEdit) || !selectedGovernmentIdType}
                  type="text"
                  label={formatMessage({ id: 'CORE.LABEL.GOVERNMENT-ID' })}
                  isRequired={isNationalIdRequired || !!isVerify || !!selectedGovernmentIdType}
                />
              </Col>
              <Col>
                <Field
                  name={PatientFormField.preferredLanguage}
                  component={SelectComponent}
                  label={formatMessage({ id: 'CORE.LABEL.PATIENT-PREFERRED-LANGUAGE' })}
                  options={languageOptions}
                  isRequired
                />
              </Col>
              <Col>
                <Field
                  name={PatientFormField.city}
                  component={SelectComponent}
                  label={formatMessage({ id: 'CORE.LABEL.CITY' })}
                  options={cityOptions}
                />
              </Col>
              <Col>
                <Field
                  name={PatientFormField.occupation}
                  component={SelectComponent}
                  label={formatMessage({ id: 'CORE.LABEL.OCCUPATION' })}
                  options={occupationOptions}
                />
              </Col>
              <Col>
                <Field
                  name={PatientFormField.maritalStatus}
                  component={SelectComponent}
                  label={formatMessage({ id: 'CORE.LABEL.MARITAL-STATUS' })}
                  options={martialStatusOptions}
                  onChange={onMaritalStatusChange}
                />
              </Col>
              {isOtherMaritalStatusSelected && (
                <Col>
                  <Field
                    name={PatientFormField.otherMaritalStatus}
                    component={InputComponent}
                    type="text"
                    label={formatMessage({ id: 'CORE.LABEL.OTHER-MARITAL-STATUS' })}
                    isRequired
                  />
                </Col>
              )}
              <Col>
                <Field
                  name={PatientFormField.patientClassification}
                  component={AsyncSelectComponent}
                  label={formatMessage({ id: 'CORE.LABEL.PATIENT-CLASSIFICATION' })}
                  fetchOptions={fetchPatientCategories}
                  isClearable
                />
              </Col>
            </Row>
            {!isVerify && (
              <>
                <h4 className="mt-2">{formatMessage({ id: 'CORE.TEXT.EMERGENCY-CONTACT-DETAILS' })}</h4>
                <Row {...rowProps}>
                  <Col>
                    <Field
                      name={PatientFormField.emergencyContactName}
                      component={InputComponent}
                      type="text"
                      label={formatMessage({ id: 'CORE.LABEL.EMERGENCY-CONTACT-NAME' })}
                      info={formatMessage({ id: 'CORE.TEXT.PATIENT-EMERGENCY-SECTION-INFO' })}
                      onChange={onEmergencyContactNameChange}
                    />
                  </Col>
                  <Col>
                    <Field
                      name={PatientFormField.emergencyContactRelation}
                      component={SelectComponent}
                      label={formatMessage({ id: 'CORE.LABEL.EMERGENCY-CONTACT-RELATION' })}
                      options={emergencyRelations}
                      isRequired={isEmergencyContactName}
                      disabled={!isEmergencyContactName}
                      onChange={onEmergencyContactRelationChange}
                    />
                  </Col>
                  {isOtherRelationSelected && isEmergencyContactName && (
                    <Col>
                      <Field
                        name={PatientFormField.otherEmergencyContactRelation}
                        component={InputComponent}
                        isRequired
                        type="text"
                        label={formatMessage({ id: 'CORE.LABEL.OTHER-CONTACT-RELATION' })}
                      />
                    </Col>
                  )}
                  <Col>
                    <Field<BaseFieldProps<PhoneNumberProps>>
                      name={PatientFormField.emergencyContactNumber}
                      component={PhoneNumber}
                      props={{
                        label: formatMessage({ id: 'CORE.LABEL.EMERGENCY-CONTACT-NUMBER' }),
                        disabled: !isEmergencyContactName,
                        isRequired: isEmergencyContactName,
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            {isClinic && !isVerify && (
              <>
                <h4 className="mt-2">{formatMessage({ id: 'CORE.TEXT.ADDITIONAL-INFORMATION' })}</h4>
                <Row>
                  <Col xs={12} lg={6} xl={4}>
                    <Field
                      name={PatientFormField.sourceOfPatient}
                      component={AsyncSelectComponent}
                      label={formatMessage({ id: 'CORE.LABEL.SOURCE-OF-PATIENT' })}
                      fetchOptions={fetchPatientSources}
                      formatOptionLabel={formatSourceOfPatientLabel}
                      isClearable
                    />
                  </Col>
                  <Col xs={12} lg={6} xl={8}>
                    <Field
                      name={PatientFormField.generalNote}
                      component={InputComponent}
                      type="text"
                      label={formatMessage({ id: 'CORE.LABEL.GENERAL-NOTE' })}
                    />
                  </Col>
                </Row>
              </>
            )}
            <h4 className="mt-2">
              {formatMessage({ id: 'CORE.LABEL.PATIENT-MOBILE-NUMBERS' })}
              <span className="text-danger me-2 ms-1">*</span>
            </h4>
            <Row xs={1} lg={2} xl={3}>
              <Col>
                <FieldArray<WrappedFieldArrayProps<PatientPhoneNumber>>
                  name={PatientFormField.patientMobileNumbers}
                  component={MobileNumbersFieldArray}
                  rerenderOnEveryChange
                />
              </Col>
            </Row>
            {isDependent && !isVerify && (
              <Row className="mt-4">
                <Col>
                  <FieldArray<PrimaryPatientsFieldArrayProps, PrimaryPatientBaseInfo>
                    name={PatientFormField.primaryPatients}
                    component={PrimaryPatientsFieldArray}
                    clinicId={clinicId}
                    rerenderOnEveryChange={true}
                  />
                </Col>
              </Row>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button disabled={submitting} color="secondary" onClick={closeModal} type="button">
          {formatMessage({ id: 'CORE.BUTTON.CANCEL' })}
        </Button>
        <Button disabled={!isVerify ? isSubmitDisabled : isVerifySubmitDisabled} color="primary-gradient" type="submit">
          {formatMessage({
            id: isVerify
              ? 'CORE.BUTTON.CONTINUE-ACTION'
              : isDependent
              ? dependentSubmitButtonTextId
              : patientSubmitButtonTextId,
          })}
          {submitting && <Spinner size="sm" className="buttonIcon buttonIcon--right" />}
        </Button>
      </ModalFooter>
      <ConfirmationModal
        description="PATIENTS.TEXT.DEFAULT-PHONE-NUMBER-NOTIFICATION"
        isOpen={isDefaultNumberWarningOpen}
        onCancel={closeDefaultNumberWarning}
        onClose={closeDefaultNumberWarning}
        onConfirm={onConfirm}
      />
    </Form>
  );
};

export const PatientForm = reduxForm<PatientFormData, PatientFormProps, PatientFormErrorType>({
  asyncValidate,
  asyncBlurFields: [PatientFormField.governmentIssuedIdNumber],
  enableReinitialize: true,
  touchOnChange: true,
})(FormComponent);
