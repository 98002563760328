import { createApi } from '@reduxjs/toolkit/query/react';
import type { AdminUser, ClinicUser, QueryParams, QueryResponseWithPagination, UserLog } from 'app/types';
import { baseQueryWithReAuth } from 'app/helpers';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['AdminUsers', 'ClinicUsers', 'UserLog'],
  endpoints: (builder) => ({
    // Admin Users
    getAdminUsers: builder.query<QueryResponseWithPagination<AdminUser[]>, QueryParams | undefined>({
      query: (params) => ({
        url: 'admins',
        method: 'GET',
        params,
      }),
      providesTags: ['AdminUsers'],
    }),

    // Clinic Users
    getClinicUsers: builder.query<
      QueryResponseWithPagination<ClinicUser[]>,
      { clinicId: string; params: QueryParams | undefined }
    >({
      query: ({ clinicId, params }) => ({
        url: `clinics/${clinicId}/users`,
        method: 'GET',
        params,
      }),
      providesTags: ['ClinicUsers'],
    }),

    //User Log
    getUserLogs: builder.query<
      QueryResponseWithPagination<UserLog[]>,
      { userId: string; params: QueryParams | undefined }
    >({
      query: ({ params, userId }) => ({
        url: `/users/${userId}/logs`,
        params,
      }),
      providesTags: ['UserLog'],
    }),
  }),
});

export const { useGetAdminUsersQuery, useGetClinicUsersQuery, useGetUserLogsQuery } = usersApi;
