import React, { useCallback } from 'react';
import { OptionContext } from 'react-select';
import { useAppIntl } from 'app/helpers';
import { ExcludedStatus } from 'app/features/doctorAssessment/components/billingSection/formatters/excludedStatus';
import { ICD10CodeOption } from 'app/features/doctorAssessment/components/billingSection/forms/createInsuranceRequest/forms/types';

export const useIcd10OptionFormatter = () => {
  const { formatMessage } = useAppIntl();

  return useCallback(
    (option: ICD10CodeOption, meta: { context: OptionContext }) => {
      // Helper Variables
      const { label: icd10Code, details } = option;

      // Return - option selected
      if (meta.context === 'value') {
        return <span>{`${icd10Code} ${details.description}`}</span>;
      }

      // Return - option in menu
      return (
        <>
          <span className="mb-1 d-block fw-medium">
            {icd10Code} {details.description}
          </span>
          <div className="fw-light small">
            <div>
              {formatMessage({ id: 'CORE.LABEL.EXCLUSION-STATUS' })}:{' '}
              {<ExcludedStatus isExcluded={details.isExcluded} />}
            </div>
          </div>
        </>
      );
    },
    [formatMessage],
  );
};
