import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import { DoctorsQueryParams, DoctorSubSpecialityBodyParams } from 'app/services/doctors/types';
import { Doctor, Product, QueryParams, QueryResponse, QueryResponseWithPagination } from 'app/types';
import { PractitionerSubSpeciality } from 'app/doctor/sections/medicalSpecialitySection/types';

export const doctorsApi = createApi({
  reducerPath: 'doctorsApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Doctors', 'Doctor', 'DoctorProducts', 'DoctorSubSpecialities'],
  endpoints: (builder) => ({
    // [EP-1]
    getDoctor: builder.query<QueryResponse<Doctor>, { doctorId: string }>({
      query: ({ doctorId }) => ({
        url: `doctors/${doctorId}`,
        method: 'GET',
      }),
      providesTags: ['Doctor'],
    }),

    // [EP-2]
    getClinicDoctors: builder.query<
      QueryResponseWithPagination<Doctor[]>,
      { clinicId: string; params: DoctorsQueryParams }
    >({
      query: ({ clinicId, params }) => ({
        url: `/clinics/${clinicId}/doctors`,
        method: 'GET',
        params,
      }),
      providesTags: ['Doctors'],
    }),

    // [EP-3]
    getClinicDeletedDoctors: builder.query<
      QueryResponseWithPagination<Doctor[]>,
      { clinicId: string; params: DoctorsQueryParams }
    >({
      query: ({ clinicId, params }) => ({
        url: `/clinics/${clinicId}/doctors/deleted`,
        method: 'GET',
        params,
      }),
      providesTags: ['Doctors'],
    }),

    // [EP-4]
    getProductsTemplate: builder.query<Product[], { branchId: string; params: QueryParams | undefined }>({
      query: ({ branchId, params }) => ({
        url: `/branches/${branchId}/products/template`,
        params,
      }),
      providesTags: ['DoctorProducts'],
    }),

    // [EP-5]
    getWorkingTimeProducts: builder.query<Product[], { workingTimeId: string; params: QueryParams | undefined }>({
      query: ({ workingTimeId, params }) => ({
        url: `/working-hours/${workingTimeId}/products`,
        params,
      }),
      providesTags: ['DoctorProducts'],
    }),

    // [EP-6]
    getDoctorSubSpecialities: builder.query<
      QueryResponseWithPagination<PractitionerSubSpeciality[]>,
      { params: QueryParams | undefined; doctorId: string }
    >({
      query: ({ params, doctorId }) => ({
        method: 'GET',
        url: `/doctors/${doctorId}/subspecialities`,
        params,
      }),
      providesTags: ['DoctorSubSpecialities'],
    }),

    // [EP-7]
    editDoctorSpeciality: builder.mutation<void, { doctorId: string; body: { specialityId: string } }>({
      query: ({ doctorId, body }) => ({
        method: 'PUT',
        url: `/doctors/${doctorId}/speciality`,
        body,
      }),
      invalidatesTags: ['Doctor', 'DoctorSubSpecialities'],
    }),

    // [EP-8]
    deleteDoctorSubSpeciality: builder.mutation<void, { doctorId: string; subSpecialityId: string }>({
      query: ({ doctorId, subSpecialityId }) => ({
        method: 'DELETE',
        url: `/doctors/${doctorId}/subspecialities/${subSpecialityId}`,
      }),
      invalidatesTags: ['DoctorSubSpecialities'],
    }),

    // [EP-9]
    addDoctorSubSpeciality: builder.mutation<void, { doctorId: string; body: DoctorSubSpecialityBodyParams }>({
      query: ({ doctorId, body }) => ({
        method: 'POST',
        url: `/doctors/${doctorId}/subspecialities`,
        body,
      }),
      invalidatesTags: ['DoctorSubSpecialities'],
    }),

    // [EP-10]
    editDoctorSubSpeciality: builder.mutation<
      void,
      { doctorId: string; subSpecialityId: string; body: DoctorSubSpecialityBodyParams }
    >({
      query: ({ doctorId, subSpecialityId, body }) => ({
        method: 'PUT',
        url: `/doctors/${doctorId}/subspecialities/${subSpecialityId}`,
        body,
      }),
      invalidatesTags: ['DoctorSubSpecialities'],
    }),
  }),
});

export const {
  useGetDoctorQuery, // [EP-1]
  useGetClinicDoctorsQuery, // [EP-2]
  useGetClinicDeletedDoctorsQuery, // [EP-3]
  useGetProductsTemplateQuery, // [EP-4]
  useGetWorkingTimeProductsQuery, // [EP-5]
  useGetDoctorSubSpecialitiesQuery, // [EP-6]
  useEditDoctorSpecialityMutation, // [EP-7]
  useDeleteDoctorSubSpecialityMutation, // [EP-8]
  useAddDoctorSubSpecialityMutation, // [EP-9]
  useEditDoctorSubSpecialityMutation, // [EP-10]
} = doctorsApi;
