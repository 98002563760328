import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { Alert, Button, Col, Form, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import { BaseFieldProps, Field, reduxForm } from 'redux-form';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { Box, BoxContent } from 'app/components';
import { RemoteBootstrapTable } from 'app/components/bootstrapTable/remoteBootstrapTable/remoteBootstrapTable';
import {
  FORMS_PRE_SEARCH_CLINIC_PATIENT,
  Loader,
  phoneNumber,
  PhoneNumber,
  PhoneNumberProps,
  required,
} from 'app/shared';
import { ClinicPatientShortInfo } from 'app/services/patients/types';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { QueryParams, QueryResponseWithPagination } from 'app/types';
import { useColumns } from 'app/features/patient/preSearchClinicPatientModal/form/helpers/useColumns';
import {
  PreSearchClinicPatientFormData,
  PreSearchClinicPatientFormErrorType,
  PreSearchClinicPatientFormField,
  PreSearchDependentResult,
} from 'app/features/patient/preSearchClinicPatientModal/form/types';

interface Props {
  isLoading: boolean;
  isFetching: boolean;
  isNumberUnique: boolean;
  data: QueryResponseWithPagination<ClinicPatientShortInfo[]> | undefined;
  params: QueryParams | undefined;
  setParams: Dispatch<SetStateAction<QueryParams | undefined>>;
  openAddPatientModal: (phoneNumber: string) => void;
  openAddDependentModal: (result: PreSearchDependentResult) => void;
}

export const FormComponent: FC<
  Props & InjectedFormProps<PreSearchClinicPatientFormData, Props, PreSearchClinicPatientFormErrorType>
> = ({
  handleSubmit,
  submitting,
  form,
  initialize,
  isLoading,
  isFetching,
  isNumberUnique,
  data,
  params,
  setParams,
  openAddPatientModal,
  openAddDependentModal,
  submitSucceeded,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();

  // Selectors
  const mobileNumber: string | undefined = useAppSelector((state) => state.form?.[form]?.values?.phoneNumber);

  // Helper Variables
  const isMobileNumberInvalid = mobileNumber ? !!phoneNumber(mobileNumber) : false;
  const alertColor = isNumberUnique ? 'primary' : 'warning';
  const alertMessageTextId = isNumberUnique
    ? 'CORE.TEXT.PHONE-NUMBER-NOT-ASSIGNED'
    : 'CORE.TEXT.PHONE-NUMBER-ALREADY-ASSIGNED';
  const isRequesting = isLoading || isFetching;
  const isSearchButtonDisabled = isMobileNumberInvalid || isRequesting;

  // Handlers
  const closeModal = useCallback(() => {
    dispatch(toggleModal('preSearchClinicPatient', false));
  }, [dispatch]);

  const onDecideToCreateNewPatient = useCallback(() => {
    if (mobileNumber) {
      closeModal();
      openAddPatientModal(mobileNumber);
    }
  }, [closeModal, mobileNumber, openAddPatientModal]);

  const onUseAnotherNumber = useCallback(() => {
    if (mobileNumber) {
      initialize({
        phoneNumber: mobileNumber,
      });
    }
  }, [initialize, mobileNumber]);

  // Helper Hooks
  const columns = useColumns(openAddDependentModal, mobileNumber, closeModal);

  return (
    <Form onSubmit={handleSubmit}>
      <ModalHeader toggle={closeModal}>{formatMessage({ id: 'PATIENTS.TEXT.ENTER-MOBILE-NUMBER' })}</ModalHeader>
      <ModalBody>
        <Row xs={1}>
          <Col>
            <Field<BaseFieldProps<PhoneNumberProps>>
              name={PreSearchClinicPatientFormField.phoneNumber}
              component={PhoneNumber}
              props={{
                isRequired: true,
                label: formatMessage({ id: 'CORE.LABEL.PHONE-NUMBER' }),
                disabled: isFetching || submitSucceeded,
              }}
              validate={[required, phoneNumber]}
            />
          </Col>
          {submitSucceeded && mobileNumber && !isRequesting && (
            <Col>
              <Alert color={alertColor} className="mb-2 py-1 px-2 fadeIn">
                <span className="small">{formatMessage({ id: alertMessageTextId }, { number: mobileNumber })}</span>
              </Alert>
            </Col>
          )}
        </Row>
        {isRequesting && <Loader style={{ height: 120 }} />}
        {submitSucceeded && !!data?.data.length && !isRequesting && (
          <div className="mt-4 slideInUp">
            <Box>
              <BoxContent type="full">
                <RemoteBootstrapTable
                  classes="table-striped table-sm"
                  columns={columns}
                  data={data.data || []}
                  id="patients"
                  isFetching={isFetching}
                  isLoading={isLoading}
                  keyField="id"
                  pagination={data.pagination}
                  params={params}
                  setParams={setParams}
                />
              </BoxContent>
            </Box>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        {!submitSucceeded && (
          <>
            <Button type="button" disabled={isRequesting} color="secondary" onClick={closeModal}>
              {formatMessage({ id: 'CORE.BUTTON.CANCEL' })}
            </Button>
            <Button disabled={isSearchButtonDisabled} type="submit" color="primary-gradient">
              {formatMessage({ id: 'CORE.BUTTON.SEARCH' })}
              {submitting && <Spinner size="sm" className="buttonIcon buttonIcon--right" />}
            </Button>
          </>
        )}
        {submitSucceeded && (
          <Button disabled={isRequesting} color="primary" outline onClick={onUseAnotherNumber}>
            {formatMessage({ id: 'CORE.BUTTON.USE-ANOTHER-NUMBER' })}
          </Button>
        )}
        {submitSucceeded && isNumberUnique && (
          <Button color="primary-gradient" onClick={onDecideToCreateNewPatient}>
            {formatMessage({ id: 'CORE.BUTTON.CREATE-NEW-PATIENT' })}
          </Button>
        )}
        {submitSucceeded && !isNumberUnique && (
          <Button disabled={isRequesting} color="primary-gradient" onClick={onDecideToCreateNewPatient}>
            {formatMessage({ id: 'CORE.BUTTON.ADD-AS-NEW-PRIMARY' })}
          </Button>
        )}
      </ModalFooter>
    </Form>
  );
};

export const PreSearchClinicPatientForm = reduxForm<
  PreSearchClinicPatientFormData,
  Props,
  PreSearchClinicPatientFormErrorType
>({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  touchOnChange: true,
  form: FORMS_PRE_SEARCH_CLINIC_PATIENT,
})(FormComponent);
