import React, { useCallback } from 'react';
import classnames from 'classnames';
import { Table } from 'reactstrap';
import { useAppIntl } from 'app/helpers';
import { Item } from 'app/components/descriptionList/types';
import {
  PrescriptionFormData,
  WrappedPrescriptionFormData,
} from 'app/features/doctorAssessment/components/prescriptionSection/types';
import { SignaturePrintField } from 'app/features/doctorAssessment/components/signaturePrintField/signaturePrintField';
import styles from './printPrescription.module.scss';

interface Props {
  prescriptionData: WrappedPrescriptionFormData;
  doctorName?: string;
  signatureUrl?: string;
  showSignature: boolean;
}

interface PrintItem extends Item {
  headerClasses?: string;
}

export const PrescriptionPrintBody = ({ prescriptionData, doctorName, signatureUrl, showSignature }: Props) => {
  const { formatMessage } = useAppIntl();

  const generateItems = useCallback(
    (prescription: PrescriptionFormData): PrintItem[] =>
      [
        {
          label: formatMessage({ id: 'TREATMENT-PLAN-PAGE.LABEL.MEDICATION-NAME' }),
          value: prescription.drugScientificName.label,
          ddClassName: 'min-w-180',
        },
        {
          label: formatMessage({ id: 'TREATMENT-PLAN-PAGE.LABEL.DOSAGE' }),
          //We are checking if user have typed in value manually or have selected value from dropdown list
          value: `${typeof prescription.drugDose === 'string' ? prescription.drugDose : prescription.drugDose?.label} ${
            typeof prescription.drugDoseUnit === 'string' ? prescription.drugDoseUnit : prescription.drugDoseUnit?.label
          } `,
        },
        {
          label: formatMessage({ id: 'CORE.LABEL.DURATION' }),
          value: `${prescription.drugDurationValue} ${prescription.drugDurationUnit.label}`,
          ddClassName: 'text-nowrap text-center',
        },
        {
          label: formatMessage({ id: 'TREATMENT-PLAN.TABLE.FREQUENCY' }),
          value: `${prescription.drugFrequencyValue} ${prescription.drugFrequencyUnit.label}`,
          ddClassName: 'text-nowrap text-center',
        },
        {
          label: formatMessage({ id: 'TREATMENT-PLAN.TABLE.ROUTE' }),
          value: prescription.drugRoute?.label,
          ddClassName: 'text-nowrap text-center',
        },
        {
          label: formatMessage({ id: 'TREATMENT-PLAN.TABLE.PRN' }),
          value: prescription.prn?.label,
          ddClassName: 'text-nowrap text-center',
        },
      ].filter((printColumn) => printColumn.value),
    [formatMessage],
  );

  return (
    <div>
      {prescriptionData.assessmentPrescriptionDrugs?.map((prescription, index) => {
        const items = generateItems(prescription);
        return (
          <div key={`drug-${index}`} style={{ marginBottom: '1rem' }}>
            <div className={styles.sectionTitle}>{`${formatMessage({
              id: 'TREATMENT-PLAN-PAGE.LABEL.PRESCRIBED-MEDICATIONS',
            })} ${index + 1} of ${prescriptionData.assessmentPrescriptionDrugs?.length}`}</div>

            <Table className={styles.drugDataTable} size="sm" borderless responsive>
              <thead>
                <tr>
                  {items.map((item, index) => (
                    <th className={classnames(item.ddClassName, styles.tableHeading)} key={`drugHeader-${index}`}>
                      {item.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {items.map((item, index) => (
                    <td className={classnames(item.ddClassName)} key={`drugData-${index}`}>
                      {item.value}
                    </td>
                  ))}
                </tr>
              </tbody>
            </Table>
            {prescription.indication && (
              <div style={{ marginTop: '7px', lineHeight: 0.8 }} className="px-2 text-black mb-2">
                <span className={classnames(styles.tableHeading, 'd-block mb-1')}>
                  {formatMessage({ id: 'TREATMENT-PLAN.TEXT.INDICATION' })}
                </span>
                <span className={styles.tableIndication}>{prescription.indication}</span>
              </div>
            )}
            {prescription.orderInstructions && (
              <div style={{ marginTop: '7px', lineHeight: 0.8 }} className="px-2 text-black">
                <span className={classnames(styles.tableHeading, 'd-block mb-1')}>
                  {formatMessage({ id: 'TREATMENT-PLAN.TEXT.ORDER-INSTRUCTIONS' })}
                </span>
                <span className={styles.tableIndication}>{prescription.orderInstructions}</span>
              </div>
            )}
          </div>
        );
      })}
      {showSignature && <SignaturePrintField doctorName={doctorName} signatureUrl={signatureUrl} />}
    </div>
  );
};
