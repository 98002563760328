import { IconListSearch } from '@tabler/icons-react';
import classnames from 'classnames';
import { useAppIntl } from 'app/helpers';
import { Box } from 'app/components';
import './noDataMessage.scss';

interface Props {
  customMessageId?: string;
  className?: string;
}

export const NoDataMessage = ({ customMessageId, className }: Props) => {
  const { formatMessage } = useAppIntl();

  return (
    <Box className={classnames('noDataMessage fadeIn', className)}>
      {/* TODO: Make it more generic related to Icon */}
      <IconListSearch className="noDataMessage__icon" size={60} strokeWidth={1} />
      <div className="noDataMessage__text">{formatMessage({ id: customMessageId || 'CORE.LABEL.NO-DATA' })}</div>
    </Box>
  );
};
