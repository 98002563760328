import { PayloadAction } from '@reduxjs/toolkit';
import { put, select } from 'redux-saga/effects';
import { FORMS_ASSESSMENT_ORDERS } from 'app/shared';
import { RootState } from 'app/types';
import { AssessmentOrdersFormData } from 'app/features/doctorAssessment/components/ordersSection/forms/ordersForm/types';
import { RefreshOrdersStatusesEvent } from 'app/features/doctorAssessment/helpers/useLiveRefresh';
import { WS_FETCH_ORDERS_STATUSES, WsRefreshOrdersStatusesAction } from 'app/sagas/assessment/types';

export function* wsUpdateOrdersStatuses({ payload }: PayloadAction<RefreshOrdersStatusesEvent>) {
  const {
    doctorAssessment: { data },
    form,
  }: RootState = yield select();

  // Helper Variables
  const formValues: AssessmentOrdersFormData | undefined = form[FORMS_ASSESSMENT_ORDERS]?.values;
  const items = formValues?.assessmentOrders;

  // Conditions
  const isAppointmentIdMatch = data?.appointmentId === payload.AppointmentId;

  if (isAppointmentIdMatch && items) {
    yield put<WsRefreshOrdersStatusesAction>({
      type: WS_FETCH_ORDERS_STATUSES,
      payload: {
        appointmentId: data.appointmentId,
      },
    });
  }
}
