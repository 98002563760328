import { InvoiceWithCalculatedRemaining, PatientPaymentsState, PaymentType } from 'app/redux/patientPayments/types';

export const updateActualInvoices = (
  { actualInvoices }: PatientPaymentsState['settleInvoices'],
  invoiceValues: Record<string, number>,
  paymentType: PaymentType,
): InvoiceWithCalculatedRemaining[] => {
  if (paymentType === PaymentType.ByInvoices) {
    return actualInvoices.map((invoice) => ({
      ...invoice,
      calculatedRemaining: invoiceValues[invoice.id],
    }));
  }
  return actualInvoices;
};
