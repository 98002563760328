import React, { useState } from 'react';
import { IconEdit } from '@tabler/icons-react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { Button } from 'reactstrap';
import { Field } from 'redux-form';
import { useAppIntl, useAppSelector } from 'app/helpers';
import { LocalBootstrapTable } from 'app/components/bootstrapTable/localBootstrapTable/localBootstrapTable';
import { RichText } from 'app/shared/form-elements/richText/richText';
import { ProcedureResponse } from 'app/redux/doctorAssessment/types';

interface Props {
  name: string;
  keyField: string;
  columns: ColumnDescription[];
  data: ProcedureResponse[];
  label: string;
  isContinueOrDraft: boolean;
  isReadOnly?: boolean;
  withoutField?: boolean;
}

export const WrapperWithTable = ({
  name,
  keyField,
  columns,
  data,
  label,
  isContinueOrDraft,
  isReadOnly,
  withoutField,
}: Props) => {
  const { formatMessage } = useAppIntl();

  // Local State
  const [isUpdateMode, setIsUpdateMode] = useState(false);

  // Selectors
  const { loading } = useAppSelector((state) => state.remoteData.visitPractitionerAssessment);

  return (
    <div className="my-5">
      <h5>{formatMessage({ id: label })}</h5>
      <LocalBootstrapTable
        classes="table-sm mb-2"
        columns={columns}
        data={data}
        id={`${name}Table`}
        keyField={keyField}
        loading={loading}
        wrapperClasses="w-100"
      />
      {!isUpdateMode && !isContinueOrDraft && !isReadOnly && (
        <div className="text-center">
          <Button color="primary-gradient" size="sm" onClick={() => setIsUpdateMode(true)}>
            <IconEdit className="buttonIcon buttonIcon--left" size={18} strokeWidth={1.8} />
            {formatMessage({ id: 'CORE.BUTTON.UPDATE' })}
          </Button>
        </div>
      )}
      {(isUpdateMode || isContinueOrDraft) && !withoutField && <Field name={name} component={RichText} />}
    </div>
  );
};
