import { Component, ErrorInfo, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import { Page } from 'app/components';
import { reportException } from 'app/features/sentry/sentry';

enum ErrorType {
  UnexpectedError,
  LoadError,
}

interface ErrorBoundaryProps {
  children?: ReactNode;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps> {
  state = {
    hasError: false,
    type: ErrorType.UnexpectedError,
  };

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error, extra: ErrorInfo) {
    // Set error type
    if (error.message.includes('Loading')) {
      this.setState({
        type: ErrorType.LoadError,
      });
    } else {
      this.setState({
        type: ErrorType.UnexpectedError,
      });
    }

    // Report to Sentry
    reportException(error, extra);
  }

  render() {
    const isLoadError = this.state.type === ErrorType.LoadError;
    const titleMessageId = isLoadError ? 'ERROR-PAGE.TEXT.TITLE-APP-UPDATED' : 'ERROR-PAGE.TEXT.TITLE';
    const descriptionMessageId = isLoadError
      ? 'ERROR-PAGE.TEXT.DESCRIPTION-APP-UPDATED'
      : 'ERROR-PAGE.TEXT.DESCRIPTION';

    if (this.state.hasError) {
      return (
        <Page className="text-center py-6">
          <h1 className="mb-2">
            <FormattedMessage id={titleMessageId} />
          </h1>
          <h3 className="fw-light mb-4">
            <FormattedMessage id={descriptionMessageId} />
          </h3>
          <Button color="primary-gradient" onClick={() => window.location.reload()}>
            <FormattedMessage id="ERROR-PAGE.BUTTON.RELOAD" />
          </Button>
        </Page>
      );
    }

    return this.props.children;
  }
}
