export const SEARCH_PATIENT_REQUEST = 'patient/SEARCH_PATIENT_REQUEST';
export const SEARCH_PATIENT_SUCCESS = 'patients/SEARCH_PATIENT_SUCCESS';
export const SEARCH_PATIENT_FAILURE = 'patients/SEARCH_PATIENT_FAILURE';

export const FETCH_PATIENT_REQUEST = 'patient/FETCH_PATIENT_REQUEST';
export const FETCH_PATIENT_SUCCESS = 'patient/FETCH_PATIENT_SUCCESS';
export const FETCH_PATIENT_FAILURE = 'patient/FETCH_PATIENT_FAILURE';

export const FETCH_PATIENT_INSURANCES_REQUEST = 'patient/FETCH_PATIENT_INSURANCES_REQUEST';
export const FETCH_PATIENT_INSURANCES_SUCCESS = 'patient/FETCH_PATIENT_INSURANCES_SUCCESS';
export const FETCH_PATIENT_INSURANCES_FAILURE = 'patient/FETCH_PATIENT_INSURANCES_FAILURE';

export const FETCH_PATIENT_WALLETS_REQUEST = 'patient/FETCH_PATIENT_WALLETS_REQUEST';
export const FETCH_PATIENT_WALLETS_SUCCESS = 'patient/FETCH_PATIENT_WALLETS_SUCCESS';
export const FETCH_PATIENT_WALLETS_FAILURE = 'patient/FETCH_PATIENT_WALLETS_FAILURE';

export const ADD_PATIENT_REQUEST = 'patient/add/REQUEST';
export const ADD_PATIENT_SUCCESS = 'patient/add/SUCCESS';
export const ADD_PATIENT_FAILURE = 'patient/add/FAILURE';

export const EDIT_PATIENT_REQUEST = 'patient/edit/REQUEST';
export const EDIT_PATIENT_SUCCESS = 'patient/edit/SUCCESS';
export const EDIT_PATIENT_FAILURE = 'patient/edit/FAILURE';

export const EDIT_DEPENDENT_PATIENT_REQUEST = 'patient/edit-dependent/REQUEST';
export const EDIT_DEPENDENT_PATIENT_SUCCESS = 'patient/edit-dependent/SUCCESS';
export const EDIT_DEPENDENT_PATIENT_FAILURE = 'patient/edit-dependent/FAILURE';

export const EDIT_PATIENT_NUMBER_REQUEST = 'patient-number/edit/REQUEST';
export const EDIT_PATIENT_NUMBER_FAILURE = 'patient-number/edit/FAILURE';

export const VERIFY_PATIENT_NUMBER_REQUEST = 'patient-number/VERIFY/REQUEST';
export const VERIFY_PATIENT_NUMBER_SUCCESS = 'patient-number/VERIFY/SUCCESS';
export const VERIFY_PATIENT_NUMBER_FAILURE = 'patient-number/VERIFY/FAILURE';
export const RESET_VERIFY_PATIENT_NUMBER = 'patient-number/VERIFY/RESET';

export const BLOCK_PATIENT_CLINICS_REQUEST = 'patients/BLOCK_PATIENT_CLINICS_REQUEST';
export const BLOCK_PATIENT_CLINICS_SUCCESS = 'patients/BLOCK_PATIENT_CLINICS_SUCCESS';
export const BLOCK_PATIENT_CLINICS_FAILURE = 'patients/BLOCK_PATIENT_CLINICS_FAILURE';

export const PATIENT_NUMBER_EXISTS_REQUEST = 'patient-number/exists/REQUEST';
export const PATIENT_NUMBER_EXISTS_SUCCESS = 'patient-number/exists/SUCCESS';
export const PATIENT_NUMBER_EXISTS_FAILURE = 'patient-number/exists/FAILURE';

export const FETCH_PATIENT_REGISTRATIONS_REQUEST = 'registrations/fetch/REQUEST';
export const FETCH_PATIENT_REGISTRATIONS_SUCCESS = 'registrations/fetch/SUCCESS';
export const FETCH_PATIENT_REGISTRATIONS_FAILURE = 'registrations/fetch/FAILURE';

export const FETCH_BLOCKED_CLINICS_REQUEST = 'patient/FETCH_BLOCKED_CLINICS_REQUEST';
export const FETCH_BLOCKED_CLINICS_SUCCESS = 'patient/FETCH_BLOCKED_CLINICS_SUCCESS';
export const FETCH_BLOCKED_CLINICS_FAILURE = 'patient/FETCH_BLOCKED_CLINICS_FAILURE';

export const UNBLOCK_PATIENT_CLINICS_REQUEST = 'patients/UNBLOCK_PATIENT_CLINICS_REQUEST';
export const UNBLOCK_PATIENT_CLINICS_SUCCESS = 'patients/UNBLOCK_PATIENT_CLINICS_SUCCESS';
export const UNBLOCK_PATIENT_CLINICS_FAILURE = 'patients/UNBLOCK_PATIENT_CLINICS_FAILURE';

export const BLOCK_PATIENT_REQUEST = 'patients/BLOCK_PATIENT_REQUEST';
export const BLOCK_PATIENT_SUCCESS = 'patients/BLOCK_PATIENT_SUCCESS';
export const BLOCK_PATIENT_FAILURE = 'patients/BLOCK_PATIENT_FAILURE';

export const UNBLOCK_PATIENT_REQUEST = 'patients/UNBLOCK_PATIENT_REQUEST';
export const UNBLOCK_PATIENT_SUCCESS = 'patients/UNBLOCK_PATIENT_SUCCESS';
export const UNBLOCK_PATIENT_FAILURE = 'patients/UNBLOCK_PATIENT_FAILURE';

export const ADD_PATIENT_INSURANCE_REQUEST = 'patient/ADD_PATIENT_INSURANCE_REQUEST';
export const ADD_PATIENT_INSURANCE_SUCCESS = 'patient/ADD_PATIENT_INSURANCE_SUCCESS';
export const ADD_PATIENT_INSURANCE_FAILURE = 'patient/ADD_PATIENT_INSURANCE_FAILURE';

export const DELETE_PATIENT_INSURANCE_REQUEST = 'patient/DELETE_PATIENT_INSURANCE_REQUEST';
export const DELETE_PATIENT_INSURANCE_SUCCESS = 'patient/DELETE_PATIENT_INSURANCE_SUCCESS';
export const DELETE_PATIENT_INSURANCE_FAILURE = 'patient/DELETE_PATIENT_INSURANCE_FAILURE';

export const UPDATE_PATIENT_INSURANCE_REQUEST = 'patient/UPDATE_PATIENT_INSURANCE_REQUEST';
export const UPDATE_PATIENT_INSURANCE_SUCCESS = 'patient/UPDATE_PATIENT_INSURANCE_SUCCESS';
export const UPDATE_PATIENT_INSURANCE_FAILURE = 'patient/UPDATE_PATIENT_INSURANCE_FAILURE';

export const FETCH_PATIENT_SUMMARY_REQUEST = 'patient/FETCH_PATIENT_SUMMARY_REQUEST';
export const FETCH_PATIENT_SUMMARY_SUCCESS = 'patient/FETCH_PATIENT_SUMMARY_SUCCESS';
export const FETCH_PATIENT_SUMMARY_FAILURE = 'patient/FETCH_PATIENT_SUMMARY_FAILURE';

export const ADD_PATIENT_ACCOUNT_CREDIT_REQUEST = 'patient/ADD_PATIENT_ACCOUNT_CREDIT_REQUEST';
export const ADD_PATIENT_ACCOUNT_CREDIT_SUCCESS = 'patient/ADD_PATIENT_ACCOUNT_CREDIT_SUCCESS';
export const ADD_PATIENT_ACCOUNT_CREDIT_FAILURE = 'patient/ADD_PATIENT_ACCOUNT_CREDIT_FAILURE';

export const SET_PATIENT_ACCOUNT_CREDIT_PAYMENTS = 'patient/SET_PATIENT_ACCOUNT_CREDIT_PAYMENTS';

export const WITHDRAW_ACC_CREDIT_REQUEST = 'patient/WITHDRAW_ACC_CREDIT_REQUEST';
export const WITHDRAW_ACC_CREDIT_SUCCESS = 'patient/WITHDRAW_ACC_CREDIT_SUCCESS';
export const WITHDRAW_ACC_CREDIT_FAILURE = 'patient/WITHDRAW_ACC_CREDIT_FAILURE';

export const CHANGE_INFO_NOTIFICATION_REQUEST = 'patients/CHANGE_INFO_NOTIFICATION_REQUEST';
export const CHANGE_INFO_NOTIFICATION_SUCCESS = 'patients/CHANGE_INFO_NOTIFICATION_SUCCESS';
export const CHANGE_INFO_NOTIFICATION_FAILURE = 'patients/CHANGE_INFO_NOTIFICATION_FAILURE';

export const CHANGE_PATIENT_REQUEST = 'patients/CHANGE_PATIENT_REQUEST';
export const CHANGE_PATIENT_SUCCESS = 'patients/CHANGE_PATIENT_SUCCESS';
export const CHANGE_PATIENT_FAILURE = 'patients/CHANGE_PATIENT_FAILURE';

export const FETCH_PATIENT_FOR_PIN_REQUEST = 'patients/FETCH_PATIENT_FOR_PIN_REQUEST';
export const FETCH_PATIENT_FOR_PIN_SUCCESS = 'patients/FETCH_PATIENT_FOR_PIN_SUCCESS';
export const FETCH_PATIENT_FOR_PIN_FAILURE = 'patients/FETCH_PATIENT_FOR_PIN_FAILURE';

export const SET_PATIENT_WITHDRAWAL_PAYMENTS = 'patient/SET_PATIENT_WITHDRAWAL_PAYMENTS';

export const CHECK_NATIONAL_ID_EXISTS_REQUEST = 'patient/CHECK_NATIONAL_ID_EXISTS_REQUEST';
export const CHECK_NATIONAL_ID_EXISTS_SUCCESS = 'patient/CHECK_NATIONAL_ID_EXISTS_SUCCESS';
export const CHECK_NATIONAL_ID_EXISTS_FAILURE = 'patient/CHECK_NATIONAL_ID_EXISTS_FAILURE';

export const CHECK_PIN_FOR_PATIENT_CHANGE_REQUEST_REQUEST = 'patients/CHECK_PIN_FOR_PATIENT_CHANGE_REQUEST_REQUEST';
export const CHECK_PIN_FOR_PATIENT_CHANGE_REQUEST_SUCCESS = 'patients/CHECK_PIN_FOR_PATIENT_CHANGE_REQUEST_SUCCESS';
export const CHECK_PIN_FOR_PATIENT_CHANGE_REQUEST_FAILURE = 'patients/CHECK_PIN_FOR_PATIENT_CHANGE_REQUEST_FAILURE';
