import React from 'react';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { getTranslation, useAppIntl } from 'app/helpers';
import { Loader } from 'app/shared';
import { Option } from 'app/types';
import './servicesList.scss';

interface Props {
  selectedService: Option | null;
  onServiceChange: (option: Option) => void;
  serviceOptions: Option[];
  multipleColumns?: boolean;
  loading: boolean;
}

export const ServicesList = ({ selectedService, onServiceChange, serviceOptions, multipleColumns, loading }: Props) => {
  const { locale } = useAppIntl();

  return (
    <>
      {loading && <Loader />}

      {!loading && (
        <Row className="fadeIn">
          {serviceOptions.map((s: Option, i: number) => {
            return (
              <Col key={i} {...(multipleColumns ? { xl: 4, lg: 6, md: 12 } : {})}>
                <UncontrolledTooltip target={`serviceListOption-${i}`}>{getTranslation(s, locale)}</UncontrolledTooltip>
                <Button
                  block
                  color="primary"
                  className="mt-2 btn text-truncate"
                  id={`serviceListOption-${i}`}
                  outline={selectedService?.value !== s.value}
                  onClick={() => onServiceChange(s)}
                >
                  {getTranslation(s, locale)}
                </Button>
              </Col>
            );
          })}
        </Row>
      )}
    </>
  );
};
