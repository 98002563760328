import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { FORMS_FORGOTTEN_PASSWORD } from 'app/shared';
import { forgottenPassword } from 'app/redux/auth/auth.actions';
import FormComponent from './form.component';

const mapStateToProps = () => ({
  form: FORMS_FORGOTTEN_PASSWORD,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values) => dispatch(forgottenPassword(values)),
});

export const FormContainer = compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm(),
)(FormComponent);
