import { useCallback } from 'react';
import { useAppDispatch, useAppIntl, useAppSelector, useSilentRefresh } from 'app/helpers';
import { RemoteTable } from 'app/shared';
import { fetchBookedAppointments } from 'app/redux/remoteData/remoteData.actions';
import { useColumns } from 'app/features/calendar/modals/manageAppointments/bookedAppointments/helpers';

export const BookedAppointments = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const { data, loading, params } = useAppSelector((state) => state.remoteData.bookedAppointments);

  const columns = useColumns();
  const selectedSlot = useAppSelector((state) => state.calendar.selectedSlot);

  const fetchData = useCallback(
    (newParams) => {
      if (selectedSlot) {
        dispatch(
          fetchBookedAppointments(selectedSlot.doctor.id, {
            ...newParams,
            branchesIds: [selectedSlot.branchId],
            start: selectedSlot.start,
            end: selectedSlot.end,
          }),
        );
      }
    },
    [dispatch, selectedSlot],
  );

  const refreshData = useCallback(() => {
    fetchData(params);
  }, [fetchData, params]);

  // Effects
  useSilentRefresh(refreshData);

  return (
    <>
      <h5 className="mb-3">{formatMessage({ id: 'CORE.TEXT.BOOKED-APPOINTMENTS' })}</h5>
      <RemoteTable
        columns={columns}
        data={data}
        fetchData={fetchData}
        id="bookedAppointments"
        initialFetch
        keyField="id"
        loading={loading}
        params={params}
      />
    </>
  );
};
