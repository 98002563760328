import toast from 'react-hot-toast';
import { put, select, takeEvery } from 'redux-saga/effects';
import { doctorsApi } from 'app/services/doctors/doctors';
import {
  fetchDoctor,
  fetchDoctorBusyTime,
  fetchDoctorCurrentCustomWorkingHours,
  fetchDoctorCurrentWorkingHours,
  setProductsModal,
} from 'app/redux/doctor/doctor.actions';
import {
  ADD_DOCTOR_BUSY_TIME_SUCCESS,
  ADD_DOCTOR_CUSTOM_WORKING_HOURS_SUCCESS,
  ADD_DOCTOR_SUCCESS,
  ADD_DOCTOR_WORKING_HOURS_SUCCESS,
  DELETE_DOCTOR_CUSTOM_WORKING_HOURS_SUCCESS,
  DELETE_DOCTOR_SUCCESS,
  DELETE_DOCTOR_WORKING_HOURS_SUCCESS,
  EDIT_DOCTOR_SUCCESS,
  EDIT_DOCTOR_WORKING_HOURS_SUCCESS,
  END_DOCTOR_BUSY_TIME_SUCCESS,
} from 'app/redux/doctor/doctor.types';
import { toggleAddDoctorBusyTimeModal, toggleAddDoctorModal, toggleModal } from 'app/redux/modals/modals.actions';
import { fetchDoctors } from 'app/redux/remoteData/remoteData.actions';
import { currentTab } from 'app/doctor/working-hours/busy-time/busy-time.constants';

export function* afterAddDoctorSuccess() {
  yield put(doctorsApi.util.invalidateTags(['Doctors']));
  yield put(toggleAddDoctorModal());
}

export function* afterDeleteDoctor({ meta }) {
  const {
    remoteData: {
      doctors: { params },
    },
  } = yield select();

  yield put(fetchDoctors(meta.clinicId, params));
}

export function* afterEditDoctorSuccess({ meta }) {
  const doctorId = meta?.doctorId;

  yield put(toggleModal('editDoctor', false));
  yield put(doctorsApi.util.invalidateTags(['Doctors', 'Doctor']));

  if (doctorId) {
    yield put(fetchDoctor(doctorId));
  }

  yield toast.success('DOCTORS.NOTIFICATION.EDIT-SUCCESS');
}

export function* afterAddDoctorBusyTimeSuccess({ meta }) {
  yield put(fetchDoctorBusyTime(meta.doctorId, meta.branchId, currentTab));
  yield put(toggleAddDoctorBusyTimeModal());
}

export function* afterEndDoctorBusyTimeSuccess({ meta }) {
  yield put(fetchDoctorBusyTime(meta.doctorId, meta.branchId, currentTab));
}

export function* afterAddDoctorWorkingHoursSuccess({ meta, payload }) {
  const workingTimeId = payload?.data?.id;

  yield put(toggleModal('addDoctorWorkingHours', false));
  yield put(fetchDoctorCurrentWorkingHours(meta.doctorId, meta.branchId));
  yield toast.success('DOCTORS.NOTIFICATION.ADD-WH-SUCCESS');

  if (workingTimeId) {
    // Set initial products modal
    yield put(setProductsModal(true, workingTimeId));
  }
}

export function* afterAddDoctorCustomWorkingHoursSuccess({ meta, payload }) {
  const workingTimeId = payload?.data?.id;

  yield put(toggleModal('addDoctorCustomWorkingHours', false));
  yield put(fetchDoctorCurrentCustomWorkingHours(meta.doctorId, meta.branchId));
  yield toast.success('DOCTORS.NOTIFICATION.ADD-WH-SUCCESS');

  if (workingTimeId) {
    // Set initial products modal
    yield put(setProductsModal(true, workingTimeId));
  }
}

export function* afterEditWorkingHours({ meta }) {
  yield put(toggleModal('editDoctorWorkingHours', false));
  yield put(toggleModal('editDoctorCustomWorkingHours', false));
  yield toast.success('DOCTORS.NOTIFICATION.EDIT-WH-SUCCESS');

  if (meta.isCustom) {
    yield put(fetchDoctorCurrentCustomWorkingHours(meta.doctorId, meta.branchId));
  } else {
    yield put(fetchDoctorCurrentWorkingHours(meta.doctorId, meta.branchId));
  }
}

export function* afterDeleteWorkingHours() {
  yield put(toggleModal('deleteDoctorWorkingHours', false));
  yield put(toggleModal('deleteDoctorCustomWorkingHours', false));
  yield toast.success('DOCTORS.NOTIFICATION.DELETE-WH-SUCCESS');
}

function* doctorsSaga() {
  yield takeEvery(ADD_DOCTOR_SUCCESS, afterAddDoctorSuccess);
  yield takeEvery(EDIT_DOCTOR_SUCCESS, afterEditDoctorSuccess);
  yield takeEvery(ADD_DOCTOR_BUSY_TIME_SUCCESS, afterAddDoctorBusyTimeSuccess);
  yield takeEvery(END_DOCTOR_BUSY_TIME_SUCCESS, afterEndDoctorBusyTimeSuccess);
  yield takeEvery(ADD_DOCTOR_WORKING_HOURS_SUCCESS, afterAddDoctorWorkingHoursSuccess);
  yield takeEvery(ADD_DOCTOR_CUSTOM_WORKING_HOURS_SUCCESS, afterAddDoctorCustomWorkingHoursSuccess);
  yield takeEvery(DELETE_DOCTOR_SUCCESS, afterDeleteDoctor);
  yield takeEvery(EDIT_DOCTOR_WORKING_HOURS_SUCCESS, afterEditWorkingHours);
  yield takeEvery(
    [DELETE_DOCTOR_WORKING_HOURS_SUCCESS, DELETE_DOCTOR_CUSTOM_WORKING_HOURS_SUCCESS],
    afterDeleteWorkingHours,
  );
}

export default doctorsSaga;
