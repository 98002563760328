import { ComponentType, useCallback } from 'react';
import defaultPhoto from 'assets/images/icons/default_photo.jpg';
import classnames from 'classnames';
import { ResourceHeaderProps } from 'react-big-calendar';
import { useHistory, useParams } from 'react-router';
import { destroy } from 'redux-form';
import { getApiDate, useAppDispatch, useAppSelector } from 'app/helpers';
import { Avatar } from 'app/components';
import { FORMS_CALENDAR_DAY_FILTER, FORMS_CALENDAR_WEEK_FILTER } from 'app/shared';
import { reset } from 'app/redux/remoteData/remoteData.actions';
import { CalendarTabs } from 'app/types';
import { FiltersFormData } from 'app/features/calendar/components/calendar/filters/types';
import { CalendarDayResource } from 'app/features/calendar/types';
import styles from 'app/features/calendar/components/calendar/resourceHeader/resourceHeader.module.scss';

export const ResourceHeader: ComponentType<ResourceHeaderProps<CalendarDayResource>> = (props) => {
  const { push } = useHistory();
  const dispatch = useAppDispatch();
  const { tabId } = useParams<{ tabId?: CalendarTabs }>();
  const isDayView = tabId === CalendarTabs.Day;
  const { resourceDescription, resourceId, resourceImage, resourceTitle } = props.resource;
  const dayViewFilters = useAppSelector((state) => state.form[FORMS_CALENDAR_DAY_FILTER])
    ?.values as Partial<FiltersFormData>;
  const labelTitle = typeof props.label === 'string' ? props.label : undefined;

  const openWeekCalendar = useCallback(() => {
    if (isDayView) {
      const params = {
        // Date
        date: dayViewFilters.date ? getApiDate(dayViewFilters.date) : '',

        // Branch
        branchId: dayViewFilters.branch?.value || '',
        branchName: dayViewFilters.branch?.label || '',

        // Speciality
        specialityId: dayViewFilters.speciality?.value || '',
        specialityName: dayViewFilters.speciality?.label || '',

        // Doctor
        doctorId: resourceId,
        doctorName: resourceTitle,
      };

      // Create URL search params
      const searchParams = new URLSearchParams(params);

      // Reset Week View params and remove old filters
      dispatch(reset('weekCalendar'));
      dispatch(destroy(FORMS_CALENDAR_WEEK_FILTER));

      // Open Calendar Week tab with current params
      push(`/calendar/week?${searchParams.toString()}`);
    }
  }, [dayViewFilters, dispatch, isDayView, push, resourceId, resourceTitle]);

  return (
    <div className={styles.wrapper}>
      <Avatar
        className={classnames('rounded-3 mb-2', { 'cursor-pointer': isDayView })}
        imageSrc={resourceImage?.url || defaultPhoto}
        onClick={openWeekCalendar}
        size="lg"
        title={labelTitle}
      />
      <h5 title={labelTitle} className="text-truncate mb-0">
        <span className={classnames({ 'cursor-pointer': isDayView })} onClick={openWeekCalendar}>
          {props.label}
        </span>
      </h5>
      <span className="d-block fw-normal text-secondary text-truncate" title={resourceDescription}>
        {resourceDescription}
      </span>
    </div>
  );
};
