import { Option } from './Option';
import { Status } from './Status';

export enum SpecialtyType {
  Primary = '0',
  Specialized = '1',
}
export enum SpecialtyFormType {
  UCAF = '0',
  DCAF = '1',
}

export type SpecialityFormTypeName = 'DCAF' | 'UCAF';

export type Speciality = {
  clinicyId: string;
  id: number;
  nameAr: string;
  nameEn: string;
  numberOfSubSpecialities: number;
  formType: Option<SpecialtyFormType>;
  specialityType: Option<SpecialtyType>;
  status: Option<Status>;
};
