import { useCallback } from 'react';
import moment from 'moment';
import { Button } from 'reactstrap';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { FORMATS_DATE, FORMATS_DAY_OF_WEEK } from 'app/shared';
import { selectTableViewDay } from 'app/redux/calendar/calendar.actions';
import { SelectedDay } from 'app/redux/calendar/types';
import { CalendarItem } from 'app/types';
import { getDaySettings } from 'app/features/bookAppointment/bookAppointmentWizard/helpers';

interface Props {
  calendarItem: CalendarItem;
  onSelectDate?: (day: SelectedDay) => void;
}

export const DateFormatterDays = ({ calendarItem, onSelectDate }: Props) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();

  // Selectors
  const selectedDays = useAppSelector(
    (state) => state.calendar.tableView.selectedDays,
    () => false,
  );

  // Actions
  const selectDate = useCallback(
    (day: SelectedDay) => {
      dispatch(selectTableViewDay(day));
      if (onSelectDate) {
        onSelectDate(day);
      }
    },
    [dispatch, onSelectDate],
  );

  return (
    <div className="dateFormatter__days">
      {calendarItem.days &&
        calendarItem.days.map((day) => {
          const date = moment(day.date);
          const formattedDate = date.format(FORMATS_DATE);
          const isToday = date.isSame(moment(), 'd');
          const isSelected = selectedDays.some(
            (d) =>
              moment(d.date).isSame(day.date, 'date') &&
              d.branchId === calendarItem.branch.id &&
              d.doctorId === calendarItem.doctor.id &&
              d.productId === calendarItem.product.value,
          );
          const { color, disabled, outline } = getDaySettings(day, isSelected);

          return (
            <Button
              className="dateFormatter__day"
              color={color}
              data-date={`${formattedDate}`}
              disabled={disabled}
              key={day.date}
              onClick={() =>
                selectDate({
                  branchId: calendarItem.branch.id,
                  date: day.date,
                  doctorId: calendarItem.doctor.id,
                  productId: calendarItem.product.value,
                })
              }
              outline={outline}
            >
              {date.format(FORMATS_DAY_OF_WEEK)}
              <br />
              <small>{isToday ? formatMessage({ id: 'CORE.TEXT.TODAY' }) : formattedDate}</small>
            </Button>
          );
        })}
    </div>
  );
};
