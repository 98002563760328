import { useMemo } from 'react';
import { SelectRowProps } from 'react-bootstrap-table-next';
import { RemoteBootstrapTable } from 'app/components/bootstrapTable/remoteBootstrapTable/remoteBootstrapTable';
import { useColumns } from 'app/shared/patientSearch/patientSearchResults/helpers/useColumns';
import { ClinicPatientShortInfo } from 'app/services/patients/types';
import { QueryParams, QueryResponsePagination } from 'app/types';

interface Props {
  data: ClinicPatientShortInfo[];
  isFetching: boolean;
  isLoading: boolean;
  pagination?: QueryResponsePagination;
  params?: QueryParams;
  setParams: (params?: QueryParams) => void;
  selectionChanged: (id: string) => void;
  checkIsSelected: (id: string) => boolean;
}

export const PatientSearchResults = ({
  data,
  isFetching,
  isLoading,
  pagination,
  params,
  setParams,
  checkIsSelected,
  selectionChanged,
}: Props) => {
  const columns = useColumns({ checkIsSelected });

  const selectRow: SelectRowProps<ClinicPatientShortInfo> = useMemo(
    () => ({
      mode: 'radio',
      hideSelectAll: true,
      hideSelectColumn: true,
      clickToSelect: true,
      classes: (row) => (checkIsSelected(row.id) ? 'bg-light-primary' : undefined),
      onSelect: (row) => {
        selectionChanged(row.id);
      },
    }),
    [selectionChanged, checkIsSelected],
  );

  return (
    <RemoteBootstrapTable
      classes="table-sm table-stripe table-row-cursor-pointer"
      columns={columns}
      data={data || []}
      id="patientSearchResults"
      keyField="id"
      isFetching={isFetching}
      isLoading={isLoading}
      pagination={pagination}
      params={params}
      setParams={setParams}
      selectRow={selectRow}
    />
  );
};
