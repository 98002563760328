import { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'app/helpers';
import { DEFAULT_TIME_FROM, DEFAULT_TIME_TO, FORMATS_API_DATE } from 'app/shared';
import { fetchMonth } from 'app/redux/calendar/calendar.actions';
import { CalendarMonthParams } from 'app/redux/calendar/types';
import { CurrentParams } from 'app/appointment/form/types';
import { checkAvailableSlotsInDay } from 'app/features/calendar/helpers';

const generateCalendarMonthParams = (currentParams: CurrentParams, date: Date): CalendarMonthParams | null => {
  const { branchId, doctorId, productId, specialityId, subSpecialityId } = currentParams;

  if (branchId && doctorId && productId && specialityId && subSpecialityId) {
    return {
      branchId,
      doctorId,
      date: moment(date).format(FORMATS_API_DATE),
      productRelatedFilters: {
        productId: productId,
        specialityId: specialityId,
        subSpecialityId: subSpecialityId,
      },
      timeFrom: DEFAULT_TIME_FROM,
      timeTo: DEFAULT_TIME_TO,
    };
  }

  return null;
};

export const useCalendarDays = (currentParams: CurrentParams) => {
  const dispatch = useAppDispatch();
  const appointment = useAppSelector((state) => state.appointment.data);
  const { data } = useAppSelector((state) => state.calendar.month);
  const [isCalendarInactive, setCalendarInactive] = useState<boolean>(false);

  // Values
  const availableDays = useMemo((): Date[] => {
    if (appointment) {
      const appointmentDate = moment(appointment.start).toISOString();

      if (appointment && data) {
        const days = data.days
          .map((day) => (checkAvailableSlotsInDay(day) ? moment(day.date).toISOString() : ''))
          .filter(Boolean);
        const hasAppointmentDate = days.includes(appointmentDate);

        // Return available dates and the date of the appointment
        const availableDays = hasAppointmentDate ? days : [...days, appointmentDate];

        return availableDays.map((d) => moment(d).toDate());
      }

      // Return appointment date if the month data is empty
      return [moment(appointmentDate).toDate()];
    }

    return [];
  }, [appointment, data]);

  // Actions
  const onCalendarChange = useCallback(
    async (date: Date, dateTestGranularity: 'day' | 'month' = 'day') => {
      if (moment(date).isBefore(moment(), dateTestGranularity)) {
        return;
      }
      const params = generateCalendarMonthParams(currentParams, date);

      if (params) {
        setCalendarInactive(true);
        await dispatch(fetchMonth(params));
        setCalendarInactive(false);
      }
    },
    [currentParams, dispatch],
  );

  return {
    availableDays,
    isCalendarInactive,
    onCalendarChange,
  };
};
