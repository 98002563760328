import moment from 'moment';
import { DEFAULT_PAGINATION_PARAMS, DEFAULT_TIME_FROM, DEFAULT_TIME_TO, FORMATS_API_DATE } from 'app/shared';
import { CalendarSearchParams, ProductNameSearchStrategy } from 'app/redux/calendar/types';
import { Option } from 'app/types';

interface Props {
  branchId: string;
  clinicId: string;
  specialityId: string;
  subSpecialityId: string;
  productName: string;
  doctorId?: string;
  timeFrom?: string;
  timeTo?: string;
  customAppointmentSlots?: Option;
  patientSourceId?: string;
  params?: { page: number; sizePerPage: number };
}

export const generateCalendarSearchParams = ({
  branchId,
  clinicId,
  specialityId,
  subSpecialityId,
  productName,
  doctorId,
  customAppointmentSlots,
  patientSourceId,
  timeFrom,
  timeTo,
  params = DEFAULT_PAGINATION_PARAMS,
}: Props): CalendarSearchParams => ({
  ...params,

  clinicRelatedFilters: {
    branchId: branchId,
    clinicId: clinicId,
  },

  doctorRelatedFilters: {
    doctorIds: doctorId ? [doctorId] : [],
    languages: [],
    ranks: [],
  },

  productRelatedFilters: {
    customDurationInMinutes: customAppointmentSlots?.value,
    productNames: [productName],
    searchStrategy: ProductNameSearchStrategy.AnyOfKeywordsIsEqualsToProductName,
    specialityId: specialityId,
    subSpecialityId: subSpecialityId,
  },

  patientSourceId,
  date: moment().format(FORMATS_API_DATE),
  timeFrom: timeFrom || DEFAULT_TIME_FROM,
  timeTo: timeTo || DEFAULT_TIME_TO,
});
