import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers';
import { resetAppointment, resetPatient } from 'app/redux/appointment/appointment.actions';
import { selectSlot as bookAppointmentSelectSlot } from 'app/redux/book-appointment/book-appointment.actions';
import { selectSlot } from 'app/redux/calendar/calendar.actions';
import { SelectedSlot } from 'app/redux/calendar/types';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { CalendarSlot, Doctor, Option } from 'app/types';

export const useSelectSlot = (
  branchId: string,
  doctor: Doctor,
  product: Option,
  speciality: Option,
  subSpeciality: Option,
) => {
  const dispatch = useAppDispatch();
  const isFullEditMode = useAppSelector((state) => state.appointment.fullEdit.isActive);
  const isBookAppointmentModalOpen = useAppSelector((state) => state.modal.bookAppointment.isOpen);

  return useCallback(
    (date: string, slot?: CalendarSlot) => {
      const hasAppointments = !!slot?.overlappingAppointmentsIds.length;

      // Reset previous data
      dispatch(resetPatient());
      dispatch(resetAppointment());

      const slotData: SelectedSlot = {
        ...slot,
        branchId,
        date,
        doctor,
        isPhantom: false,
        product,
        speciality,
        subSpeciality,
      };

      // Select slot
      dispatch(selectSlot(slotData));
      // Select slot in book appointment wizard when open
      if (isBookAppointmentModalOpen) {
        dispatch(bookAppointmentSelectSlot(slotData));
      }

      // Open specific modal
      if (hasAppointments && !isFullEditMode) {
        dispatch(toggleModal('manageAppointments'));
      } else {
        if (isFullEditMode) {
          dispatch(toggleModal('fullEditAppointment'));
        } else if (!isBookAppointmentModalOpen) {
          dispatch(toggleModal('addAppointment'));
        }
      }
    },
    [branchId, doctor, dispatch, isFullEditMode, isBookAppointmentModalOpen, product, speciality, subSpeciality],
  );
};
