import { useMemo } from 'react';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import { getPatientNameFromOption } from 'app/helpers';
import { CalendarType, DayCalendar, Locale } from 'app/types';
import { CalendarDayEvent } from 'app/features/calendar/types';

export const useBackgroundEvents = (data: DayCalendar[], calendarType: CalendarType, locale: Locale) => {
  return useMemo((): CalendarDayEvent[] => {
    const events: CalendarDayEvent[] = [];

    if (calendarType === CalendarType.Waiting) {
      data.forEach((dayCalendar) => {
        return dayCalendar.appointments.forEach((appointment) => {
          if (appointment.end && !appointment.isConflicting) {
            const isFutureEvent = moment().startOf('day').isSameOrBefore(appointment.end);

            if (isFutureEvent) {
              events.push({
                allDay: false,
                end: moment(appointment.end).toDate(),
                id: appointment.id,
                isWaiting: false,
                resourceId: dayCalendar.doctor.id,
                start: moment(appointment.start).toDate(),
                title: getPatientNameFromOption(appointment.patient, locale),
              });
            }
          }
        });
      });
    }

    return uniqBy(events, 'id');
  }, [calendarType, data, locale]);
};
