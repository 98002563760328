import { createPayment } from 'app/redux/patientPayments/helpers/createPayment';
import { updateActualInvoices } from 'app/redux/patientPayments/helpers/updateActualInvoices';
import { AddSettleInvoicesPaymentPayload, PatientPaymentsState, PaymentType } from 'app/redux/patientPayments/types';

export const addNewSettleInvoicesPayment = (
  state: PatientPaymentsState['settleInvoices'],
  payload: AddSettleInvoicesPaymentPayload,
) => {
  const { invoiceValues, payments, paymentAllocations, remainingCredits } = createPayment(state, payload);
  const actualInvoices = updateActualInvoices(state, invoiceValues, payload.paymentType || PaymentType.None);
  const newDebtAmount = state.debtAmount - Number(payload.generalPaymentDetails?.amount);

  return {
    actualInvoices: actualInvoices.filter((invoice) => (invoice.calculatedRemaining ?? 0) > 0),
    payments: [...state.payments, ...payments],
    paymentAllocations: [...state.paymentAllocations, ...paymentAllocations],
    invoiceValues: invoiceValues,
    remainingCredits: remainingCredits,
    debtAmount: newDebtAmount,
  };
};
