import { updateActualInvoiceRemaining } from 'app/redux/patientPayments/helpers/updateActualInvoiceRemaining';
import { PatientPaymentsState, PaymentType } from 'app/redux/patientPayments/types';
import { InvoicePaymentMethodType } from 'app/types';

export const deletePayment = (
  state: PatientPaymentsState['settleInvoices'],
  paymentIndex: number,
): PatientPaymentsState['settleInvoices'] => {
  const payment = state.payments[paymentIndex];
  const { invoiceId, amount } = state.paymentAllocations[paymentIndex];

  const newInvoiceValues = {
    ...state.invoiceValues,
    [invoiceId]: state.invoiceValues[invoiceId] + amount,
  };
  const newDebtAmount = state.debtAmount + amount;

  const newRemainingCredits =
    payment.generalPaymentDetails?.paymentMethod === InvoicePaymentMethodType.FromAccountCredit
      ? state.remainingCredits + amount
      : state.remainingCredits;

  const updatedActualInvoices =
    state.paymentType === PaymentType.ByInvoices
      ? updateActualInvoiceRemaining(invoiceId, state.actualInvoices, newInvoiceValues[invoiceId])
      : state.actualInvoices;

  const newPayments = state.payments.filter((_, i) => i !== paymentIndex);
  const newPaymentAllocations = state.paymentAllocations.filter((_, i) => i !== paymentIndex);

  const newPaymentType = newPayments.length === 0 ? PaymentType.None : state.paymentType;

  const updatedPayments = newPayments.map((item) =>
    item.id === invoiceId ? { ...item, remaining: item.remaining + amount } : item,
  );

  return {
    ...state,
    actualInvoices: updatedActualInvoices,
    payments: updatedPayments,
    paymentAllocations: newPaymentAllocations,
    paymentType: newPaymentType,
    invoiceValues: newInvoiceValues,
    remainingCredits: newRemainingCredits,
    debtAmount: newDebtAmount,
  };
};
