import { AnyAction } from 'redux';
import { createAction, RSAA } from 'redux-api-middleware';
import * as types from 'app/redux/doctorAssessment/doctorAssessment.types';
import {
  AssessmentReferral,
  AttachmentAppointmentOptionsParams,
  AttachmentAppointmentOptionsPayload,
  BillingItemQuantityInformation,
  CreateAssessmentDraftResponse,
  DiagnosesResponse,
  DoctorAssessmentParams,
  FetchUpdatedOrdersStatusesResponse,
  GeneralAssessmentInformation,
  InsuranceApprovalParams,
  MedicalAttachmentData,
  ProcedureResponse,
  VisitNumberInformation,
} from 'app/redux/doctorAssessment/types';
import {
  AssessmentDiagnose,
  AssessmentOrder,
  AssessmentPackageItem,
  AssessmentPatientIllness,
  BillingItemPaymentStatus,
  BillingItemSourceType,
  InitialAssessmentAppointmentType,
  MedicalAttachment,
  Option,
  PatientVitals,
  QueryResponse,
} from 'app/types';
import { AssessmentBillingItem } from 'app/features/doctorAssessment/components/billingSection/forms/types';
import { PrescriptionData } from 'app/features/doctorAssessment/components/prescriptionSection/types';

export const fetchEmptyDoctorAssessment = (appointmentId: string) => {
  return {
    [RSAA]: {
      endpoint: '/doctor-assessments/empty',
      method: 'GET',
      params: { appointmentId },
      types: [
        types.FETCH_EMPTY_DOCTOR_ASSESSMENT_REQUEST,
        types.FETCH_EMPTY_DOCTOR_ASSESSMENT_SUCCESS,
        types.FETCH_EMPTY_DOCTOR_ASSESSMENT_FAILURE,
      ],
    },
  };
};

export const fetchDoctorAssessment = (doctorAssessmentId: string) => {
  return {
    [RSAA]: {
      endpoint: `/doctor-assessments/${doctorAssessmentId}`,
      method: 'GET',
      types: [
        types.FETCH_DOCTOR_ASSESSMENT_REQUEST,
        types.FETCH_DOCTOR_ASSESSMENT_SUCCESS,
        types.FETCH_DOCTOR_ASSESSMENT_FAILURE,
      ],
    },
  };
};

export const fetchContinuedDoctorAssessment = (
  previousDoctorAssessmentId: string,
  params: { appointmentId: string },
) => {
  return {
    [RSAA]: {
      endpoint: `/doctor-assessments/${previousDoctorAssessmentId}/continue`,
      method: 'GET',
      params,
      types: [
        types.FETCH_CONTINUED_DOCTOR_ASSESSMENT_REQUEST,
        types.FETCH_CONTINUED_DOCTOR_ASSESSMENT_SUCCESS,
        types.FETCH_CONTINUED_DOCTOR_ASSESSMENT_FAILURE,
      ],
    },
  };
};

export const createCompletedDoctorAssessment = (body: DoctorAssessmentParams) => {
  return {
    [RSAA]: {
      endpoint: '/doctor-assessments/complete',
      method: 'POST',
      body,
      types: [
        types.CREATE_COMPLETED_DOCTOR_ASSESSMENT_REQUEST,
        {
          type: types.CREATE_COMPLETED_DOCTOR_ASSESSMENT_SUCCESS,
          meta: {
            notification: {
              type: 'success',
              title: 'TREATMENT-PLAN.TEXT.ASSESSMENT-COMPLETE-CREATE-SUCCESS',
            },
          },
        },
        {
          type: types.CREATE_COMPLETED_DOCTOR_ASSESSMENT_FAILURE,
          meta: {
            notification: {
              type: 'danger',
              title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
            },
          },
        },
      ],
    },
  };
};

export const createDraftedDoctorAssessment = (body: DoctorAssessmentParams) =>
  createAction<any, CreateAssessmentDraftResponse, undefined>({
    endpoint: '/doctor-assessments/save-for-later',
    method: 'POST',
    types: [
      types.CREATE_DRAFTED_DOCTOR_ASSESSMENT_REQUEST,
      types.CREATE_DRAFTED_DOCTOR_ASSESSMENT_SUCCESS,
      types.CREATE_DRAFTED_DOCTOR_ASSESSMENT_FAILURE,
    ],
    // @ts-ignore custom body middleware
    body,
  });

export const updateCompletedDoctorAssessment = (body: DoctorAssessmentParams, doctorAssessmentId: string) => {
  return {
    [RSAA]: {
      endpoint: `/doctor-assessments/${doctorAssessmentId}`,
      method: 'PUT',
      body,
      params: {
        doctorAssessmentId,
      },
      types: [
        types.UPDATE_COMPLETED_DOCTOR_ASSESSMENT_REQUEST,
        {
          type: types.UPDATE_COMPLETED_DOCTOR_ASSESSMENT_SUCCESS,
          meta: {
            notification: {
              type: 'success',
              title: 'TREATMENT-PLAN.TEXT.ASSESSMENT-COMPLETE-UPDATE-SUCCESS',
            },
          },
        },
        {
          type: types.UPDATE_COMPLETED_DOCTOR_ASSESSMENT_FAILURE,
          meta: {
            notification: {
              type: 'danger',
              title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
            },
          },
        },
      ],
    },
  };
};

export const updateDoctorAssessmentToCompleted = (body: DoctorAssessmentParams, doctorAssessmentId: string) => {
  return {
    [RSAA]: {
      endpoint: `/doctor-assessments/${doctorAssessmentId}/complete`,
      method: 'PUT',
      body,
      params: {
        doctorAssessmentId,
      },
      types: [
        types.UPDATE_DOCTOR_ASSESSMENT_TO_COMPLETED_REQUEST,
        {
          type: types.UPDATE_DOCTOR_ASSESSMENT_TO_COMPLETED_SUCCESS,
          meta: {
            notification: {
              type: 'success',
              title: 'TREATMENT-PLAN.TEXT.ASSESSMENT-COMPLETE-CREATE-SUCCESS',
            },
          },
        },
        {
          type: types.UPDATE_DOCTOR_ASSESSMENT_TO_COMPLETED_FAILURE,
          meta: {
            notification: {
              type: 'danger',
              title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
            },
          },
        },
      ],
    },
  };
};

export const updateDoctorAssessmentToSaveForLater = (body: DoctorAssessmentParams, doctorAssessmentId: string) =>
  createAction({
    endpoint: `/doctor-assessments/${doctorAssessmentId}/save-for-later`,
    method: 'PUT',
    params: {
      doctorAssessmentId,
    },
    types: [
      types.UPDATE_DOCTOR_ASSESSMENT_TO_SAVE_FOR_LATER_REQUEST,
      types.UPDATE_DOCTOR_ASSESSMENT_TO_SAVE_FOR_LATER_SUCCESS,
      types.UPDATE_DOCTOR_ASSESSMENT_TO_SAVE_FOR_LATER_FAILURE,
    ],
    // @ts-ignore custom body middleware
    body,
  });

export const removeDoctorAssessment = (doctorAssessmentId: string) => {
  return {
    [RSAA]: {
      endpoint: `/doctor-assessments/${doctorAssessmentId}`,
      method: 'DELETE',
      params: {
        doctorAssessmentId,
      },
      types: [
        types.REMOVE_DOCTOR_ASSESSMENT_REQUEST,
        {
          type: types.REMOVE_DOCTOR_ASSESSMENT_SUCCESS,
          meta: {
            notification: {
              type: 'success',
              title: 'TREATMENT-PLAN.TEXT.ASSESSMENT-DRAFT-REMOVE-SUCCESS',
            },
          },
        },
        {
          type: types.REMOVE_DOCTOR_ASSESSMENT_FAILURE,
          meta: {
            notification: {
              type: 'danger',
              title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
            },
          },
        },
      ],
    },
  };
};

export const sendAttachment = (patientId: string, clinicId: string, values: MedicalAttachmentData) =>
  createAction<any, string, {}>({
    endpoint: `/clinic/${clinicId}/clinic-patients/${patientId}/attachments`,
    method: 'POST',
    // @ts-ignore custom body middleware
    body: values,
    types: [
      types.SEND_ATTACHMENT_REQUEST,
      {
        type: types.SEND_ATTACHMENT_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'TREATMENT-PLAN-PAGE.TEXT.ATTACHMENTS-SUCCESS',
          },
        },
      },
      {
        type: types.SEND_ATTACHMENT_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
        },
      },
    ],
  });

export const sendRequestForApproval = (bodyParams: InsuranceApprovalParams) =>
  createAction<any, string, {}>({
    endpoint: '/insurance-approvals',
    method: 'POST',
    // @ts-ignore custom body middleware
    body: bodyParams,
    types: [
      types.SEND_REQUEST_FOR_APPROVAL_REQUEST,
      {
        type: types.SEND_REQUEST_FOR_APPROVAL_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'INSURANCE-APPROVALS.TEXT.SEEK-APPROVAL-CREATE-SUCCESS',
          },
        },
      },
      {
        type: types.SEND_REQUEST_FOR_APPROVAL_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
        },
      },
    ],
  });

export const fetchClinicLogo = (clinicId: string) => {
  return {
    [RSAA]: {
      endpoint: `/clinics/${clinicId}/logo`,
      method: 'GET',
      types: [types.FETCH_CLINIC_LOGO_REQUEST, types.FETCH_CLINIC_LOGO_SUCCESS, types.FETCH_CLINIC_LOGO_FAILURE],
    },
  };
};

export const getPackageItems = (patientId: string, packageSnapshotId: string) =>
  createAction<any, { data: AssessmentPackageItem[] }, {}>({
    endpoint: `/clinic-patients/${patientId}/package-snapshots/${packageSnapshotId}/items-for-assessment`,
    method: 'GET',
    types: [types.GET_PACKAGE_ITEMS_REQUEST, types.GET_PACKAGE_ITEMS_SUCCESS, types.GET_PACKAGE_ITEMS_FAILURE],
  });

export const fetchAttachmentAppointmentOptions = (
  doctorId: string,
  patientId: string,
  params: AttachmentAppointmentOptionsParams,
) =>
  createAction<any, AttachmentAppointmentOptionsPayload, {}>({
    endpoint: `/doctors/${doctorId}/patients/${patientId}/appointments/dropdown`,
    method: 'GET',
    types: [
      types.FETCH_APPOINTMENT_OPTIONS_REQUEST,
      types.FETCH_APPOINTMENT_OPTIONS_SUCCESS,
      types.FETCH_APPOINTMENT_OPTIONS_FAILURE,
    ],
    // @ts-ignore custom params middleware
    params: params,
  });

export interface BillingInformation {
  performerBillingItemId?: string;
  invoicePackageSnapshotId?: string;
  invoicePackageItemSnapshotId?: string;
  quantity: number;
  totalItemBillingSum: number;
}

export interface UpdatedBillingStatus {
  performerBillingItemId: string;
  invoicePackageItemSnapshotId?: string;
  status: Option<BillingItemPaymentStatus>;
}

export interface UpdatedStatusResponse {
  data: UpdatedBillingStatus;
}

export interface UpdatedStatusesResponse {
  data: UpdatedBillingStatus[];
}

export interface SeekBillingItemInformation {
  performerBillingItemId?: string;
  quantity: number;
  invoicePackageSnapshotId?: string;
  invoicePackageItemSnapshotId?: string;
  billingItemSourceType: BillingItemSourceType;
  preAuthorized: boolean;
}

export interface SeekBillingBody {
  insuranceId?: string;
  doctorId: string;
  patientId: string;
  clinicBranchId: string;
  billNote?: string;
  totalRegularBillingSum: number;
  billingItems: SeekBillingItemInformation[];
}

export const updateNotCoveredBillingStatus = (appointmentId: string, notCoveredItem: BillingInformation) =>
  createAction<any, UpdatedStatusResponse, {}>({
    endpoint: `/appointments/${appointmentId}/invoice-billing-item-status`,
    method: 'POST',
    types: [
      types.UPDATE_NOT_COVERED_BILLING_STATUS_REQUEST,
      types.UPDATE_NOT_COVERED_BILLING_STATUS_SUCCESS,
      types.UPDATE_NOT_COVERED_BILLING_STATUS_FAILURE,
    ],
    // @ts-ignore custom body middleware
    body: notCoveredItem,
  });

export const updateCoveredBillingStatus = (
  appointmentId: string,
  patientInsuranceId: string,
  coveredItem: BillingInformation,
) =>
  createAction<any, UpdatedStatusResponse, {}>({
    endpoint: `/appointments/${appointmentId}/insurances/${patientInsuranceId}/insurance-billing-item-status`,
    method: 'POST',
    types: [
      types.UPDATE_COVERED_BILLING_STATUS_REQUEST,
      types.UPDATE_COVERED_BILLING_STATUS_SUCCESS,
      types.UPDATE_COVERED_BILLING_STATUS_FAILURE,
    ],
    // @ts-ignore custom body middleware
    body: coveredItem,
  });

export const sendSeekForBilling = (appointmentId: string, seekBillingBody: SeekBillingBody) =>
  createAction<any, string, {}>({
    endpoint: `/appointments/${appointmentId}/seek-for-billing`,
    method: 'POST',
    types: [
      types.SEND_SEEK_FOR_BILLING_REQUEST,
      types.SEND_SEEK_FOR_BILLING_SUCCESS,
      types.SEND_SEEK_FOR_BILLING_FAILURE,
    ],
    // @ts-ignore custom body middleware
    body: seekBillingBody,
  });

export const updateInsuranceBillingItemStatuses = (
  appointmentId: string,
  patientInsuranceId: string,
  coveredItems: BillingInformation[],
) => ({
  [RSAA]: {
    endpoint: `/appointments/${appointmentId}/insurances/${patientInsuranceId}/insurance-billing-item-statuses`,
    method: 'POST',
    types: [
      types.UPDATE_COVERED_BILLING_STATUSES_REQUEST,
      types.UPDATE_COVERED_BILLING_STATUSES_SUCCESS,
      types.UPDATE_COVERED_BILLING_STATUSES_FAILURE,
    ],
    body: coveredItems,
  },
});

export const updateInvoiceBillingItemStatuses = (appointmentId: string, notCoveredItems: BillingInformation[]) => ({
  [RSAA]: {
    endpoint: `/appointments/${appointmentId}/invoice-billing-item-statuses`,
    method: 'POST',
    types: [
      types.UPDATE_NOT_COVERED_BILLING_STATUSES_REQUEST,
      types.UPDATE_NOT_COVERED_BILLING_STATUSES_SUCCESS,
      types.UPDATE_NOT_COVERED_BILLING_STATUSES_FAILURE,
    ],
    body: notCoveredItems,
  },
});

export interface FillRequestPayload {
  data: FillRequestData;
}

interface FillRequestData {
  attachments: MedicalAttachment[];
  diagnoses: AssessmentDiagnose;
  illnessDurationDays?: number;
  patientIllness: AssessmentPatientIllness;
  medications: PrescriptionData[];
  items: AssessmentBillingItem[];
  appointmentType?: Option<InitialAssessmentAppointmentType>;
  patientVitals: PatientVitals;
}

export const fetchRequestRehydrationData = (appointmentId: string) =>
  createAction<any, FillRequestPayload, undefined>({
    endpoint: `/insurance-approvals/${appointmentId}/fill-request`,
    method: 'GET',
    types: [
      types.FETCH_REQUEST_REHYDRATION_DATA_REQUEST,
      types.FETCH_REQUEST_REHYDRATION_DATA_SUCCESS,
      types.FETCH_REQUEST_REHYDRATION_DATA_FAILURE,
    ],
  });

export const setAssessmentBillingItem = (
  assessmentBillingItem: AssessmentBillingItem,
  rowIndex: number,
): AnyAction => ({
  type: types.SET_ASSESSMENT_BILLING_ITEM,
  payload: { assessmentBillingItem, rowIndex },
});

export const resetAssessmentBillingItem = () => ({
  type: types.RESET_ASSESSMENT_BILLING_ITEM,
});

export const setInformation = (description: ProcedureResponse | DiagnosesResponse, title: string) => ({
  type: types.SET_INFORMATION,
  payload: { description, title },
});

export const logDoctorAssessmentPrintAction = (appointmentId: string) => ({
  [RSAA]: {
    endpoint: `/appointments/${appointmentId}/log-doctor-assessment-print-action`,
    method: 'POST',
    types: [types.LOG_PRINT_ACTIONS_REQUEST, types.LOG_PRINT_ACTIONS_SUCCESS, types.LOG_PRINT_ACTIONS_FAILURE],
    body: undefined,
  },
});

export const setAssessmentReferral = (assessmentReferral: AssessmentReferral, rowIndex: number): AnyAction => ({
  type: types.SET_ASSESSMENT_REFERRAL,
  payload: { assessmentReferral, rowIndex },
});

export const setAssessmentOrder = (assessmentOrder: AssessmentOrder, rowIndex: number): AnyAction => ({
  type: types.SET_ASSESSMENT_ORDER,
  payload: { assessmentOrder, rowIndex },
});

export const resetAssessmentOrder = (): AnyAction => ({
  type: types.RESET_ASSESSMENT_ORDER,
});

export const setAssessmentOrderTicketId = (assessmentOrderId: string): AnyAction => ({
  type: types.SET_ASSESSMENT_ORDER_TICKET_ID,
  payload: assessmentOrderId,
});

export const resetAssessmentOrderTicketId = (): AnyAction => ({
  type: types.RESET_ASSESSMENT_ORDER_TICKET_ID,
});

export const cancelAssessmentOrder = (assessmentId: string, assessmentOrderId: string) =>
  createAction({
    endpoint: `/doctor-assessments/${assessmentId}/orders/${assessmentOrderId}/cancel`,
    method: 'PUT',
    types: [
      types.CANCEL_ASSESSMENT_ORDER_REQUEST,
      {
        type: types.CANCEL_ASSESSMENT_ORDER_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'CORE.TEXT.STATUS-CHANGE-SUCCESS',
          },
        },
      },
      {
        type: types.CANCEL_ASSESSMENT_ORDER_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
        },
      },
    ],
  });

export const sendToOrder = (assessmentId: string) =>
  createAction({
    endpoint: `/doctor-assessments/${assessmentId}/orders/send`,
    method: 'PUT',
    types: [
      types.SEND_TO_ORDER_REQUEST,
      {
        type: types.SEND_TO_ORDER_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            title: 'TREATMENT-PLAN.TEXT.SEND-TO-ORDER-SUCCESS',
          },
        },
      },
      {
        type: types.SEND_TO_ORDER_FAILURE,
        meta: {
          notification: {
            type: 'danger',
            title: 'CORE.TEXT.DEFAULT-FAILURE-MESSAGE',
          },
        },
      },
    ],
  });

export const fetchUpdatedOrdersStatuses = (appointmentId: string) =>
  createAction<any, FetchUpdatedOrdersStatusesResponse, {}>({
    endpoint: `appointments/${appointmentId}/orders/statuses`,
    method: 'GET',
    types: [
      types.UPDATE_ORDERS_STATUSES_REQUEST,
      types.UPDATE_ORDERS_STATUSES_SUCCESS,
      types.UPDATE_ORDERS_STATUSES_FAILURE,
    ],
  });

export const resetAssessmentReferral = () => ({
  type: types.RESET_ASSESSMENT_REFERRAL,
});

export const resetDoctorAssessmentData = () => ({
  type: types.RESET_DOCTOR_ASSESSMENT_DATA,
});

export const getBillingItemQuantityInformation = (packageSnapshotId: string, packageItemSnapshotId: string) =>
  createAction<any, QueryResponse<BillingItemQuantityInformation>, {}>({
    endpoint: `package-snapshots/${packageSnapshotId}/items/${packageItemSnapshotId}/actual-quantities`,
    method: 'GET',
    types: [
      types.GET_BILLING_ITEM_QUANTITY_INFO_REQUEST,
      types.GET_BILLING_ITEM_QUANTITY_INFO_SUCCESS,
      types.GET_BILLING_ITEM_QUANTITY_INFO_FAILURE,
    ],
  });

export const getGeneralAssessmentInformation = (appointmentId: string) =>
  createAction<any, QueryResponse<GeneralAssessmentInformation>, {}>({
    endpoint: `/appointments/${appointmentId}/insurance-approvals/empty`,
    method: 'GET',
    types: [
      types.GET_GENERAL_ASSESSMENT_INFORMATION_REQUEST,
      types.GET_GENERAL_ASSESSMENT_INFORMATION_SUCCESS,
      types.GET_GENERAL_ASSESSMENT_INFORMATION_FAILURE,
    ],
  });

export const checkVisitNumberInformation = (appointmentId: string) =>
  createAction<any, QueryResponse<VisitNumberInformation>, {}>({
    endpoint: `/appointments/${appointmentId}/visit-number`,
    method: 'GET',
    types: [
      types.CHECK_VISIT_NUMBER_INFORMATION_REQUEST,
      types.CHECK_VISIT_NUMBER_INFORMATION_SUCCESS,
      types.CHECK_VISIT_NUMBER_INFORMATION_FAILURE,
    ],
  });
