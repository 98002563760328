import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import { getInvalidatesTags } from 'app/services/medicalLibraries/helpers/getInvalidatesTags';
import {
  AllergyBodyParams,
  CheckNameParams,
  CheckNameResponse,
  DrugBodyParams,
  DrugsQueryParams,
  FamilyIllnessBodyParams,
  ImportMedicalLibraryItemsParams,
  PatientIllnessBodyParams,
  SurgeryBodyParams,
} from 'app/services/medicalLibraries/types';
import {
  MedicalLibrariesAllergy,
  MedicalLibrariesDiagnose,
  MedicalLibrariesDrug,
  MedicalLibrariesFamilyIllness,
  MedicalLibrariesPatientIllness,
  MedicalLibrariesSurgery,
  QueryParams,
  QueryResponse,
  QueryResponseWithPagination,
} from 'app/types';

export const medicalLibrariesApi = createApi({
  reducerPath: 'medicalLibrariesApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    'FamilyIllnesses',
    'FamilyIllness',
    'PatientIllnesses',
    'PatientIllness',
    'Surgeries',
    'Surgery',
    'Diagnoses',
    'Drugs',
    'Drug',
    'Allergies',
    'Allergy',
  ],
  endpoints: (builder) => ({
    // Family Illnesses: [EP-1]
    getFamilyIllnesses: builder.query<
      QueryResponseWithPagination<MedicalLibrariesFamilyIllness[]>,
      QueryParams | undefined
    >({
      query: (params) => ({
        url: '/family-illnesses',
        method: 'GET',
        params,
      }),
      providesTags: ['FamilyIllnesses'],
    }),

    // Family Illnesses: [EP-2]
    addFamilyIllness: builder.mutation<void, FamilyIllnessBodyParams>({
      query: (body) => ({
        url: '/family-illnesses',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['FamilyIllnesses'],
    }),

    // Family Illnesses: [EP-3]
    editFamilyIllness: builder.mutation<void, { illnessId: string; body: FamilyIllnessBodyParams }>({
      query: ({ illnessId, body }) => ({
        url: `/family-illnesses/${illnessId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['FamilyIllnesses', 'FamilyIllness'],
    }),

    // Family Illnesses: [EP-4]
    getFamilyIllness: builder.query<QueryResponse<MedicalLibrariesFamilyIllness>, string>({
      query: (illnessId) => ({
        url: `/family-illnesses/${illnessId}`,
        method: 'GET',
      }),
      providesTags: ['FamilyIllness'],
    }),

    // Family Illnesses: [EP-5]
    checkFamilyIllnessName: builder.query<CheckNameResponse, CheckNameParams>({
      query: (params) => ({
        url: '/family-illnesses/check-name',
        method: 'GET',
        params,
      }),
    }),

    // Patient Illnesses: [EP-6]
    getPatientIllnesses: builder.query<
      QueryResponseWithPagination<MedicalLibrariesPatientIllness[]>,
      QueryParams | undefined
    >({
      query: (params) => ({
        url: '/patient-illnesses',
        method: 'GET',
        params,
      }),
      providesTags: ['PatientIllnesses'],
    }),

    // Patient Illnesses: [EP-7]
    checkPatientIllnessName: builder.query<CheckNameResponse, CheckNameParams>({
      query: (params) => ({
        url: '/patient-illnesses/check-name',
        method: 'GET',
        params,
      }),
    }),

    // Patient Illnesses: [EP-8]
    addPatientIllness: builder.mutation<void, PatientIllnessBodyParams>({
      query: (body) => ({
        url: '/patient-illnesses',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PatientIllnesses'],
    }),

    // Patient Illnesses: [EP-9]
    editPatientIllness: builder.mutation<void, { illnessId: string; body: PatientIllnessBodyParams }>({
      query: ({ illnessId, body }) => ({
        url: `/patient-illnesses/${illnessId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['PatientIllnesses', 'PatientIllness'],
    }),

    // Patient Illnesses: [EP-10]
    getPatientIllness: builder.query<QueryResponse<MedicalLibrariesPatientIllness>, string>({
      query: (illnessId) => ({
        url: `/patient-illnesses/${illnessId}`,
        method: 'GET',
      }),
      providesTags: ['PatientIllness'],
    }),

    // Surgeries: [EP-11]
    getSurgeries: builder.query<QueryResponseWithPagination<MedicalLibrariesSurgery[]>, QueryParams | undefined>({
      query: (params) => ({
        url: '/surgeries',
        method: 'GET',
        params,
      }),
      providesTags: ['Surgeries'],
    }),

    // Surgeries: [EP-12]
    getSurgery: builder.query<QueryResponse<MedicalLibrariesSurgery>, string>({
      query: (surgeryId) => ({
        url: `/surgeries/${surgeryId}`,
        method: 'GET',
      }),
      providesTags: ['Surgery'],
    }),

    // Surgeries: [EP-13]
    addSurgery: builder.mutation<void, SurgeryBodyParams>({
      query: (body) => ({
        url: '/surgeries',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Surgeries'],
    }),

    // Surgeries: [EP-14]
    editSurgery: builder.mutation<void, { surgeryId: string; body: SurgeryBodyParams }>({
      query: ({ surgeryId, body }) => ({
        url: `/surgeries/${surgeryId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Surgeries', 'Surgery'],
    }),

    // Surgeries: [EP-15]
    checkSurgeryName: builder.query<CheckNameResponse, CheckNameParams>({
      query: (params) => ({
        url: '/surgeries/check-name',
        method: 'GET',
        params,
      }),
    }),

    // Diagnoses: [EP-16]
    getDiagnoses: builder.query<QueryResponseWithPagination<MedicalLibrariesDiagnose[]>, QueryParams | undefined>({
      query: (params) => ({
        url: '/diagnoses',
        method: 'GET',
        params,
      }),
      providesTags: ['Diagnoses'],
    }),

    // Drugs: [EP-17]
    getDrugs: builder.query<QueryResponseWithPagination<MedicalLibrariesDrug[]>, DrugsQueryParams | undefined>({
      query: (params) => ({
        url: '/drugs',
        method: 'GET',
        params,
      }),
      providesTags: ['Drugs'],
    }),

    // Drugs: [EP-18]
    addDrug: builder.mutation<void, DrugBodyParams>({
      query: (body) => ({
        url: '/drugs',
        methods: 'POST',
        body,
      }),
      invalidatesTags: ['Drugs'],
    }),

    // Drugs: [EP-19]
    editDrug: builder.mutation<void, { drugId: string; body: DrugBodyParams }>({
      query: ({ drugId, body }) => ({
        url: `/drugs/${drugId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Drugs', 'Drug'],
    }),

    // Drugs: [EP-20]
    getDrug: builder.query<QueryResponse<MedicalLibrariesDrug>, string>({
      query: (drugId) => ({
        url: `/drugs/${drugId}`,
        method: 'GET',
      }),
      providesTags: ['Drug'],
    }),

    // Allergies: [EP-21]
    getAllergies: builder.query<QueryResponseWithPagination<MedicalLibrariesAllergy[]>, QueryParams | undefined>({
      query: (params) => ({
        url: '/allergies',
        method: 'GET',
        params,
      }),
      providesTags: ['Allergies'],
    }),

    // Allergies: [EP-22]
    addAllergy: builder.mutation<void, AllergyBodyParams>({
      query: (body) => ({
        url: '/allergies',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Allergies'],
    }),

    // Allergies: [EP-23]
    editAllergy: builder.mutation<void, { allergyId: string; body: AllergyBodyParams }>({
      query: ({ allergyId, body }) => ({
        url: `/allergies/${allergyId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Allergies', 'Allergy'],
    }),

    // Allergies: [EP-24]
    getAllergy: builder.query<QueryResponse<MedicalLibrariesAllergy>, string>({
      query: (allergyId) => ({
        url: `/allergies/${allergyId}`,
        method: 'GET',
      }),
      providesTags: ['Allergy'],
    }),

    // Allergies: [EP-25]
    checkAllergyName: builder.query<CheckNameResponse, CheckNameParams>({
      query: (params) => ({
        url: '/allergies/check-name',
        method: 'GET',
        params,
      }),
    }),

    // Common: [EP-26]
    importMedicalLibraryItems: builder.mutation<void, ImportMedicalLibraryItemsParams>({
      query: ({ activeTabName, body }) => ({
        url: `/${activeTabName}/import`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, params) => getInvalidatesTags(params.activeTabName),
    }),
  }),
});

export const {
  useGetFamilyIllnessesQuery, // [EP-1]
  useAddFamilyIllnessMutation, // [EP-2]
  useEditFamilyIllnessMutation, // [EP-3]
  useGetFamilyIllnessQuery, // [EP-4]
  useCheckFamilyIllnessNameQuery, // [EP-5]

  useGetPatientIllnessesQuery, // [EP-6]
  useCheckPatientIllnessNameQuery, // [EP-7]
  useAddPatientIllnessMutation, // [EP-8]
  useEditPatientIllnessMutation, // [EP-9]
  useGetPatientIllnessQuery, // [EP-10]

  useGetSurgeriesQuery, // [EP-11]
  useGetSurgeryQuery, // [EP-12]
  useAddSurgeryMutation, // [EP-13]
  useEditSurgeryMutation, // [EP-14]
  useCheckSurgeryNameQuery, // [EP-15]

  useGetDiagnosesQuery, // [EP-16]

  useGetDrugsQuery, // [EP-17]
  useAddDrugMutation, // [EP-18]
  useEditDrugMutation, // [EP-19]
  useGetDrugQuery, // [EP-20]

  useGetAllergiesQuery, // [EP-21]
  useAddAllergyMutation, // [EP-22]
  useEditAllergyMutation, // [EP-23]
  useGetAllergyQuery, // [EP-24]
  useCheckAllergyNameQuery, // [EP-25]

  useImportMedicalLibraryItemsMutation, // [EP-26]
} = medicalLibrariesApi;
