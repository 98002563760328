import { Normalizer } from 'redux-form/lib/Field';

export const percentageNormalizer: Normalizer = (value: string | undefined): string | undefined => {
  if (!value) {
    return value;
  }

  const trimmedValue = value.trim();
  const discountValue = Number(trimmedValue);

  if (isNaN(discountValue)) {
    return undefined;
  }

  return String(discountValue < 0 ? 0 : discountValue > 100 ? 100 : Math.floor(discountValue));
};
