import { useEffect } from 'react';
import { useAppDispatch, useHubConnection } from 'app/helpers';
import { isDevelopmentEnv } from 'app/constants';
import { WS_UPDATE_CALENDAR } from 'app/redux/calendar/calendar.types';

export type RefreshCalendarEvent = {
  BranchId: string;
  ClinicId: string;
  DateFrom: string;
  DateTo: string;
  DoctorId: string;
  ProductId: string;
  EventName: string;
};

export const useLiveRefresh = () => {
  const dispatch = useAppDispatch();
  const connection = useHubConnection('live-refresh-hub');

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on('RefreshCalendar', (event) => {
            const refreshCalendarEvent: RefreshCalendarEvent = JSON.parse(event);
            dispatch({ type: WS_UPDATE_CALENDAR, payload: refreshCalendarEvent });
          });
        })
        .catch((error) => {
          if (isDevelopmentEnv) {
            console.error(error);
          }
        });
    }

    return () => {
      if (connection) {
        connection.stop().catch((error) => {
          if (isDevelopmentEnv) {
            console.error(error);
          }
        });
      }
    };
  }, [connection, dispatch]);
};
