import { TagDescription } from '@reduxjs/toolkit/query';
import { ImportMedicalLibraryItemsTagTypes } from 'app/services/medicalLibraries/types';
import { medicalLibrariesTab } from 'app/routes/settings/constants';
import { MedicalLibrariesTab } from 'app/routes/settings/types/medicalLibraries';

export const getInvalidatesTags = (
  activeTab: MedicalLibrariesTab,
): TagDescription<ImportMedicalLibraryItemsTagTypes>[] => {
  switch (activeTab) {
    case medicalLibrariesTab.familyIllnesses:
      return ['FamilyIllnesses'];
    case medicalLibrariesTab.patientIllnesses:
      return ['PatientIllnesses'];
    case medicalLibrariesTab.surgeries:
      return ['Surgeries'];
    case medicalLibrariesTab.diagnoses:
      return ['Diagnoses'];
    case medicalLibrariesTab.drugs:
      return ['Drugs'];
    case medicalLibrariesTab.allergies:
      return ['Allergies'];
    default:
      return [];
  }
};
