import { useCallback } from 'react';
import { Button } from 'reactstrap';
import { getPatientFullName, useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { InfoBar } from 'app/shared';
import { resetPreselectedPatient } from 'app/redux/appointment/appointment.actions';

export const PatientBar = () => {
  const dispatch = useAppDispatch();
  const { formatMessage, locale } = useAppIntl();
  const preselectedPatient = useAppSelector((state) => state.appointment.preselectedPatient);

  const onCancel = useCallback(() => dispatch(resetPreselectedPatient()), [dispatch]);

  if (!preselectedPatient) {
    return null;
  }

  return (
    <InfoBar>
      <div>
        {formatMessage({ id: 'CALENDAR-TABLE.TEXT.CREATING-APPOINTMENT' })}{' '}
        {getPatientFullName(preselectedPatient, locale)}
      </div>
      <Button color="light" className="mx-2 my-1" size="sm" onClick={onCancel}>
        {formatMessage({ id: 'CORE.BUTTON.CANCEL' })}
      </Button>
    </InfoBar>
  );
};
