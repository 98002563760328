import { useCallback, useMemo, useState } from 'react';
import { ColumnFormatter } from 'react-bootstrap-table-next';
import { useAppDispatch, useAppIntl, useCurrentUserPermissions } from 'app/helpers';
import { DotsMenu, DotsMenuItem } from 'app/components';
import { ConfirmationModal } from 'app/shared';
import { appointmentToPatientShortAdapter } from 'app/shared/patientSearch/patientShortDetails/helpers';
import { cancelWaitingAppointment, setAppointment, setPatient } from 'app/redux/appointment/appointment.actions';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { Appointment } from 'app/types';

interface Props {
  appointment: Appointment;
}

const Actions = ({ appointment }: Props) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const { allowedForAppointmentsActions, allowedForEditAppointments } = useCurrentUserPermissions();

  // Confirmation modal
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationDisabled, setConfirmationDisabled] = useState(false);
  const showConfirmation = () => setConfirmation(true);
  const closeConfirmation = () => setConfirmation(false);

  const onConfirm = useCallback(() => {
    setConfirmationDisabled(true);
    dispatch(cancelWaitingAppointment(appointment.id));
  }, [appointment, dispatch]);

  const onEdit = useCallback(() => {
    dispatch(setAppointment(appointment));
    dispatch(setPatient(appointmentToPatientShortAdapter(appointment)));
    dispatch(toggleModal('editWaitingAppointment'));
  }, [appointment, dispatch]);

  const onPromote = useCallback(() => {
    dispatch(setAppointment(appointment));
    dispatch(setPatient(appointmentToPatientShortAdapter(appointment)));
    dispatch(toggleModal('promoteWaitingAppointment'));
  }, [appointment, dispatch]);

  const items: DotsMenuItem[] = useMemo(
    () => [
      {
        isVisible: !!allowedForEditAppointments,
        label: formatMessage({ id: 'CORE.BUTTON.EDIT' }),
        onClick: onEdit,
      },
      {
        isVisible: allowedForAppointmentsActions,
        label: formatMessage({ id: 'CORE.BUTTON.CANCEL' }),
        onClick: showConfirmation,
      },
      {
        isVisible: !!allowedForEditAppointments,
        label: formatMessage({ id: 'APPOINTMENTS.BUTTON.MOVE-TO-REGULAR' }),
        onClick: onPromote,
      },
    ],
    [allowedForAppointmentsActions, allowedForEditAppointments, formatMessage, onEdit, onPromote],
  );

  return (
    <>
      <DotsMenu container=".bookedWaitingAppointmentsWrapper" items={items} />
      <ConfirmationModal
        description="APPOINTMENTS.TEXT.CANCEL-CONFIRMATION"
        disabled={confirmationDisabled}
        isOpen={confirmation}
        onCancel={closeConfirmation}
        onClose={closeConfirmation}
        onConfirm={onConfirm}
      />
    </>
  );
};

export const ActionsFormatter: ColumnFormatter<Appointment> = (cell, row) => <Actions appointment={row} />;
