import { Normalizer } from 'redux-form/lib/Field';

export const discountAmountNormalizer =
  (minValue: number, maxValue: number): Normalizer =>
  (value: string | undefined): string | undefined => {
    if (!value) {
      return value;
    }

    const trimmedValue = value.trim();
    const discountValue = Number(trimmedValue);

    if (isNaN(discountValue)) {
      return String(minValue); // Default to minValue if non-numeric
    }

    return String(Math.min(Math.max(discountValue, minValue), maxValue));
  };
