import { useCallback } from 'react';
import { useAppDispatch } from 'app/helpers';
import { fetchDropdownOptions } from 'app/redux/dropdownOptions/dropdownOptions.actions';
import { DropdownOptionsParams } from 'app/redux/dropdownOptions/types';

export const useFetchOptions = (insuranceId: string | undefined, icdCodesToExclude: string[] | undefined) => {
  const dispatch = useAppDispatch();

  const fetchIcd10CodeOptions = useCallback(
    (params: DropdownOptionsParams) => {
      return dispatch(fetchDropdownOptions('/diagnoses/fuzzy-search', { ...params, icdCodesToExclude, insuranceId }));
    },
    [dispatch, icdCodesToExclude, insuranceId],
  );

  return {
    fetchIcd10CodeOptions,
  };
};
