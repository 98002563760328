import { useCallback } from 'react';
import moment from 'moment';
import { getApiDate, mapOptionItems, useAppDispatch } from 'app/helpers';
import { fetchDropdownOptions } from 'app/redux/dropdownOptions/dropdownOptions.actions';
import { BranchClinicMode, BranchStatus, Option, RemoteDataParams } from 'app/types';

export const useFetchOptions = (
  date: Date | undefined,
  branchId: string | undefined,
  clinicId: string | undefined,
  specialityId: string | undefined | null,
  subSpecialityId: string | undefined,
  doctors: Option[] | undefined,
) => {
  const dispatch = useAppDispatch();

  const fetchBranchOptions = useCallback(
    (params: RemoteDataParams) =>
      dispatch(
        fetchDropdownOptions('/branches/dropdown', {
          ...params,
          clinicIds: clinicId,
          clinicMode: BranchClinicMode.Operational,
          statuses: BranchStatus.Online,
        }),
      ),
    [dispatch, clinicId],
  );

  const fetchPractitionerOptions = useCallback(
    (params: RemoteDataParams) => {
      if (branchId) {
        return dispatch(
          fetchDropdownOptions(`/day-calendars/branch/${branchId}/practitioners/dropdown`, {
            ...params,
            specialitiesIds: specialityId ? [specialityId] : [],
            subSpecialityId,
          }),
        );
      }
    },
    [branchId, dispatch, specialityId, subSpecialityId],
  );

  const fetchPractitionersOptions = useCallback(
    (params: RemoteDataParams) => {
      if (branchId && date) {
        const apiDate = getApiDate(date);

        return dispatch(
          fetchDropdownOptions(`/day-calendars/branch/${branchId}/practitioners/dropdown`, {
            ...params,
            startDate: apiDate,
            endDate: apiDate,
            specialitiesIds: specialityId ? [specialityId] : [],
            subSpecialityId,
          }),
        );
      }
    },
    [branchId, date, dispatch, specialityId, subSpecialityId],
  );

  const fetchSpecialityOptions = useCallback(
    (params: RemoteDataParams) => {
      if (branchId && date) {
        const apiDate = getApiDate(date);

        return dispatch(
          fetchDropdownOptions(`/day-calendars/branch/${branchId}/specialities/dropdown`, {
            ...params,
            startDate: apiDate,
            endDate: apiDate,
          }),
        );
      }
    },
    [branchId, date, dispatch],
  );

  const fetchWeekSpecialityOptions = useCallback(
    (params: RemoteDataParams) => {
      if (branchId && date) {
        return dispatch(
          fetchDropdownOptions(`/day-calendars/branch/${branchId}/specialities/dropdown`, {
            ...params,
            startDate: getApiDate(moment(date).startOf('week').toDate()),
            endDate: getApiDate(moment(date).endOf('week').toDate()),
          }),
        );
      }
    },
    [branchId, date, dispatch],
  );

  const fetchSubSpecialityOptions = useCallback(
    (params: RemoteDataParams) =>
      dispatch(
        fetchDropdownOptions('/subspecialities/dropdown', {
          ...params,
          specialityIds: [specialityId],
        }),
      ),
    [specialityId, dispatch],
  );
  const fetchServiceOptions = useCallback(
    (params: RemoteDataParams) => {
      return dispatch(
        fetchDropdownOptions('/clinic-products/dropdown', {
          ...params,
          subSpecialityIds: [subSpecialityId],
          branchIds: [branchId],
          clinicIds: [clinicId],
          specialityIds: [specialityId],
          practitionerIds: doctors && mapOptionItems(doctors),
        }),
      );
    },
    [subSpecialityId, dispatch, branchId, clinicId, specialityId, doctors],
  );

  return {
    fetchBranchOptions,
    fetchPractitionerOptions,
    fetchPractitionersOptions,
    fetchSpecialityOptions,
    fetchSubSpecialityOptions,
    fetchWeekSpecialityOptions,
    fetchServiceOptions,
  };
};
