import { put, select } from 'redux-saga/effects';
import { fetchDayCalendars } from 'app/redux/remoteData/remoteData.actions';
import { RootState } from 'app/types';
import { RefreshCalendarEvent } from 'app/features/calendar/helpers';

export function* wsUpdateDayView(calendarEvent: RefreshCalendarEvent) {
  const {
    remoteData: {
      dayCalendars: { data, params },
    },
  }: RootState = yield select();

  const hasDoctor = data.some((i) => i.doctor.id === calendarEvent.DoctorId);

  if (hasDoctor && params && params.branchId && params.date && params.doctorIds && params.specialityIds) {
    yield put<any>(
      fetchDayCalendars(
        {
          ...params,
          branchId: params.branchId,
          date: params.date,
          doctorIds: params.doctorIds,
          specialityIds: params.specialityIds,
          subSpecialityId: params.subSpecialityId,
        },
        { isSilent: true },
      ),
    );
  }
}
