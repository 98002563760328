import { ColumnFormatter } from 'react-bootstrap-table-next';
import { useAppSelector } from 'app/helpers';
import { Loader } from 'app/shared';
import { SelectedDay } from 'app/redux/calendar/types';
import { CalendarItem } from 'app/types';
import { DateFormatterDays } from 'app/features/bookAppointment/bookAppointmentWizard/shared/dateFormatter/dateFormatterDays/dateFormatterDays';
import { DateFormatterHeader } from 'app/features/bookAppointment/bookAppointmentWizard/shared/dateFormatter/dateFormatterHeader/dateFormatterHeader';
import { DateFormatterNoTimeSwitch } from 'app/features/bookAppointment/bookAppointmentWizard/shared/dateFormatter/dateFormatterNoTimeSwitch/dateFormatterNoTimeSwitch';
import 'app/features/bookAppointment/bookAppointmentWizard/shared/dateFormatter/dateFormatter.scss';

interface Props {
  calendarItem: CalendarItem;
  extra?: { onSelectDate: (day: SelectedDay) => void };
}

const Date = ({ calendarItem, extra }: Props) => {
  // Selectors
  const loadingItems = useAppSelector((state) => state.calendar.tableView.loadingItems);

  // Variables
  const loading = loadingItems.some(
    (item) =>
      item.branchId === calendarItem.branch.id &&
      item.doctorId === calendarItem.doctor.id &&
      item.productId === calendarItem.product.value,
  );

  return (
    <div className="dateFormatter">
      <DateFormatterHeader calendarItem={calendarItem} loading={loading} />
      <DateFormatterNoTimeSwitch id={calendarItem.id} />
      {loading && <Loader style={{ height: 190 }} />}
      {!loading && <DateFormatterDays calendarItem={calendarItem} onSelectDate={extra?.onSelectDate} />}
    </div>
  );
};

export const DateFormatter: ColumnFormatter<CalendarItem> = (cell, row, index, formatExtraData) => (
  <Date calendarItem={row} extra={formatExtraData} />
);
