export const settingsPathCore = '/settings';

// Specialities
export const specialitiesPath = `${settingsPathCore}/specialities`;
export const specialityPath = `${specialitiesPath}/:specialityId`;
export const subSpecialitiesPath = `${specialitiesPath}/:specialityId/sub-specialities`;
export const subSpecialityPath = `${specialitiesPath}/:specialityId/sub-specialities/:subSpecialityId`;

// General Settings
export const generalSettingsPath = `${settingsPathCore}/general-settings`;

// Insurance Setup
export const insuranceCompaniesPath = `${settingsPathCore}/insurance-companies`;
export const insuranceCompanyPath = `${insuranceCompaniesPath}/:insuranceCompanyId`;

// Icon Library
export const iconLibraryPath = `${settingsPathCore}/icon-library`;

// Medical Libraries
export const medicalLibrariesTab = {
  familyIllnesses: 'family-illnesses',
  patientIllnesses: 'patient-illnesses',
  surgeries: 'surgeries',
  diagnoses: 'diagnoses',
  drugs: 'drugs',
  allergies: 'allergies',
} as const;
export const medicalLibrariesPathCore = `${settingsPathCore}/medical-libraries`;
export const medicalLibrariesPath = `${medicalLibrariesPathCore}/:tab(${Object.values(medicalLibrariesTab).join('|')})`;

// Patient Classification
export const patientClassificationPath = `${settingsPathCore}/patient-category`;
