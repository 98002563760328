import { generatePath } from 'react-router';
import { dashboardPath, dashboardTabs } from 'app/routes/dashboard/constants';
import { DashboardTab } from 'app/routes/dashboard/types';

export const generateDashboardPath = (tab: DashboardTab) =>
  generatePath(dashboardPath, {
    tab,
  });

export const checkDashboardTab = (tab: DashboardTab) => ({
  isAppointmentsSummary: tab === dashboardTabs.appointmentsSummary,
  isAppointmentsPerformers: tab === dashboardTabs.appointmentsPerformers,
  isUsersPerformance: tab === dashboardTabs.usersPerformance,
});
