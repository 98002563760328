import { BillingItemSourceType } from 'app/types/BillingItem';
import { InsuranceClassOption } from 'app/types/Insurance';
import { Option } from 'app/types/Option';
import { PatientInsuranceDetailsOption, PatientPaymentMethod } from 'app/types/Patient';
import { ReferralInsuranceApprovalOption } from 'app/types/Referrals';

export interface InvoiceBillingItemOption extends Option {
  clinicyId?: string;
  details: {
    gtinCode: string | null;
    internalCode: string | null;
    isReplaceableMedication?: boolean;
    measureValue: string | null;
    medicalBillingNphiesCode: string | null;
    price: number;
    quantity?: number;
    saudisVatExempted: boolean;
    unitMeasure: string | null;
    vatExempted: boolean;
  };
}

export enum InvoiceDiscountType {
  None = '0',
  Percentage = '1',
  Amount = '2',
}

export interface InvoiceItemDiscount {
  discountTypeView: Option<InvoiceDiscountType>;
  value: number;
}

export interface InvoiceItem {
  billingItem: InvoiceBillingItemOption;
  billingItemSourceType: Option<BillingItemSourceType>;
  discount: InvoiceItemDiscount;
  id: string;
  invoicePackageItemSnapshotId?: string;
  price: number;
  quantity: number;
  vat: number;
}

export enum InvoiceItemVat {
  Default = 15,
  None = 0,
}

export interface InvoiceItemTotal {
  discount: number;
  grandTotalValue: number;
  insuranceShare: number;
  patientShare: number;
  price: number;
  vatValue: number;
}

export interface InvoicePaymentMethodItem {
  amount: number;
  creditNotePayment: boolean;
  id: string;
  isDefault: boolean;
  notes: string | null;
  otherPaymentMethod?: string;
  paymentMethod: Option<PatientPaymentMethod>;
  timeOfCreation: string;
}

export interface InvoiceCreditNotePayment {
  amount: number;
  creditNoteId: string; // unique ID
  creditNoteNumber: string;
  paymentMethod: Option<PatientPaymentMethod>;
  timeOfCreation: string;
}

export type InvoicePaymentMethodTotal = Pick<InvoicePaymentMethodItem, 'amount'>;

export enum InvoicePayBy {
  Insurance = '0',
  Patient = '1',
}

export enum InvoiceStatus {
  NotSettled = '0',
  PartiallySettled = '1',
  Settled = '2',
  Canceled = '3',
  ClearedByCreditNote = '4',
}

export enum InvoiceType {
  AppointmentServiceItemBasedInvoice = '1',
  PatientPaymentInvoice = '2',
  DefaultInsuranceInvoice = '3',
  FirstInsuranceInvoice = '101',
  InsuranceApprovalInvoice = '102',
  ReferralInsuranceApprovalInvoice = '103',
  NewInsuranceInvoiceWithoutAppointment = 104,
  InsurancePreAuthorizedApprovalInvoice = 105,
}

export interface InvoicePaymentMethod {
  /* ISOString */
  dueDate: string;
  items: InvoicePaymentMethodItem[];
  creditNotePayments: InvoiceCreditNotePayment[];
}

export interface InvoicePaymentModeInsurance {
  approvalNumber: string;
  approvalThreshold: number;
  approvalType: Option;
  benefitLimit: number;
  company: PatientInsuranceDetailsOption;
  eligibilityNumber: string;
  eligibilityType: Option<InvoiceEligibility>;
  end: string;
  insuranceApproval: Option | null;
  insuranceClass: InsuranceClassOption;
  insuranceId: string;
  insurancePolicy: Option;
  maxConsultFee: number;
  maxPatientShare: number;
  membershipNumber: string;
  number: string;
  patientShare: number;
  referralInsuranceApproval: ReferralInsuranceApprovalOption;
  start: string;
}

export interface InvoicePaymentMode {
  insurance: InvoicePaymentModeInsurance | null;
  governmentIssuedId: string | null;
  governmentIssuedIdType: Option<BillingItemSourceType> | null;
  payBy: Option<InvoicePayBy>;
}

export enum InvoiceLogObjectType {
  Invoice = 0,
  CreditNote = 1,
}

export interface InvoiceLogSource {
  id: string | null;
  objectType: InvoiceLogObjectType;
  url: string | null;
}

export interface InvoiceLogItem {
  actionTaker: Option;
  actionType: Option;
  appointmentId: string | null;
  id: string;
  rootInvoiceId: string;
  source: InvoiceLogSource | null;
  /* ISOString */
  timeOfCreation: string;
  walletTransactionAmount: number | null;
  walletTransactionType: Option | null;
}

export enum InvoiceCreditNoteAction {
  Modify = '0',
  Refund = '1',
}

export enum InvoiceCreditNoteReason {
  RefusedService = '1',
  DoctorRequest = '2',
  WrongEntry = '3',
  Other = '4',
  ReturnPackageItem = '5',
  ExceededPreAuthLimit = '6',
}

export enum InvoiceCreditNotePackageAction {
  DoNothing = '0',
  IssueInvoiceWithCurrentPrices = '1',
  IssueInvoiceWithPricesOnPurchaseDate = '2',
  UsePackagePrices = '3',
}

export enum InvoicePaymentMethodType {
  Cash = '1',
  CreditCard = '2',
  FromAccountCredit = '3',
  Other = '4',
  Mada = '5',
  MasterCard = '6',
  Visa = '7',
  AmericanlExpress = '8',
  STCPay = '9',
  ApplePay = '10',
  Cheque = '11',
  Transfer = '13',
  Tabby = '14',
  Tamara = '15',
  Madfu = '16',
  GCCNET = '17',
  Discover = '18',
  Maestro = '19',
}

export interface InvoiceInsuranceReferralItem {
  billingItemSourceType: Option<BillingItemSourceType>;
  internalCode: string;
  isCoveredByInsuranceClass: boolean;
  medicalBillingNphiesCode: string;
  nameAr: string;
  nameEn: string;
  performerBillingItemId: string;
  price: number | null;
  quantity: number;
  saudisVatExempted: boolean;
  vatExempted: boolean;
}

export interface InvoiceInsuranceApprovalItem {
  approvalDate: string;
  approvalExpiryDate: string;
  billingItemSourceType: Option<BillingItemSourceType>;
  insuranceId: string;
  internalCode: string;
  nameAr: string;
  nameEn: string;
  performerBillingItemId: string;
  practitioner: Option;
  price: number;
  quantity: number;
  referenceNumber: string;
  saudisVatExempted: boolean;
  specialities: Option[];
  vatExempted: boolean;
  visitNumber: string;
}

export enum InvoiceEligibility {
  Eligible = '1',
  PreviousApproval = '2',
  ReferralIn = '3',
}
