import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers';
import { addAppointment } from 'app/redux/appointment/appointment.actions';
import { formValuesAdapter } from 'app/features/bookAppointment/bookAppointmentWizard/helpers';
import { BookAppointmentFormData } from 'app/features/bookAppointment/types';

export const useBookAppointmentHandler = () => {
  const dispatch = useAppDispatch();

  const selectedSlot = useAppSelector((state) => state.bookAppointment.selectedSlot);
  const referralInfo = useAppSelector((state) => state.bookAppointment.referralInfo);
  const isPhantom = !!selectedSlot?.isPhantom;

  return useCallback(
    async (formValues: BookAppointmentFormData) => {
      if (selectedSlot && (selectedSlot.start || selectedSlot.date)) {
        await dispatch(
          addAppointment(
            formValuesAdapter({
              formValues,
              selectedSlot,
              start: selectedSlot.start || selectedSlot.date, // no-time has only 'date'
              end: selectedSlot.end || null,
              referralId: referralInfo?.id || null,
            }),
            isPhantom,
          ),
        );
      }
    },
    [dispatch, isPhantom, selectedSlot, referralInfo?.id],
  );
};
