import { ComponentType, useCallback, useEffect, useMemo } from 'react';
import cancelEvent from 'assets/images/icons/cancelEvent.png';
import classnames from 'classnames';
import moment from 'moment';
import {
  CalendarProps,
  Components,
  DayPropGetter,
  EventPropGetter,
  Formats,
  momentLocalizer,
  Calendar as RBC,
  SlotInfo,
  SlotPropGetter,
  View,
} from 'react-big-calendar';
import withDragAndDrop, { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop';
import toast from 'react-hot-toast';
import { change } from 'redux-form';
import { formattedPeriod, useAppDispatch, useAppIntl, useAppSelector, useCurrentUser } from 'app/helpers';
import { Box, DatePicker, NoResults } from 'app/components';
import { FORMATS_DATE_AND_TIME, FORMATS_EXTENDED_DATE, FORMATS_TIME, Loader } from 'app/shared';
import { selectEvent } from 'app/redux/calendar/calendar.actions';
import { AppointmentStatus, CalendarType } from 'app/types';
import { useBookingWizard } from 'app/bookingWizardModal/helpers';
import { CALENDAR_STEP, CALENDAR_TIMESLOTS } from 'app/features/calendar/components/calendar/calendar.constants';
import { EventToolbar } from 'app/features/calendar/components/calendar/eventToolbar/eventToolbar';
import { Filters } from 'app/features/calendar/components/calendar/filters/filters';
import { FiltersFormData } from 'app/features/calendar/components/calendar/filters/types';
import {
  checkSlotAvailability,
  customDayLayoutAlgorithm,
  findResource,
  findWorkingTime,
} from 'app/features/calendar/components/calendar/helpers';
import { Legend } from 'app/features/calendar/components/calendar/legend/legend';
import { ResourceHeader } from 'app/features/calendar/components/calendar/resourceHeader/resourceHeader';
import { Toolbar } from 'app/features/calendar/components/calendar/toolbar/toolbar';
import { scrollToCurrentTime } from 'app/features/calendar/helpers';
import { CalendarDayEvent, CalendarDayResource } from 'app/features/calendar/types';
import 'assets/styles/reactBigCalendar.scss';
import styles from 'app/features/calendar/components/calendar/calendar.module.scss';

interface Props {
  backgroundEvents?: CalendarDayEvent[];
  date: Date | undefined;
  defaultDate: Date;
  defaultView: View;
  events: CalendarDayEvent[];
  form: string;
  initialFormValues?: Partial<FiltersFormData>;
  isEmpty: boolean;
  loading: boolean;
  max?: Date;
  min?: Date;
  onFormChange: (values: Partial<FiltersFormData>) => void;
  resources: CalendarDayResource[] | undefined;
  views?: View[];
}

const localizer = momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop<CalendarDayEvent, CalendarDayResource>(
  RBC as ComponentType<CalendarProps<CalendarDayEvent, CalendarDayResource>>,
);

export const Calendar = (props: Props) => {
  const dispatch = useAppDispatch();
  const {
    currentUser: { allowedForBookAppointments, allowedForEditAppointments },
  } = useCurrentUser();
  const { isRtl } = useAppIntl();
  const openBookingWizard = useBookingWizard();

  const calendarType = useAppSelector((state) => state.calendar.type);
  const isFullEditActive = useAppSelector((state) => state.appointment.fullEdit.isActive);
  const isRebook = useAppSelector((state) => !!state.appointment.rebook.data);
  const selectedEvent = useAppSelector((state) => state.calendar.selectedEvent);
  const isWaiting = calendarType === CalendarType.Waiting;
  const minDate = useMemo(() => moment().startOf('day').toDate(), []);
  const maxDate = useMemo(() => moment().add(1, 'year').startOf('day').toDate(), []);
  const selected = useMemo(() => props.events.find((e) => e.id === selectedEvent), [props.events, selectedEvent]);

  const draggableAccessor = useCallback(
    (event: CalendarDayEvent) =>
      isFullEditActive || isRebook || !allowedForBookAppointments ? false : !!event.isDraggable,
    [allowedForBookAppointments, isFullEditActive, isRebook],
  );

  const components = useMemo(
    (): Components<CalendarDayEvent, CalendarDayResource> => ({
      resourceHeader: ResourceHeader,
      toolbar: Toolbar,
    }),
    [],
  );

  const formats = useMemo(
    (): Formats => ({
      dayFormat: 'DD dddd',
      dayHeaderFormat: FORMATS_EXTENDED_DATE,
      eventTimeRangeEndFormat: (dateRange) => formattedPeriod(dateRange.start, dateRange.end, FORMATS_DATE_AND_TIME),
      eventTimeRangeFormat: (dateRange) => formattedPeriod(dateRange.start, dateRange.end),
      eventTimeRangeStartFormat: (dateRange) => formattedPeriod(dateRange.start, dateRange.end, FORMATS_DATE_AND_TIME),
      selectRangeFormat: (dateRange) => formattedPeriod(dateRange.start, dateRange.end),
      timeGutterFormat: FORMATS_TIME,
    }),
    [],
  );

  const onDateChange = useCallback(
    (date: Date | null) => {
      if (date) {
        dispatch(change(props.form, 'date', date));
      }
    },
    [dispatch, props.form],
  );

  const onSelectEvent = useCallback(
    (event: CalendarDayEvent) => {
      if (!isFullEditActive) {
        dispatch(selectEvent(event.id));
      }
    },
    [dispatch, isFullEditActive],
  );

  const onSelectSlot = useCallback(
    (slotInfo: SlotInfo) => {
      const resource = findResource(props.resources, slotInfo.resourceId);
      const isPast = moment().isAfter(slotInfo.start, 'day');
      const isAllDay = Math.abs(moment(slotInfo.start).diff(slotInfo.end, 'day')) === 1;

      if (resource && !isPast && !isAllDay) {
        // If it's not All Day, we check slot availability
        const isAvailable = checkSlotAvailability(props.resources, slotInfo.resourceId, slotInfo.start, slotInfo.end);

        if (isAvailable) {
          const workingTime = findWorkingTime(resource.resourceWorkingTimes, slotInfo.start, slotInfo.end);

          if (workingTime && resource.resourceBranchId) {
            let end = slotInfo.end;

            if (end.getHours() === 23 && end.getMinutes() === 59) {
              // Convert 23:59 to 00:00 (backend issue)
              end = moment(end).add(1, 'day').startOf('day').toDate();
            }

            // Add Normal Appointment
            openBookingWizard({
              branchId: resource.resourceBranchId,
              doctorId: resource.resourceId,
              end,
              start: slotInfo.start,
              isWaiting,
              workingTimeId: workingTime.id,
            });
          }
        }
      }
    },
    [isWaiting, openBookingWizard, props.resources],
  );

  const onSelecting = useCallback(
    ({ start, end, resourceId }: { end: Date; resourceId?: number | string; start: Date }): boolean => {
      return resourceId ? checkSlotAvailability(props.resources, resourceId, start, end) : true;
    },
    [props.resources],
  );

  const onEventDrop: withDragAndDropProps<CalendarDayEvent, CalendarDayResource>['onEventDrop'] = useCallback(
    (args) => {
      if (!allowedForEditAppointments) {
        // User does not have permission to edit action
        return;
      }

      const resourceId = args.resourceId as string; // target resourceId (missing in @types/react-big-calendar)
      const resource = findResource(props.resources, resourceId);

      const isSameResource = resourceId === args.event.resourceId;
      const isSameTime = moment(args.start).isSame(args.event.start) && moment(args.end).isSame(args.event.end);
      const isFromAllDayToAllDay = args.isAllDay && args.event.allDay;
      const isNotAllDay = !args.isAllDay && !args.event.allDay;

      if (isSameResource && ((isSameTime && isNotAllDay) || isFromAllDayToAllDay)) {
        // The same parameters have been selected
        return;
      }

      const isAvailable = checkSlotAvailability(props.resources, resourceId, args.start, args.end);

      if (resource && isAvailable) {
        const workingTime = findWorkingTime(resource.resourceWorkingTimes, args.start, args.end);

        if (workingTime && resource.resourceBranchId) {
          openBookingWizard({
            appointmentId: args.event.id,
            branchId: resource.resourceBranchId,
            doctorId: resource.resourceId,
            end: args.isAllDay ? undefined : args.end,
            start: args.start,
            isWaiting: args.isAllDay ? false : isWaiting,
            workingTimeId: workingTime.id,
          });

          return;
        }
      }

      toast.error('ERRORS.SELECTED-TIME-NOT-AVAILABLE');
    },
    [allowedForEditAppointments, isWaiting, openBookingWizard, props.resources],
  );

  const slotPropGetter: SlotPropGetter = useCallback(
    (date, resourceId) => {
      const isSlotAvailable = resourceId ? checkSlotAvailability(props.resources, resourceId, date) : true;
      return isSlotAvailable ? {} : { className: 'rbc__disabledSlot' };
    },
    [props.resources],
  );

  const dayPropGetter: DayPropGetter = useCallback((date) => {
    const isPast = moment().isAfter(date, 'day');
    return isPast ? { className: 'rbc__disabledSlot' } : {};
  }, []);

  const eventPropGetter: EventPropGetter<CalendarDayEvent> = useCallback((event) => {
    let className = '';
    const status = event.status?.value;
    const isConfirmed = status === AppointmentStatus.Confirmed || status === AppointmentStatus.ConfirmedByStaff;
    const isNoShow = status === AppointmentStatus.NoShow;
    const isShaking =
      !event.allDay &&
      !event.checkedIn &&
      !isNoShow &&
      !event.isWaiting &&
      moment().isBetween(moment(event.start).subtract(2, 'hours'), moment(event.end));

    switch (true) {
      case event.isConflicting:
        className = 'rbc-event--conflicting';
        break;
      case event.checkedIn:
        className = 'rbc-event--checkedIn';
        break;
      case isNoShow:
        className = 'rbc-event--noShow';
        break;
      case isConfirmed:
        className = 'rbc-event--confirmed';
        break;
      case event.isWaiting:
        className = 'rbc-event--waiting';
        break;
    }

    return { className: classnames(className, { [styles.shakingEvent]: isShaking || event.isConflicting }) };
  }, []);

  const onNavigate = useCallback(() => null, []);

  useEffect(() => {
    scrollToCurrentTime(props.defaultView === 'week');
  }, [props.defaultView]);

  return (
    <>
      <div className={classnames(styles.row, { [styles.calendarLoading]: props.loading })}>
        <div className={`${styles.col} ${styles.colFilters}`}>
          <div className="sticky-top sticky-top--withGutter">
            <DatePicker
              calendarClassName={styles.datePicker}
              inactive={props.loading}
              inline
              maxDate={maxDate}
              minDate={minDate}
              onChange={onDateChange}
              selected={props.date || props.defaultDate}
            />
          </div>
        </div>
        <div className={`${styles.col} ${styles.colCalendar}`}>
          <Box className="pb-2">
            <Filters form={props.form} initialValues={props.initialFormValues} onChange={props.onFormChange} />
          </Box>
          <Legend />
          <Box className={styles.box}>
            {props.isEmpty && props.loading && <Loader style={{ height: 180 }} />}
            {props.isEmpty && !props.loading && (
              <NoResults imageSrc={cancelEvent} messageId="CORE.TEXT.NO-RESULTS-FOR-FILTERS" />
            )}
            {!props.isEmpty && (
              <div
                className={classnames(styles.calendarWrapper, {
                  dayView: props.defaultView === 'day',
                  weekView: props.defaultView === 'week',
                })}
              >
                <DragAndDropCalendar
                  backgroundEvents={props.backgroundEvents}
                  components={components}
                  date={props.date}
                  // We can go back to "no-overlap" algorithm after merging https://github.com/jquense/react-big-calendar/pull/2239
                  dayLayoutAlgorithm={customDayLayoutAlgorithm}
                  dayPropGetter={dayPropGetter}
                  defaultDate={props.defaultDate}
                  defaultView={props.defaultView}
                  draggableAccessor={draggableAccessor}
                  eventPropGetter={eventPropGetter}
                  events={props.events}
                  formats={formats}
                  localizer={localizer}
                  max={props.max}
                  min={props.min}
                  onEventDrop={onEventDrop}
                  onNavigate={onNavigate}
                  onSelectEvent={onSelectEvent}
                  onSelectSlot={onSelectSlot}
                  onSelecting={onSelecting}
                  resizable={false}
                  resourceIdAccessor="resourceId"
                  resourceTitleAccessor="resourceTitle"
                  resources={props.resources}
                  rtl={isRtl}
                  selectable={allowedForBookAppointments}
                  selected={selected}
                  showAllEvents
                  showMultiDayTimes
                  slotPropGetter={slotPropGetter}
                  step={CALENDAR_STEP}
                  timeslots={CALENDAR_TIMESLOTS}
                  views={props.views}
                />
              </div>
            )}
          </Box>
        </div>
      </div>
      {!isRebook && <EventToolbar loading={props.loading} />}
    </>
  );
};
