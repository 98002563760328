import moment from 'moment';
import { SlotInfo } from 'react-big-calendar';
import { findResource } from 'app/features/calendar/components/calendar/helpers/findResource';
import { CalendarDayResource } from 'app/features/calendar/types';

/** Returns true if the slot is available */
export const checkSlotAvailability = (
  resources: CalendarDayResource[] | undefined,
  resourceId: SlotInfo['resourceId'],
  start: Date,
  end?: Date,
): boolean => {
  if (resources && !!resources.length) {
    const resource = findResource(resources, resourceId);
    const startMoment = moment(start);

    if (resource) {
      let isAvailable: boolean;
      let isBusyTime: boolean;

      if (end) {
        const endMoment = moment(end);
        isAvailable = resource.resourceWorkingTimes.some(
          (wt) =>
            startMoment.isSameOrAfter(new Date(wt.start), 'minutes') &&
            endMoment.isSameOrBefore(new Date(wt.end), 'minutes'),
        );
        isBusyTime = resource.resourceBusyTimes.some(
          (bt) =>
            startMoment.isBetween(new Date(bt.start), new Date(bt.end)) ||
            endMoment.isBetween(new Date(bt.start), new Date(bt.end)) ||
            moment(bt.start).isBetween(startMoment, endMoment) ||
            moment(bt.end).isBetween(startMoment, endMoment),
        );
      } else {
        isAvailable = resource.resourceWorkingTimes.some((wt) =>
          startMoment.isBetween(new Date(wt.start), new Date(wt.end), 'minutes', '[)'),
        );
        isBusyTime = resource.resourceBusyTimes.some((bt) =>
          startMoment.isBetween(new Date(bt.start), new Date(bt.end), 'minutes', '[)'),
        );
      }

      if (!isAvailable || isBusyTime) {
        // Slot is not available
        return false;
      }
    }
  }

  // Slot is available
  return true;
};
