import React, { useEffect, useMemo } from 'react';
import { PaginationOptions, RowEventHandlerProps } from 'react-bootstrap-table-next';
import { LocalBootstrapTable } from 'app/components/bootstrapTable/localBootstrapTable/localBootstrapTable';
import { useColumns } from 'app/shared/patientSearch/patientSearchResults/helpers/useColumns';
import { useGetRelatedPatientsForAppointmentQuery } from 'app/services/patients/patients';
import { ClinicPatientShortInfo } from 'app/services/patients/types';
import { useSelectionHandlers } from 'app/features/patient/changeAppointmentPatientModal/list/helpers/useSelectionHandlers';

interface Props {
  patientId: string;
  phoneNumber: string;
  selectionChanged: (data: ClinicPatientShortInfo | null) => void;
}

export const List = ({ patientId, phoneNumber, selectionChanged }: Props) => {
  const { data, isFetching, isLoading } = useGetRelatedPatientsForAppointmentQuery({ patientId, phoneNumber });
  //Actions
  const { resetSelection, checkIsRowSelected, handleSelectionChange } = useSelectionHandlers({
    data: data?.data,
    selectionChanged,
  });

  const paginationSettings: PaginationOptions = useMemo(
    () => ({
      totalSize: data?.data.length || 0,
      onSizePerPageChange: resetSelection,
      onPageChange: resetSelection,
    }),
    [data?.data, resetSelection],
  );

  const rowEvents: RowEventHandlerProps<ClinicPatientShortInfo> = useMemo(
    () => ({
      onClick: (_, row) => handleSelectionChange(row.id),
    }),
    [handleSelectionChange],
  );

  const columns = useColumns({
    checkIsSelected: checkIsRowSelected,
  });

  useEffect(() => {
    if (isFetching || isLoading) {
      resetSelection();
    }
  }, [isFetching, isLoading, resetSelection]);

  return (
    <LocalBootstrapTable
      classes="table-striped table-sm table-row-cursor-pointer"
      columns={columns}
      data={data?.data || []}
      id="relatedPatients"
      keyField="id"
      loading={isFetching || isLoading}
      pagination={paginationSettings}
      rowEvents={rowEvents}
    />
  );
};
