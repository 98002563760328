import { useMemo } from 'react';
import { BootstrapTableProps } from 'react-bootstrap-table-next';

interface Props {
  columns: BootstrapTableProps['columns'];
  pageNumber?: number;
  pageSize?: number;
  showRowId?: boolean;
}

export const useCustomColumns = ({ columns, pageNumber, pageSize, showRowId }: Props) => {
  return useMemo<BootstrapTableProps['columns']>(() => {
    const pageNumberBase = ((pageNumber || 1) - 1) * (pageSize || 1);

    return [
      {
        dataField: 'rowId',
        text: '#',
        hidden: !showRowId,
        formatter: (_, __, rowIndex, formatExtraData) => formatExtraData.pageNumberBase + rowIndex + 1,
        formatExtraData: { pageNumberBase },
      },
      ...columns,
    ];
  }, [columns, pageNumber, pageSize, showRowId]);
};
