import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Table } from 'reactstrap';
import { Field } from 'redux-form';
import { WrappedFieldArrayProps } from 'redux-form/lib/FieldArray';
import { formattedDate, useAppIntl } from 'app/helpers';
import { CheckboxComponent } from 'app/shared';
import { PreviousSurgery } from 'app/types';

export const SurgicalHistoryField = ({ fields }: WrappedFieldArrayProps<PreviousSurgery>) => {
  const items = fields.getAll() || [];
  const { formatMessage } = useAppIntl();

  return (
    <>
      <Table size="sm" responsive>
        <thead>
          <tr>
            <th>{formatMessage({ id: 'CORE.LABEL.SURGERIES' })}</th>
            <th>{formatMessage({ id: 'CORE.LABEL.DATE-OF-SUBMISSION' })}</th>
            <th>{formatMessage({ id: 'CORE.BUTTON.PRINT' })}</th>
          </tr>
        </thead>
        <tbody>
          {!items.length ? (
            <tr>
              <td colSpan={2} className="text-center">
                <FormattedMessage id="CORE.LABEL.NO-DATA" />
              </td>
            </tr>
          ) : (
            items.map((history, index) => (
              <tr key={`surgicalHistoryTable-${index}`}>
                <td>{history.surgery.label}</td>
                <td>{formattedDate(history.addedAt)}</td>
                <td>
                  <Field name={`surgicalHistoryTable.${index}.toPrint`} component={CheckboxComponent} />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </>
  );
};
