import { Note } from 'app/components/locationSelector/procedureLocation/types';
import {
  AttachmentAppointmentOption,
  AttachmentType,
  BillingItemSourceType,
  BillingItemType,
  ExtendedOrderPerformerBillingItemOption,
  Option,
  OrderLink,
  OrderPerformerBillingItemOption,
  OrderStatus,
  RemoteFile,
} from 'app/types';
import { AssessmentBillingItem } from 'app/features/doctorAssessment/components/billingSection/forms/types';
import {
  DrugDurationUnit,
  DrugScientificName,
} from 'app/features/doctorAssessment/components/prescriptionSection/types';

export enum AssessmentField {
  ChiefComplaint = '0',
  Diagnostics = '5',
}

export interface ICD10Code {
  icd10Code: string;
  description: string;
  isExcluded: boolean;
}

export interface DiagnoseResponse {
  date: string;
  text: string;
  formattedText: string;
  icd10Codes?: ICD10Code[];
}

export interface VisitPractitionerAssessment {
  chiefComplaint?: string;
  comment?: string;
  conditions?: string;
  diagnosis?: string;
  icdTable?: DiagnoseResponse[];
  plan?: string;
  signs?: string;
}

export interface HistoricalBillingSection {
  appointmentId: string;
  appointmentNumber: string;
  invoiceId?: string;
  invoiceNumber?: string;
  billNote?: string;
  billDate: Date;
  assessmentBillingItems: AssessmentBillingItem[];
}

export interface HistoricalOrdersSection {
  doctorAssessmentId: string;
  appointmentId: string;
  appointmentNumber: string;
  assessmentDate: string;
  assessmentOrders: HistoricalAssessmentOrderResponse[];
}

export interface NewPrescriptionHistoricalItem {
  doctorAssessmentId: string;
  appointmentId: string;
  appointmentNumber: string;
  assessmentDate: Date;
  assessmentPrescriptions: NewHistoricalPrescription[];
}

export interface NewHistoricalPrescription {
  prescriptionId: string;
  prescriptionDrugs: HistoricalPrescriptionDrug[];
  number: string;
}

export interface HistoricalPrescriptionDrug {
  id: string;
  drugScientificName: DrugScientificName;
  drugDose?: Option | string;
  drugDoseUnit?: Option | string;
  drugDurationValue: string;
  drugDurationUnit: Option<DrugDurationUnit>;
  drugFrequencyValue: string;
  drugFrequencyUnit: Option;
  drugRoute?: Option;
  prn: Option;
}

export enum BillingItemPaymentStatus {
  None = '0',
  PendingInvoice = '1',
  PartiallyInvoiceIssued = '2',
  PendingApproval = '3',
  InvoiceIssued = '4',
  ApprovedByInsurance = '5',
  RejectedByInsurance = '6',
}

export enum BillingInsuranceSupport {
  Covered = 'Covered',
  NotCovered = 'NotCovered',
}

export interface AttachmentResponseItem {
  additionalComment?: string;
  appointments: AttachmentAppointmentOption[];
  id: string;
  attachmentName: string;
  attachmentType: Option<AttachmentType>;
  clinicId: string;
  files: RemoteFile[];
  hasMergePatientsBadge: boolean;
  otherAttachmentType?: string;
  patientId: string;
}

export interface AssessmentDiagnose {
  diagnosesInformation: ICD10Code[];
  clinicalExaminationText?: string;
  chiefComplainText?: string;
  otherConditionText?: string;
}

export interface AssessmentPatientIllness {
  illnessTypes: AssessmentIllnessTypes;
  otherText?: string;
  how?: string;
  where?: string;
  when?: Date;
  lmpDate?: Date;
}

export type AssessmentIllnessTypes = { [key: string]: boolean };

export type HistoricalAssessmentOrderResponse = Omit<DoctorAssessmentOrderSection, 'attachments' | 'links'>;

export type DoctorAssessmentOrderSection = {
  invoicePackageSnapshotId?: string;
  id: string;
  number: string | null;
  timeOfCreation: string;
  orderedBy: Option;
  billingItemType: Option<BillingItemType>;
  orderItem: ExtendedOrderPerformerBillingItemOption;
  details: string | null;
  status: Option<OrderStatus>;
  dueDate: string | null;
  links?: OrderLink[];
  locations?: Note[];
  attachments?: RemoteFile[];
  locationsCount: number;
  isFromDoctorAssessment: boolean;
};

export type AssessmentOrder = {
  localId: string;
  quantity: number;
  billingItemType: Option<BillingItemType>;
  orderItem: OrderPerformerBillingItemOption;
  locations?: Note[];
  details: string | null;
  dueDate?: string;
  status: Option<OrderStatus>;
  links?: OrderLink[];
  attachments?: RemoteFile[];
  performerBillingItemId: string;
  invoicePackageSnapshotId?: string;
  invoicePackageItemSnapshotId?: string;
  isFromDoctorAssessment: boolean;

  // [?]: SendToOrder, SaveForLater or Completed
  id?: string;
  timeOfCreation?: string;
  orderedBy?: Option;
  number?: string;
};

export interface AssessmentPackageItem {
  billingItemSourceType: Option<BillingItemSourceType>;
  billingItemType?: Option<BillingItemType>;
  billingPrice: number;
  clinicyId?: string;
  initialQuantity: number;
  pendingQuantity: number;
  remainingQuantity: number;
  id: string;
  performerBillingItemNameAr: string;
  performerBillingItemNameEn: string;
  internalCode?: string;
  medicalBilling?: string;
  performerBillingItemId: string;
  unitMeasure: Option;
  unitMeasureValue: string;
  isExcluded: boolean;
  preAuthorization: boolean;
}

export interface ExtendedAssessmentPackageItem extends AssessmentPackageItem {
  isChecked?: boolean;
  quantity?: number;
}
