import React, { ReactElement } from 'react';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import moment from 'moment';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { IconButton, Select } from 'app/components';
import { Option } from 'app/types';

type Options = {
  months?: Option[];
  showDisabledMonthNavigation?: boolean;
  years: Option[];
};

export const renderCustomHeader = (params: ReactDatePickerCustomHeaderProps, options: Options): ReactElement => {
  const hasMonths = !!options.months;
  const selectedYear = options.years[options.years.findIndex((y) => y.value === moment(params.date).format('YYYY'))];
  const selectedMonth =
    options.months && options.months[options.months.findIndex((m) => m.value === moment(params.date).format('MMMM'))];
  const maxMenuHeight = hasMonths ? 218 : 170;
  const arrowSize = 19;
  const arrowStrokeWidth = 1.8;

  return (
    <div className="d-flex justify-content-between align-items-center text-left px-2">
      <IconButton
        className="datePicker__arrowButton me-2"
        disabled={options.showDisabledMonthNavigation ? false : params.prevMonthButtonDisabled}
        icon={<IconChevronLeft size={arrowSize} strokeWidth={arrowStrokeWidth} />}
        onClick={hasMonths ? params.decreaseMonth : params.decreaseYear}
      />
      {hasMonths && (
        <div className="me-2" style={{ width: 122 }}>
          <Select
            maxMenuHeight={maxMenuHeight}
            name="month"
            onChange={(option) =>
              options.months && params.changeMonth(options.months.findIndex((m) => m.value === option?.value))
            }
            options={options.months}
            size="sm"
            value={selectedMonth}
          />
        </div>
      )}
      <div className="me-2" style={{ width: 85 }}>
        <Select
          maxMenuHeight={maxMenuHeight}
          name="year"
          onChange={(option) => params.changeYear(Number(option?.value))}
          options={options.years}
          size="sm"
          value={selectedYear}
        />
      </div>
      <IconButton
        className="datePicker__arrowButton"
        disabled={options.showDisabledMonthNavigation ? false : params.nextMonthButtonDisabled}
        icon={<IconChevronRight size={arrowSize} strokeWidth={arrowStrokeWidth} />}
        onClick={hasMonths ? params.increaseMonth : params.increaseYear}
      />
    </div>
  );
};
