import classnames from 'classnames';
import { Col, Row } from 'reactstrap';
import styles from 'app/features/doctorAssessment/components/medicalProfileSection/components/helpers/printVisitPractitionerAssessment/printVisitPractitionerAssessment.module.scss';

interface Props {
  label: string;
  value: string;
  className?: string;
}

export const CustomDescriptionList = ({ label, value, className }: Props) => (
  <Row>
    <Col className={classnames('horizontal', className)}>
      <p className={styles.sectionTitle}>{label}:</p>
      <p className={styles.sectionSubtitle} dangerouslySetInnerHTML={{ __html: value }} />
    </Col>
  </Row>
);
