import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { intlReducer } from 'react-intl-redux';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { accountBalancesApi } from 'app/services/accountBalances/accountBalances';
import { appointmentsApi } from 'app/services/appointments/appointments';
import { branchesApi } from 'app/services/branches/branches';
import { clinicsApi } from 'app/services/clinics/clinics';
import { contractsAndPoliciesExclusionsApi } from 'app/services/contractsAndPoliciesExclusions/contractsAndPoliciesExclusions';
import { doctorsApi } from 'app/services/doctors/doctors';
import { generalApi } from 'app/services/general/general';
import { generalNotificationsApi } from 'app/services/generalNotifications/generalNotifications';
import { iconLibraryApi } from 'app/services/iconLibrary/iconLibrary';
import { insuranceApprovalsApi } from 'app/services/insuranceApprovals/insuranceApprovals';
import { insuranceClassBillingItemsApi } from 'app/services/insuranceClassBillingItems/insuranceClassBillingItems';
import { insuranceCompaniesApi } from 'app/services/insuranceCompanies/insuranceCompanies';
import { insuranceContractorApi } from 'app/services/insuranceContractors/insuranceContractors';
import { insuranceContractApi } from 'app/services/insuranceContracts/insuranceContracts';
import { insurancePoliciesApi } from 'app/services/insurancePolicies/insurancePolicies';
import { claimsApi } from 'app/services/insurances/claims';
import { invoicesApi } from 'app/services/invoices/invoices';
import { massAddingApi } from 'app/services/massAdding/massAdding';
import { medicalLibrariesApi } from 'app/services/medicalLibraries/medicalLibraries';
import { orderApi } from 'app/services/order/order';
import { ordersApi } from 'app/services/orders/orders';
import { patientPaymentsApi } from 'app/services/patientPayments/patientPayments';
import { patientsApi } from 'app/services/patients/patients';
import { patientsArrivalApi } from 'app/services/patientsArrival/patientsArrival';
import { patientSourcesApi } from 'app/services/patientSources/patientSources';
import { referralsApi } from 'app/services/referrals/referrals';
import { specialitiesApi } from 'app/services/specialities/specialities';
import { specialityApi } from 'app/services/speciality/speciality';
import { usersApi } from 'app/services/users/users';
import { accountBalanceReducer } from 'app/redux/account-balance/account-balance.reducer';
import { appReducer } from 'app/redux/app/app.reducer';
import { appointmentReducer } from 'app/redux/appointment/appointment.reducer';
import { appointmentsReducer } from 'app/redux/appointments/appointments.reducer';
import { authReducer } from 'app/redux/auth/auth.reducer';
import { billingItemsListsReducer } from 'app/redux/billingItemsLists/billingItemsLists.reducer';
import { billingPackagesReducer } from 'app/redux/billingPackages/billingPackages.reducer';
import { bookAppointmentReducer } from 'app/redux/book-appointment/book-appointment.reducer';
import { bookingWizardReducer } from 'app/redux/bookingWizard/bookWizard.reducer';
import { branchReducer } from 'app/redux/branch/branch.reducer';
import { branchBillingItemReducer } from 'app/redux/branchBillingItem/branchBillingItem.reducer';
import { branchesReducer } from 'app/redux/branches/branches.reducer';
import { calendarReducer } from 'app/redux/calendar/calendar.reducer';
import { clinicReducer } from 'app/redux/clinic/clinic.reducer';
import { clinicsPendingReducer } from 'app/redux/clinics-pending/clinicsPending.reducer';
import { clinicsRejectedReducer } from 'app/redux/clinics-rejected/clinicsRejected.reducer';
import { clinicsReducer } from 'app/redux/clinics/clinics.reducer';
import { conflictsReducer } from 'app/redux/conflicts/conflicts.reducer';
import { dashboard } from 'app/redux/dashboard/dashboard.reducer';
import { dependentsReducer } from 'app/redux/dependents/dependents.reducer';
import { dictionariesReducer } from 'app/redux/dictionaries/dictionaries.reducer';
import { doctorReducer } from 'app/redux/doctor/doctor.reducer';
import { doctorAssessmentReducer } from 'app/redux/doctorAssessment/doctorAssessment.reducer';
import { doctorsDeletedReducer } from 'app/redux/doctors-deleted/doctors-deleted.reducer';
import { downloadsReducer } from 'app/redux/downloads/downloads.reducer';
import iconLibrary from 'app/redux/iconLibrary/iconLibrary';
import { illnessTypesReducer } from 'app/redux/illnessTypes/illnessTypes.reducer';
import { initialAssessmentWizardReducer } from 'app/redux/initialAssessmentWizard/initialAssessmentWizard.reducer';
import insuranceClaims from 'app/redux/insuranceClaims/insuranceClaims';
import insuranceContractors from 'app/redux/insuranceContractor/insuranceContractor';
import { invoiceReducer } from 'app/redux/invoice/invoice.reducer';
import { invoicesReducer } from 'app/redux/invoices/invoices.reducer';
import { invoicingProcessReducer } from 'app/redux/invoicingProcess/invoicingProcess.reducer';
import { massAddingContractsReducer } from 'app/redux/mass-adding-contracts/massAddingContracts.reducer';
import { massAddingReducer } from 'app/redux/mass-adding/massAdding.reducer';
import { medicationsReducer } from 'app/redux/medications/medications.reducer';
import { modalsReducer } from 'app/redux/modals/modals.reducer';
import { notificationsReducer } from 'app/redux/notifications/notifications.reducer';
import order from 'app/redux/order/order';
import { patientReducer } from 'app/redux/patient/patient.reducer';
import patientSlice from 'app/redux/patient/patientSlice';
import { patientAllergyReducer } from 'app/redux/patientAllergy/patientAllergy.reducer';
import patientPaymentsSlice from 'app/redux/patientPayments/patientPaymentsSlice';
import { patientsReducer } from 'app/redux/patients/patients.reducer';
import { billingPractitionerPackagesReducer } from 'app/redux/practitionerBillingPackages/practitionerBillingPackages.reducer';
import { prescriptionReducer } from 'app/redux/prescription/prescription.reducer';
import queryParams from 'app/redux/queryParams/queryParams';
import { referralsReducer } from 'app/redux/referrals/referrals.reducer';
import { remoteDataReducer } from 'app/redux/remoteData/remoteData.reducer';
import { requestsReducer } from 'app/redux/requests/requests.reducer';
import { settingsReducer } from 'app/redux/settings/settings.reducer';
import { sidebarReducer } from 'app/redux/sidebar/sidebar.reducer';
import { takeActionReducer } from 'app/redux/takeAction/takeAction.reducer';
import { treatmentReducer } from 'app/redux/treatment/treatment.reducer';
import { userRolesReducer } from 'app/redux/user-roles/user-roles.reducer';
import { walletReducer } from 'app/redux/wallet/wallet.reducer';
import { walletsReducer } from 'app/redux/wallets/wallets.reducer';

export const createRootReducer = (history: History) =>
  combineReducers({
    // Main reducers (Do not change positions)
    intl: intlReducer,
    router: connectRouter(history),
    form: formReducer,

    // API
    [accountBalancesApi.reducerPath]: accountBalancesApi.reducer,
    [appointmentsApi.reducerPath]: appointmentsApi.reducer,
    [branchesApi.reducerPath]: branchesApi.reducer,
    [clinicsApi.reducerPath]: clinicsApi.reducer,
    [contractsAndPoliciesExclusionsApi.reducerPath]: contractsAndPoliciesExclusionsApi.reducer,
    [doctorsApi.reducerPath]: doctorsApi.reducer,
    [generalNotificationsApi.reducerPath]: generalNotificationsApi.reducer,
    [iconLibraryApi.reducerPath]: iconLibraryApi.reducer,
    [insuranceApprovalsApi.reducerPath]: insuranceApprovalsApi.reducer,
    [insuranceClassBillingItemsApi.reducerPath]: insuranceClassBillingItemsApi.reducer,
    [insuranceCompaniesApi.reducerPath]: insuranceCompaniesApi.reducer,
    [insuranceContractApi.reducerPath]: insuranceContractApi.reducer,
    [insuranceContractorApi.reducerPath]: insuranceContractorApi.reducer,
    [insurancePoliciesApi.reducerPath]: insurancePoliciesApi.reducer,
    [invoicesApi.reducerPath]: invoicesApi.reducer,
    [massAddingApi.reducerPath]: massAddingApi.reducer,
    [medicalLibrariesApi.reducerPath]: medicalLibrariesApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [patientSourcesApi.reducerPath]: patientSourcesApi.reducer,
    [patientsApi.reducerPath]: patientsApi.reducer,
    [patientPaymentsApi.reducerPath]: patientPaymentsApi.reducer,
    [patientsArrivalApi.reducerPath]: patientsArrivalApi.reducer,
    [referralsApi.reducerPath]: referralsApi.reducer,
    [specialityApi.reducerPath]: specialityApi.reducer,
    [specialitiesApi.reducerPath]: specialitiesApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [generalApi.reducerPath]: generalApi.reducer,
    [claimsApi.reducerPath]: claimsApi.reducer,

    // Custom reducers
    accountBalance: accountBalanceReducer,
    app: appReducer,
    appointment: appointmentReducer,
    appointments: appointmentsReducer,
    auth: authReducer,
    billingItemsLists: billingItemsListsReducer,
    billingPackages: billingPackagesReducer,
    billingPractitionerPackages: billingPractitionerPackagesReducer,
    bookAppointment: bookAppointmentReducer,
    bookingWizard: bookingWizardReducer,
    branch: branchReducer,
    branchBillingItem: branchBillingItemReducer,
    branches: branchesReducer,
    calendar: calendarReducer,
    clinic: clinicReducer,
    clinics: clinicsReducer,
    clinicsPending: clinicsPendingReducer,
    clinicsRejected: clinicsRejectedReducer,
    conflicts: conflictsReducer,
    dashboard: dashboard,
    dependents: dependentsReducer,
    dictionaries: dictionariesReducer,
    doctor: doctorReducer,
    doctorAssessment: doctorAssessmentReducer,
    doctorsDeleted: doctorsDeletedReducer,
    downloads: downloadsReducer,
    iconLibrary,
    illnessTypes: illnessTypesReducer,
    initialAssessmentWizard: initialAssessmentWizardReducer,
    invoice: invoiceReducer,
    invoices: invoicesReducer,
    invoicingProcess: invoicingProcessReducer,
    massAddingContracts: massAddingContractsReducer,
    massAdding: massAddingReducer,
    medications: medicationsReducer,
    modal: modalsReducer,
    notifications: notificationsReducer,
    order,
    insuranceContractors,
    // TODO: Rewrite Patient into patientSlice
    insuranceClaims,
    patient: patientReducer,
    patientSlice,
    patientPayments: patientPaymentsSlice,
    patientAllergy: patientAllergyReducer,
    patients: patientsReducer,
    prescription: prescriptionReducer,
    queryParams,
    referrals: referralsReducer,
    remoteData: remoteDataReducer,
    requests: requestsReducer,
    settings: settingsReducer,
    sidebar: sidebarReducer,
    takeAction: takeActionReducer,
    treatment: treatmentReducer,
    userRoles: userRolesReducer,
    wallet: walletReducer,
    wallets: walletsReducer,
  });
