import React, { useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useAppDispatch, useAppSelector, useCurrentUser, useModalCleaner } from 'app/helpers';
import { resetAppointment, resetPatient } from 'app/redux/appointment/appointment.actions';
import { selectSlot } from 'app/redux/calendar/calendar.actions';
import { SelectedSlot } from 'app/redux/calendar/types';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { AppointmentTicketModalContainer } from 'app/modals/appointment-ticket/appointment-ticket-modal.container';
import { DoctorSection } from 'app/features/calendar/components';
import { BookedAppointments } from 'app/features/calendar/modals/manageAppointments/bookedAppointments/bookedAppointments';
import { Header } from 'app/features/calendar/modals/manageAppointments/header/header';

interface Props {
  onAddPhantom?: (slot: SelectedSlot) => void;
}

export const ManageAppointmentsModal = ({ onAddPhantom }: Props) => {
  const dispatch = useAppDispatch();
  const modalRef = useRef(null);
  const { formatMessage } = useIntl();
  const {
    currentUser: { allowedForBookAppointments },
  } = useCurrentUser();

  // Selectors
  const isOpen = useAppSelector((state) => state.modal.manageAppointments.isOpen);
  const isBookAppointmentOpen = useAppSelector((state) => state.modal.bookAppointment.isOpen);
  const selectedSlot = useAppSelector((state) => state.calendar.selectedSlot);
  const { data: bookedAppointmentsData, loading: bookedAppointmentsLoading } = useAppSelector(
    (state) => state.remoteData.bookedAppointments,
  );

  // Variables
  const hasBookedAppointments = bookedAppointmentsData.length > 0;
  const isAddPhantomDisabled = !allowedForBookAppointments || !hasBookedAppointments || bookedAppointmentsLoading;

  // Actions
  const toggle = useCallback(() => dispatch(toggleModal('manageAppointments')), [dispatch]);
  const addPhantom = useCallback(() => {
    if (selectedSlot) {
      dispatch(resetPatient());
      dispatch(resetAppointment());
      if (isBookAppointmentOpen) {
        onAddPhantom?.({ ...selectedSlot, isPhantom: true });
        toggle();
      } else {
        dispatch(selectSlot({ ...selectedSlot, isPhantom: true }));
        dispatch(toggleModal('addAppointment'));
      }
    }
  }, [dispatch, isBookAppointmentOpen, onAddPhantom, selectedSlot, toggle]);
  const onClosed = useCallback(() => dispatch(selectSlot(null)), [dispatch]);

  // Effects
  useModalCleaner('manageAppointments', modalRef);

  return (
    <Modal ref={modalRef} isOpen={isOpen} onClosed={onClosed} size="xl" toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <Header />
      </ModalHeader>
      <ModalBody>
        {selectedSlot && <DoctorSection doctor={selectedSlot.doctor} />}
        <BookedAppointments />
        {/* @ts-ignore */}
        <AppointmentTicketModalContainer />
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          disabled={isAddPhantomDisabled}
          onClick={addPhantom}
          data-cy="addPhantomAppointmentButton"
        >
          {formatMessage({ id: 'APPOINTMENTS.BUTTON.ADD-PHANTOM-APPOINTMENT' })}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
