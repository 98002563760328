import moment, { Moment } from 'moment';
import {
  FORMATS_API_DATE,
  FORMATS_API_DATE_AND_TIME,
  FORMATS_API_TIME,
  FORMATS_DATE,
  FORMATS_DATE_AND_TIME,
  FORMATS_TIME,
} from 'app/shared';

// Moment
export const getDateAndTimeMoment = (date: Date | string, time: string): Moment =>
  moment(`${getApiDate(date)} ${time}`, `${FORMATS_API_DATE} ${FORMATS_API_TIME}`);

// Formatters
export const formattedDate = (date: Date | string, format: string = FORMATS_DATE): string =>
  moment(date).format(format);
export const formattedTime = (time: string): string => moment(time, FORMATS_API_TIME).format(FORMATS_TIME);
export const formattedDateAndTime = (
  date: Date | string,
  time?: string,
  format: string = FORMATS_DATE_AND_TIME,
): string => (time ? getDateAndTimeMoment(date, time).format(format) : moment(date).format(format));
export const formattedPeriod = (start: Date | string, end: Date | string, format: string = FORMATS_TIME) =>
  `${moment(start).format(format)} - ${moment(end).format(format)}`;
export const formattedTimePeriod = (start: string, end: string) => `${formattedTime(start)} - ${formattedTime(end)}`;
export const formatDateToTime = (date: Date | string) => moment(date).format(FORMATS_TIME);

// API
export const getApiDate = (date: Date | string): string => moment(date).format(FORMATS_API_DATE);
export const getApiTime = (date: Date | string): string => moment(date).format(FORMATS_API_TIME);
export const getApiDateAndTime = (date: Date | string, time?: string): string =>
  time
    ? getDateAndTimeMoment(date, time).format(FORMATS_API_DATE_AND_TIME)
    : moment(date).format(FORMATS_API_DATE_AND_TIME);
