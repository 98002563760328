export const patientPaymentsTab = {
  settleInvoices: 'settle-invoices',
  addAccountCredit: 'add-account-credit',
  withdrawal: 'withdrawal',
} as const;

export const patientPaymentsCore = '/patient-payments';
export const patientPaymentsPath = `${patientPaymentsCore}/:patientId/:tab(${Object.values(patientPaymentsTab).join(
  '|',
)})`;
