import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { getLastPage } from 'app/helpers/getLastPage/getLastPage';
import { IconButton } from 'app/components';
import { RemoteDataParams } from 'app/types';
import styles from 'app/features/calendar/components/pagination/pagination.module.scss';

interface Props {
  loading: boolean;
  onChange: (newParams: Partial<RemoteDataParams>) => void;
  params: RemoteDataParams | undefined;
}

export const Pagination = ({ loading, onChange, params }: Props) => {
  if (!params) {
    return null;
  }

  const disabled = loading;
  const isFirstPage = params.page === 1;
  const lastPage = getLastPage(params);
  const isLastPage = params.page === lastPage;

  if (isLastPage && params.page === 1) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.pagination}>
        <IconButton
          className={styles.button}
          disabled={disabled || isFirstPage}
          icon={<IconChevronLeft size={16} />}
          onClick={() => onChange({ page: params.page - 1 })}
        />
        <div className={styles.page}>
          {params.page}/{lastPage}
        </div>
        <IconButton
          className={styles.button}
          disabled={disabled || isLastPage}
          icon={<IconChevronRight size={16} />}
          onClick={() => onChange({ page: params.page + 1 })}
        />
      </div>
    </div>
  );
};
