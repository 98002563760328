import { v4 as uuid } from 'uuid';
import { PatientPayment, PaymentAllocation, SettleInvoicesPayment } from 'app/redux/patientPayments/types';
import { PatientInvoicesToPay } from 'app/types';

export const createPaymentsFromAllocations = (
  allocations: PaymentAllocation[],
  invoices: PatientInvoicesToPay[],
  invoiceValues: Record<string, number>,
  generalPaymentDetails: PatientPayment,
): SettleInvoicesPayment[] => {
  return allocations.map(({ invoiceId, amount }) => {
    invoiceValues[invoiceId] -= amount;
    const invoice = invoices.find((item) => item.id === invoiceId)!;

    return {
      paymentId: uuid(),
      ...invoice,
      generalPaymentDetails,
      paymentAmount: amount,
      remaining: invoiceValues[invoiceId],
    };
  });
};
