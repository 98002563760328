import { AnyAction } from 'redux';
import * as types from 'app/redux/prescription/prescription.types';
import { PrescriptionPrintView } from 'app/types';
import { PrescriptionFormData } from 'app/features/doctorAssessment/components/prescriptionSection/types';

interface PrescriptionReducer {
  data: PrescriptionFormData[] | undefined;
  rowIndex: number | null;
  printView: PrescriptionPrintView | null;
}

const initialState: PrescriptionReducer = {
  data: undefined,
  rowIndex: null,
  printView: null,
};

export const prescriptionReducer = (state = initialState, action: AnyAction): PrescriptionReducer => {
  switch (action.type) {
    case types.SET_PRESCRIPTION:
      return {
        ...state,
        data: action.payload.prescription,
        rowIndex: action.payload.rowIndex,
      };
    case types.RESET_PRESCRIPTION:
      return {
        ...state,
        data: initialState.data,
        rowIndex: initialState.rowIndex,
      };
    case types.FETCH_PRESCRIPTION_INFO_SUCCESS:
      return {
        ...state,
        printView: action.payload.data,
      };
    default:
      return state;
  }
};
