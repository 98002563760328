import { AnyAction } from 'redux';
import { InsuranceApprovalRequest, RemoteData } from 'app/types';
import { Details, InsuranceData } from 'app/features/insuranceApprovals/modals/takeAction/modalBody/details/types';
import {
  BillingData,
  TakeActionForm,
} from 'app/features/insuranceApprovals/modals/takeAction/modalBody/takeActionForm/types';
import * as types from './takeAction.types';

export interface TakeActionReducer {
  form: RemoteData<TakeActionForm | null>;
  details: RemoteData<Details | null>;
  items: RemoteData<BillingData[]>;
  info: InsuranceApprovalRequest | null;
  data: RemoteData<InsuranceData | null>;
}

export const initialState: TakeActionReducer = {
  form: {
    data: null,
    loading: false,
  },
  details: {
    data: null,
    loading: false,
  },
  items: {
    data: [],
    loading: false,
  },
  info: null,
  data: {
    data: null,
    loading: false,
  },
};

export const takeActionReducer = (state = initialState, action: AnyAction): TakeActionReducer => {
  switch (action.type) {
    case types.FETCH_DETAILS_REQUEST:
      return {
        ...state,
        details: {
          data: initialState.details.data,
          loading: true,
        },
      };
    case types.FETCH_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_DETAILS_FAILURE:
      return {
        ...state,
        details: {
          data: initialState.details.data,
          loading: false,
          error: true,
        },
      };
    case types.SET_FORM:
      return {
        ...state,
        form: {
          data: { ...action.meta },
          loading: false,
        },
      };
    case types.SET_DETAILS:
      return {
        ...state,
        details: {
          data: { ...action.meta },
          loading: false,
        },
      };
    case types.SET_INFO:
      return {
        ...state,
        info: { ...action.payload },
      };
    case types.FETCH_INSURANCE_APPROVAL_REQUEST:
      return {
        ...state,
        form: {
          data: initialState.form.data,
          loading: true,
        },
      };
    case types.FETCH_INSURANCE_APPROVAL_SUCCESS:
      return {
        ...state,
        form: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_INSURANCE_APPROVAL_FAILURE:
      return {
        ...state,
        form: {
          data: initialState.form.data,
          loading: false,
          error: true,
        },
      };
    case types.FETCH_BILLING_ITEMS_REQUEST:
      return {
        ...state,
        items: {
          data: initialState.items.data,
          loading: true,
        },
      };
    case types.FETCH_BILLING_ITEMS_SUCCESS:
      return {
        ...state,
        items: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_BILLING_ITEMS_FAILURE:
      return {
        ...state,
        items: {
          data: initialState.items.data,
          loading: false,
          error: true,
        },
      };
    case types.FETCH_DATA_REQUEST:
      return {
        ...state,
        data: {
          data: initialState.data.data,
          loading: true,
        },
      };
    case types.FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_DATA_FAILURE:
      return {
        ...state,
        data: {
          data: initialState.data.data,
          loading: false,
          error: true,
        },
      };
    //Default
    default:
      return state;
  }
};
