import { createApi } from '@reduxjs/toolkit/query/react';
import type { PatientInvoicesToPay, QueryParams, QueryResponseWithPagination } from 'app/types';
import { baseQueryWithReAuth } from 'app/helpers';
import { PatientPayment, PatientSettleInvoicesPayment } from 'app/redux/patientPayments/types';

export const patientPaymentsApi = createApi({
  reducerPath: 'patientPaymentsApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['PatientInvoicesToPay', 'PatientAccountBalance'],
  endpoints: (builder) => ({
    getPatientInvoicesToPay: builder.query<
      QueryResponseWithPagination<PatientInvoicesToPay[]>,
      { patientId: string; params?: QueryParams }
    >({
      query: ({ patientId, params }) => ({
        url: `invoices/to-pay/patients/${patientId}`,
        params,
      }),
      providesTags: ['PatientInvoicesToPay'],
    }),

    addPatientPayments: builder.mutation<void, { body: PatientSettleInvoicesPayment[] }>({
      query: ({ body }) => ({
        url: 'invoices/patients/payments',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PatientInvoicesToPay', 'PatientAccountBalance'],
    }),

    addPatientAccountCredits: builder.mutation<
      void,
      { patientId: string; body: PatientPayment[]; branchDeclarationId?: string }
    >({
      query: ({ patientId, body, branchDeclarationId }) => ({
        url: `wallets/clinic-patients/${patientId}/account-credits`,
        method: 'POST',
        body,
        params: {
          branchDeclarationId,
        },
      }),
      invalidatesTags: ['PatientAccountBalance'],
    }),

    withdrawPatientAccountCredit: builder.mutation<void, { patientId: string; body: PatientPayment[] }>({
      query: ({ patientId, body }) => ({
        url: `wallets/clinic-patients/${patientId}/withdraw-credits`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PatientAccountBalance'],
    }),
  }),
});

export const {
  useGetPatientInvoicesToPayQuery,
  useAddPatientPaymentsMutation,
  useAddPatientAccountCreditsMutation,
  useWithdrawPatientAccountCreditMutation,
} = patientPaymentsApi;
