import { useCallback, useRef } from 'react';
import { Modal } from 'reactstrap';
import { useAppDispatch, useAppSelector, useModalCleaner } from 'app/helpers';
import { reset } from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { InvoicingProcessMode } from 'app/redux/invoicingProcess/types';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { Invoices } from 'app/features/invoicingProcess/invoices/invoices';
import { CreditNoteModal } from 'app/features/invoicingProcess/modals/creditNoteModal/creditNoteModal';
import { CreditNotePackageActionsModal } from 'app/features/invoicingProcess/modals/creditNotePackageActionsModal/creditNotePackageActionsModal';
import { InsuranceDetailsModal } from 'app/features/invoicingProcess/modals/insuranceDetailsModal/insuranceDetailsModal';
import { InvoiceHistoryModal } from 'app/features/invoicingProcess/modals/invoiceHistoryModal/invoiceHistoryModal';
import { Process } from 'app/features/invoicingProcess/process/process';

export const invoicingProcessModalId = 'invoicingProcessModal';

export const InvoicingProcessModal = () => {
  const dispatch = useAppDispatch();
  const modalRef = useRef(null);
  const mode = useAppSelector((state) => state.invoicingProcess.mode);
  const isOpen = useAppSelector((state) => state.modal.invoicingProcess.isOpen);
  const isInvoicesMode = mode === InvoicingProcessMode.Invoices;

  const closeInvoicingProcess = useCallback(() => {
    dispatch(toggleModal('invoicingProcess', false));
  }, [dispatch]);

  const resetInvoicingProcess = useCallback(() => {
    dispatch(reset());
  }, [dispatch]);

  useModalCleaner('invoicingProcess', modalRef);

  if (mode === null) {
    return null;
  }

  return (
    <>
      <Modal
        backdrop="static"
        centered
        id={invoicingProcessModalId}
        isOpen={isOpen}
        keyboard={false}
        onClosed={resetInvoicingProcess}
        onExit={resetInvoicingProcess}
        ref={modalRef}
        size={isInvoicesMode ? 'lg' : 'xxl'}
        toggle={closeInvoicingProcess}
      >
        {isInvoicesMode && <Invoices closeInvoicingProcess={closeInvoicingProcess} />}
        {!isInvoicesMode && <Process closeInvoicingProcess={closeInvoicingProcess} />}
      </Modal>
      <div>
        <CreditNoteModal />
        <CreditNotePackageActionsModal />
        <InvoiceHistoryModal />
        <InsuranceDetailsModal />
      </div>
    </>
  );
};
