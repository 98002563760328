import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { change } from 'redux-form';
import { useAppDispatch, useAppIntl, useAppSelector, useFilterFormActions, useModalCleaner } from 'app/helpers';
import { RemoteBootstrapTable } from 'app/components/bootstrapTable/remoteBootstrapTable/remoteBootstrapTable';
import {
  FORMS_ADD_GUARDIAN_TO_DEPENDENT,
  FORMS_ADD_PATIENT_DEPENDENT,
  FORMS_EDIT_PATIENT_DEPENDENT,
  Loader,
  phoneNumber,
} from 'app/shared';
import { useGetClinicPatientInfoQuery } from 'app/services/patients/patients';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { QueryParams } from 'app/types';
import {
  AddGuardianToDependentCustomProps,
  AddGuardianToDependentForm,
} from 'app/features/patient/addGuardianToDependentModal/form/addGuardianToDependentForm';
import { formValuesAdapter } from 'app/features/patient/addGuardianToDependentModal/form/helpers/formValuesAdapter';
import { useColumns } from 'app/features/patient/addGuardianToDependentModal/form/helpers/useColumns';
import { AddGuardianToDependentFormData } from 'app/features/patient/addGuardianToDependentModal/form/types';
import { PrimaryPatientBaseInfo } from 'app/features/patient/patientForm/types';

interface Props {
  clinicId: string;
}

export const AddGuardianToDependentModal = ({ clinicId }: Props) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();

  // Local State
  const [params, setParams] = useState<QueryParams | undefined>();
  const [itemIds, setItemIds] = useState<string[]>([]);

  // Selectors
  const isOpen = useAppSelector((state) => state.modal.addGuardianToDependent.isOpen);
  const isAddDependentOpen = useAppSelector((state) => state.modal.addPatientDependent.isOpen);
  const addedPrimaryPatientsData: PrimaryPatientBaseInfo[] = useAppSelector(
    (state) => state.patientSlice.addedPrimaryPatients,
  );
  const mobileNumber: string = useAppSelector(
    (state) => state.form?.[FORMS_ADD_GUARDIAN_TO_DEPENDENT]?.values?.mobileNumber,
  );

  // Requests
  const { data, isLoading, isFetching } = useGetClinicPatientInfoQuery(
    !phoneNumber(mobileNumber) ? { clinicId, params: { ...params, hideDependants: 'true' } } : skipToken,
  );

  // Conditions
  const hasSelectedItem = itemIds.length > 0;
  const isRequesting = isLoading || isFetching;

  // Helper Variables
  const patients = useMemo(() => {
    if (data?.data) {
      return data.data.filter(
        (item) => !addedPrimaryPatientsData?.find((primaryPatient) => primaryPatient.id === item.id),
      );
    }

    return [];
  }, [addedPrimaryPatientsData, data?.data]);

  // Handlers
  const onRowSelectionChange = useCallback(
    (rowIndex: number) => {
      if (patients) {
        const itemId = patients[rowIndex].id;
        setItemIds((prevItemIds) => {
          if (prevItemIds.includes(itemId)) {
            return prevItemIds.filter((id) => id !== itemId);
          } else {
            return [...prevItemIds, itemId];
          }
        });
      }
    },
    [patients],
  );

  const onClose = useCallback(() => {
    dispatch(toggleModal('addGuardianToDependent', false));
  }, [dispatch]);

  const resetSelected = () => setItemIds([]);

  const onSubmit = useCallback(() => {
    if (addedPrimaryPatientsData && data?.data) {
      const results: PrimaryPatientBaseInfo[] = data.data.filter((item) => itemIds.includes(item.id));
      const changeValue = [...addedPrimaryPatientsData, ...results];

      // [i]: We are using ChangeRelationModal only in 2 cases
      if (isAddDependentOpen) {
        dispatch(change(FORMS_ADD_PATIENT_DEPENDENT, 'primaryPatients', changeValue));
      } else {
        dispatch(change(FORMS_EDIT_PATIENT_DEPENDENT, 'primaryPatients', changeValue));
      }

      setItemIds([]);
      onClose();
    }
  }, [addedPrimaryPatientsData, data?.data, isAddDependentOpen, onClose, itemIds, dispatch]);

  // Helper Hooks
  const columns = useColumns({ onRowSelectionChange, itemIds });
  const { onChange } = useFilterFormActions<AddGuardianToDependentFormData, AddGuardianToDependentCustomProps>(
    params,
    setParams,
    formValuesAdapter,
  );

  // Effects
  useEffect(() => {
    if (params) {
      resetSelected();
    }
  }, [params]);

  useModalCleaner('addGuardianToDependent');

  return (
    <Modal backdrop="static" keyboard={false} isOpen={isOpen} size="lg" toggle={onClose}>
      <ModalHeader toggle={onClose}>{formatMessage({ id: 'CORE.LABEL.ADD-PRIMARY-TO-DEPENDENT' })}</ModalHeader>
      <ModalBody>
        <AddGuardianToDependentForm onChange={onChange} resetSelected={resetSelected} />
        {isRequesting ? (
          <Loader />
        ) : (
          <RemoteBootstrapTable
            classes="table-striped"
            columns={columns}
            data={patients}
            id="guardianItems"
            keyField="id"
            isFetching={isFetching}
            isLoading={isLoading}
            pagination={data?.pagination}
            params={params}
            setParams={setParams}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="secondary" onClick={onClose}>
          {formatMessage({ id: 'CORE.BUTTON.CANCEL' })}
        </Button>
        <Button type="button" disabled={!hasSelectedItem} color="primary-gradient" onClick={onSubmit}>
          {formatMessage({ id: 'CORE.BUTTON.ADD' })}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
