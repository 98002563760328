import React, { useEffect, useMemo } from 'react';
import draftToHtml from 'draftjs-to-html';
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import { getFormInitialValues } from 'redux-form';
import { formattedDateAndTime, useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { LocalBootstrapTable } from 'app/components/bootstrapTable/localBootstrapTable/localBootstrapTable';
import {
  FORMATS_DATE_AND_TIME,
  FORMS_ASSESSMENT_BILLING,
  FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM,
  FORMS_ASSESSMENT_PRESCRIPTIONS,
  FORMS_ASSESSMENT_PRINT_MODAL_FORM,
  FORMS_ASSESSMENT_SIGNATURE_FORM,
} from 'app/shared';
import { fetchClinicLogo } from 'app/redux/doctorAssessment/doctorAssessment.actions';
import { DiagnosesResponse } from 'app/redux/doctorAssessment/types';
import { CustomDescriptionList } from 'app/features/doctorAssessment/components/medicalProfileSection/components/customDescriptionList';
import {
  InitialAssessmentVitalsItems,
  useColumns,
  useIcdCodesColumns,
  useLocationsColumns,
  usePastMedicalHistory,
  useServiceItemsColumns,
  useSurgicalHistory,
} from 'app/features/doctorAssessment/components/medicalProfileSection/components/helpers';
import { useReferralsColumns } from 'app/features/doctorAssessment/components/medicalProfileSection/components/helpers/columns/useReferralsColumns';
import { useTableData } from 'app/features/doctorAssessment/components/medicalProfileSection/components/helpers/printVisitPractitionerAssessment/useTableData';
import { PrintModalFormData } from 'app/features/doctorAssessment/components/medicalProfileSection/components/modals/printModal/printModalBody';
import { MedicalProfileFormData } from 'app/features/doctorAssessment/components/medicalProfileSection/components/practitionerAssessment/practitionerAssessment';
import { usePrescriptionData } from 'app/features/doctorAssessment/components/prescriptionSection/components/prescriptionPrintTemplate/helpers/usePrescriptionData';
import { PrescriptionPrintBody } from 'app/features/doctorAssessment/components/prescriptionSection/components/prescriptionPrintTemplate/prescriptionPrintBody';
import { PrescriptionPrintHeader } from 'app/features/doctorAssessment/components/prescriptionSection/components/prescriptionPrintTemplate/prescriptionPrintHeader';
import {
  WrappedPrescriptionFormData,
  WrappedPrescriptionsFormData,
} from 'app/features/doctorAssessment/components/prescriptionSection/types';
import { SignaturePrintField } from 'app/features/doctorAssessment/components/signaturePrintField/signaturePrintField';
import { useCheckViewStatus } from 'app/features/doctorAssessment/helpers';
import styles from 'app/features/doctorAssessment/components/medicalProfileSection/components/helpers/printVisitPractitionerAssessment/printVisitPractitionerAssessment.module.scss';

export const PrintVisitAssessment = () => {
  const { formatMessage } = useAppIntl();
  const timeNow = moment().format(FORMATS_DATE_AND_TIME);
  const printHeaderData = usePrescriptionData();
  const dispatch = useAppDispatch();

  // Selectors
  const values: Partial<MedicalProfileFormData> | undefined = useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM]?.values,
  );
  const initialValues = useAppSelector((state) =>
    (getFormInitialValues(FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM) as any)(state),
  );
  const prescriptionsValues: WrappedPrescriptionsFormData | undefined = useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_PRESCRIPTIONS]?.values,
  );
  const servicesItemsValues = useAppSelector((state) => state.form[FORMS_ASSESSMENT_BILLING]?.values);
  const printValues: Partial<PrintModalFormData> | undefined = useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_PRINT_MODAL_FORM]?.values,
  );
  const signatureUrl = useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_SIGNATURE_FORM]?.values?.signatureFile.url,
  );
  const billingHistoricalData = useAppSelector((state) =>
    state.doctorAssessment.data?.previousBillingSections?.flatMap(
      (previousBillingItem) => previousBillingItem.assessmentBillingItems,
    ),
  );
  const prescriptionsHistoricalData = useAppSelector((state) =>
    state.doctorAssessment.data?.previousAssessmentPrescriptionSections?.flatMap(
      (historicalData) => historicalData.assessmentPrescriptions,
    ),
  );
  const assessmentData = useAppSelector((state) => state.doctorAssessment.data);
  const doctorName = useAppSelector(
    (state) => state.treatment.generalTreatmentInformation.data?.appointmentInformation.doctorName,
  );
  const pastMedicalHistoryTable = printValues?.pastMedicalHistoryTable?.filter((row) => row.toPrint);
  const surgicalHistoryTable = printValues?.surgicalHistoryTable?.filter((row) => row.toPrint);

  // Helper Hooks
  const {
    assessmentsData,
    chiefComplaintData,
    clinicalExaminationData,
    healthEducationData,
    procedureUpdateData,
    presentIllnessHistoryData,
    medicalConditionData,
    systemReviewData,
  } = useTableData();
  const { isContinue } = useCheckViewStatus(assessmentData?.viewStatus, assessmentData?.completedAt);
  const columnsIcdHistory = useIcdCodesColumns(true);
  const columnsComplaintHistory = useColumns({
    dataField: 'chiefComplaint',
    text: 'TREATMENT-PLAN.LABEL.CHIEF-COMPLAINT',
    isPrintView: true,
  });
  const columnsPresentIllnessHistory = useColumns({
    dataField: 'presentIllnessHistory',
    text: 'TREATMENT-PLAN.LABEL.HISTORY-OF-PRESENT-ILLNESS',
    isPrintView: true,
  });
  const columnsSystemReviewHistory = useColumns({
    dataField: 'systemReview',
    text: 'TREATMENT-PLAN.LABEL.REVIEW-OF-SYSTEM',
    isPrintView: true,
  });
  const columnsMedicalConditionHistory = useColumns({
    dataField: 'medicalCondition',
    text: 'TREATMENT-PLAN.LABEL.MEDICAL-CONDITION',
    isPrintView: true,
  });
  const columnsClinicalExaminationHistory = useColumns({
    dataField: 'clinicalExamination',
    text: 'TREATMENT-PLAN.LABEL.CLINICAL-EXAMINATION',
    isPrintView: true,
  });
  const columnsAssessmentHistory = useColumns({
    dataField: 'assessment',
    text: 'TREATMENT-PLAN.TEXT.ASSESSMENT',
    isPrintView: true,
  });
  const procedureUpdateHistory = useColumns({
    dataField: 'procedureUpdate',
    text: 'TREATMENT-PLAN.LABEL.PROCEDURE-UPDATE',
    isPrintView: true,
  });
  const columnsHealthEducationHistory = useColumns({
    dataField: 'healthEducation',
    text: 'TREATMENT-PLAN.LABEL.HEALTH-EDUCATION',
    isPrintView: true,
  });
  const locationsColumns = useLocationsColumns();
  const serviceItemsColumns = useServiceItemsColumns();
  const pastMedicalHistoryColumns = usePastMedicalHistory();
  const surgicalHistoryColumns = useSurgicalHistory();
  const referralsColumns = useReferralsColumns();

  const billingItemsData = useMemo(() => {
    if (isContinue) {
      if (billingHistoricalData && servicesItemsValues?.assessmentBillingItems?.length > 0) {
        return [...billingHistoricalData, ...servicesItemsValues?.assessmentBillingItems];
      } else if (billingHistoricalData) {
        return billingHistoricalData;
      } else if (servicesItemsValues?.assessmentBillingItems?.length > 0) {
        return servicesItemsValues?.assessmentBillingItems;
      } else return [];
    } else {
      if (servicesItemsValues?.assessmentBillingItems?.length > 0) {
        return servicesItemsValues?.assessmentBillingItems;
      } else return [];
    }
  }, [billingHistoricalData, servicesItemsValues, isContinue]);

  const prescriptionsData: WrappedPrescriptionFormData[] = useMemo(() => {
    if (isContinue) {
      if (
        prescriptionsHistoricalData &&
        prescriptionsValues?.assessmentPrescriptions &&
        prescriptionsValues?.assessmentPrescriptions?.length > 0
      ) {
        return [
          ...prescriptionsHistoricalData.map((p) => ({
            prescriptionId: p.prescriptionId,
            assessmentPrescriptionDrugs: p.prescriptionDrugs,
          })),
          ...prescriptionsValues?.assessmentPrescriptions,
        ];
      } else if (prescriptionsHistoricalData) {
        return prescriptionsHistoricalData.map((p) => ({
          prescriptionId: p.prescriptionId,
          assessmentPrescriptionDrugs: p.prescriptionDrugs,
        }));
      } else if (
        prescriptionsValues?.assessmentPrescriptions &&
        prescriptionsValues?.assessmentPrescriptions?.length > 0
      ) {
        return prescriptionsValues?.assessmentPrescriptions;
      } else return [];
    } else {
      if (prescriptionsValues?.assessmentPrescriptions && prescriptionsValues?.assessmentPrescriptions?.length > 0) {
        return prescriptionsValues?.assessmentPrescriptions;
      } else return [];
    }
  }, [prescriptionsHistoricalData, prescriptionsValues, isContinue]);

  useEffect(() => {
    if (printHeaderData?.clinicId) {
      dispatch(fetchClinicLogo(printHeaderData?.clinicId));
    }
  }, [dispatch, printHeaderData?.clinicId]);

  return (
    <>
      <Row xs={1} className="mt-4">
        {chiefComplaintData?.length > 0 && (
          <Col className="mb-2">
            <div className={styles.pageBreak}>
              <LocalBootstrapTable
                columns={columnsComplaintHistory}
                data={chiefComplaintData}
                id="chiefComplaint"
                keyField="createdAt"
                loading={false}
                classes={`table-sm mb-4`}
                wrapperClasses={styles.overflowHidden}
                showRowId={false}
              />
            </div>
          </Col>
        )}
        {presentIllnessHistoryData?.length > 0 && (
          <Col className="mb-2">
            <div className={styles.pageBreak}>
              <LocalBootstrapTable
                columns={columnsPresentIllnessHistory}
                data={presentIllnessHistoryData}
                id="presentIllnessHistory"
                keyField="id"
                loading={false}
                classes={`table-sm mb-4`}
                wrapperClasses={styles.overflowHidden}
                showRowId={false}
              />
            </div>
          </Col>
        )}
        {medicalConditionData?.length > 0 && (
          <Col className="mb-2">
            <div className={styles.pageBreak}>
              <LocalBootstrapTable
                columns={columnsMedicalConditionHistory}
                data={medicalConditionData}
                id="medicalCondition"
                keyField="id"
                loading={false}
                classes={`table-sm mb-4`}
                wrapperClasses={styles.overflowHidden}
                showRowId={false}
              />
            </div>
          </Col>
        )}
        {!!pastMedicalHistoryTable?.length && printValues?.pastMedicalHistory && (
          <Col className="mb-2">
            <div className={styles.icdTable}>
              <LocalBootstrapTable
                columns={pastMedicalHistoryColumns}
                data={pastMedicalHistoryTable}
                id="pastMedicalHistory"
                keyField="patientMedicalIllnessId"
                loading={false}
                classes={`table-sm mb-4`}
                wrapperClasses={styles.overflowHidden}
                showRowId={false}
              />
            </div>
          </Col>
        )}
        {!!surgicalHistoryTable?.length && printValues?.surgicalHistory && (
          <Col className="mb-2">
            <div className={styles.icdTable}>
              <LocalBootstrapTable
                columns={surgicalHistoryColumns}
                data={surgicalHistoryTable}
                id="surgicalHistory"
                keyField="patientSurgeryId"
                loading={false}
                classes={`table-sm mb-4`}
                wrapperClasses={styles.overflowHidden}
                showRowId={false}
              />
            </div>
          </Col>
        )}
        {systemReviewData?.length > 0 && (
          <Col className="mb-2">
            <div className={styles.pageBreak}>
              <LocalBootstrapTable
                columns={columnsSystemReviewHistory}
                data={systemReviewData}
                id="systemReview"
                keyField="id"
                loading={false}
                classes={`table-sm mb-4`}
                wrapperClasses={styles.overflowHidden}
                showRowId={false}
              />
            </div>
          </Col>
        )}
        {clinicalExaminationData?.length > 0 && (
          <Col className="mb-2">
            <div className={styles.pageBreak}>
              <LocalBootstrapTable
                columns={columnsClinicalExaminationHistory}
                data={clinicalExaminationData}
                id="signs"
                keyField="id"
                loading={false}
                classes={`table-sm mb-4`}
                wrapperClasses={styles.overflowHidden}
                showRowId={false}
              />
            </div>
          </Col>
        )}
        {printValues?.vitals && (
          <div className={styles.pageBreak}>
            <InitialAssessmentVitalsItems />
          </div>
        )}
        {/*TODO: LAB REPORT HERE IN THE FUTURE*/}
        <Col className="mb-5">
          <div className={styles.icdTable}>
            {values?.icdTable && values?.icdTable?.length > 0 && (
              <>
                <div className="mb-4">
                  <p className={styles.sectionSubtitle}>
                    {formatMessage({ id: 'CORE.TABLE.DATE' })}: {timeNow}
                  </p>
                  {values.diagnosis && (
                    <p
                      className={styles.sectionSubtitle}
                      dangerouslySetInnerHTML={{ __html: draftToHtml(values?.diagnosis) }}
                    />
                  )}
                  <LocalBootstrapTable
                    columns={columnsIcdHistory}
                    data={values?.icdTable}
                    id="icd"
                    keyField="icdCode"
                    loading={false}
                    classes={`table-sm mb-4`}
                    wrapperClasses={styles.overflowHidden}
                    showRowId={false}
                  />
                </div>
              </>
            )}
            {initialValues.diagnosisTable.flatMap((diagnose: DiagnosesResponse, index: number) => (
              <Col className="mb-4" key={`diagnoses-${index}`}>
                {diagnose.text?.trim().length > 0 && (
                  <>
                    <p className={styles.sectionTitle}>{formatMessage({ id: 'CORE.TABLE.DESCRIPTION' })}: </p>
                    <p className={styles.sectionSubtitle} dangerouslySetInnerHTML={{ __html: diagnose.text }} />
                  </>
                )}
                <p className={styles.sectionTitle}>
                  {formatMessage({ id: 'CORE.TABLE.DATE' })}: {formattedDateAndTime(diagnose.createdAt)}
                </p>
                {diagnose.diagnoses !== null && diagnose.diagnoses.length > 0 && (
                  <LocalBootstrapTable
                    columns={columnsIcdHistory}
                    data={diagnose.diagnoses}
                    id="diagnosis"
                    keyField="icd10Code"
                    loading={false}
                    classes={`table-sm mb-4`}
                    wrapperClasses={styles.overflowHidden}
                    showRowId={false}
                  />
                )}
              </Col>
            ))}
          </div>
        </Col>
        {assessmentsData?.length > 0 && (
          <Col className="mb-2">
            <div className={styles.pageBreak}>
              <LocalBootstrapTable
                columns={columnsAssessmentHistory}
                data={assessmentsData}
                id="assessment"
                keyField="id"
                loading={false}
                classes={`table-sm mb-4`}
                wrapperClasses={styles.overflowHidden}
                showRowId={false}
              />
            </div>
          </Col>
        )}
        {billingItemsData?.length > 0 && (
          <Col className="mb-5">
            <LocalBootstrapTable
              columns={serviceItemsColumns}
              data={billingItemsData}
              id="servicesItems"
              keyField="item"
              loading={false}
              classes={`table-sm`}
              wrapperClasses={styles.overflowHidden}
              showRowId={false}
            />
          </Col>
        )}
        {printValues?.plan && (
          <Col>
            {values?.procedureLocation?.notes && values?.procedureLocation?.notes?.length > 0 && (
              <>
                <LocalBootstrapTable
                  columns={locationsColumns}
                  data={values?.procedureLocation?.notes}
                  id="locations"
                  keyField="id"
                  loading={false}
                  classes={`table-sm mb-4`}
                  wrapperClasses={styles.overflowHidden}
                  showRowId={false}
                />
              </>
            )}
            {values?.plan && values.plan.blocks[0].text?.trim().length > 0 && (
              <CustomDescriptionList
                label={formatMessage({ id: 'TREATMENT-PLAN.LABEL.ADDITIONAL-PLAN-COMMENT' })}
                value={draftToHtml(values?.plan)}
                className="mb-4"
              />
            )}
          </Col>
        )}
        {printValues?.goal && (
          <Col>
            {values?.goalLocation?.notes && values?.goalLocation?.notes?.length > 0 && (
              <>
                <LocalBootstrapTable
                  columns={locationsColumns}
                  data={values?.goalLocation?.notes}
                  id="goalLocations"
                  keyField="id"
                  loading={false}
                  classes={`table-sm mb-4`}
                  wrapperClasses={styles.overflowHidden}
                  showRowId={false}
                />
              </>
            )}
            {values?.goal && values.goal.blocks[0].text?.trim().length > 0 && (
              <CustomDescriptionList
                label={formatMessage({ id: 'TREATMENT-PLAN.LABEL.ADDITIONAL-GOAL-COMMENT' })}
                value={draftToHtml(values?.goal)}
                className="mb-4"
              />
            )}
          </Col>
        )}
        {healthEducationData?.length > 0 && (
          <Col className="mb-2">
            <div className={styles.pageBreak}>
              <LocalBootstrapTable
                columns={columnsHealthEducationHistory}
                data={healthEducationData}
                id="conditions"
                keyField="createdAt"
                loading={false}
                classes={`table-sm mb-4`}
                wrapperClasses={styles.overflowHidden}
                showRowId={false}
              />
            </div>
          </Col>
        )}
        {procedureUpdateData?.length > 0 && (
          <Col className="mb-2">
            <div className={styles.pageBreak}>
              <LocalBootstrapTable
                columns={procedureUpdateHistory}
                data={procedureUpdateData}
                id="procedureUpdate"
                keyField="id"
                loading={false}
                classes={`table-sm mb-4`}
                wrapperClasses={styles.overflowHidden}
                showRowId={false}
              />
            </div>
          </Col>
        )}
        <div className={styles.pageBreak}>
          {printValues?.referrals &&
            assessmentData?.referralSection?.referrals &&
            assessmentData.referralSection?.referrals?.length > 0 && (
              <Col className="mb-5">
                <div className={styles.pageBreak}>
                  <LocalBootstrapTable
                    columns={referralsColumns}
                    data={assessmentData.referralSection.referrals}
                    id="assessmentReferrals"
                    keyField="id"
                    loading={false}
                    classes={`table-sm`}
                    wrapperClasses={styles.overflowHidden}
                    showRowId={false}
                  />
                </div>
              </Col>
            )}
        </div>
      </Row>
      {prescriptionsData && prescriptionsData?.length > 0 && printHeaderData && (
        <div className={styles.separatePage}>
          {prescriptionsData.map((prescription, index) => {
            return (
              <div key={index}>
                <PrescriptionPrintHeader data={printHeaderData} />
                <PrescriptionPrintBody
                  prescriptionData={prescription}
                  doctorName={doctorName}
                  signatureUrl={signatureUrl}
                  showSignature={false}
                />
              </div>
            );
          })}
        </div>
      )}
      <SignaturePrintField doctorName={doctorName} signatureUrl={signatureUrl} />
    </>
  );
};
