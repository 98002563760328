import { treatmentPathCore } from 'app/routes/treatment/constants';

export const isTreatmentRoute = (pathname: string) =>
  pathname.includes(treatmentPathCore) && !pathname.includes('/patient');
export const isTreatmentPlanPage = (pathname: string) => isTreatmentRoute(pathname) && !pathname.includes('assessment');
export const isGeneralTreatmentPage = (pathname: string) =>
  pathname.includes(`${treatmentPathCore}/patients`) && pathname.includes('general');
export const isDoctorAssessmentPage = (pathname: string) =>
  isTreatmentRoute(pathname) && pathname.includes('assessment');
export const isClinicTreatmentPage = (pathname: string) =>
  pathname.includes(`${treatmentPathCore}/clinics/`) && pathname.includes('/patients/');
