import { PaymentAllocation } from 'app/redux/patientPayments/types';
import { PatientInvoicesToPay } from 'app/types';

export const allocatePaymentToInvoices = (
  invoices: PatientInvoicesToPay[],
  invoiceBalances: Record<string, number>,
  totalPaymentAmount: number,
): PaymentAllocation[] => {
  let remainingAmount = totalPaymentAmount;

  return invoices.reduce<PaymentAllocation[]>((allocations, invoice) => {
    const balance = invoiceBalances[invoice.id];
    if (balance <= 0 || remainingAmount <= 0) return allocations;

    const amount = Math.min(balance, remainingAmount);
    remainingAmount -= amount;

    return [...allocations, { invoiceId: invoice.id, amount }];
  }, []);
};
