import { allocatePaymentToInvoices } from 'app/redux/patientPayments/helpers/allocatePaymentToInvoices';
import { calculateRemainingCredits } from 'app/redux/patientPayments/helpers/calculateRemainingCredits';
import { createPaymentsFromAllocations } from 'app/redux/patientPayments/helpers/createPaymentsFromAllocations';
import { AddSettleInvoicesPaymentPayload, PatientPaymentsState } from 'app/redux/patientPayments/types';

export const createPayment = (
  { actualInvoices, invoiceValues, remainingCredits }: PatientPaymentsState['settleInvoices'],
  { selected, generalPaymentDetails }: AddSettleInvoicesPaymentPayload,
) => {
  const selectedInvoices = selected ?? actualInvoices;
  const paymentAmount = Number(generalPaymentDetails?.amount);
  const paymentAllocations = allocatePaymentToInvoices(selectedInvoices, invoiceValues, paymentAmount);

  const payments = createPaymentsFromAllocations(
    paymentAllocations,
    selectedInvoices,
    invoiceValues,
    generalPaymentDetails,
  );

  return {
    payments,
    paymentAllocations,
    invoiceValues: invoiceValues,
    remainingCredits: calculateRemainingCredits(remainingCredits, generalPaymentDetails),
  };
};
