import React, { useCallback } from 'react';
import { WrappedFieldArrayProps } from 'redux-form/lib/FieldArray';
import { LocalBootstrapTable } from 'app/components/bootstrapTable/localBootstrapTable/localBootstrapTable';
import { useColumns } from 'app/features/doctorAssessment/components/medicalProfileSection/components/helpers/wrappers/diagnosisWrapper/fieldArrayTable/helpers';

interface Props {
  fieldArrayProps: WrappedFieldArrayProps;
  isReadOnly?: boolean;
}

export const FieldArrayTable = ({ fieldArrayProps, isReadOnly }: Props) => {
  const items = fieldArrayProps.fields.getAll() || [];

  // Handlers
  const removeField = useCallback(
    (rowIndex: number) => {
      fieldArrayProps.fields.remove(rowIndex);
    },
    [fieldArrayProps.fields],
  );

  // Helper Hooks
  const columns = useColumns(removeField, isReadOnly);

  return (
    <>
      <LocalBootstrapTable
        classes="table-sm"
        columns={columns}
        data={items}
        id="diagnosisTable"
        keyField="icd10Code"
        loading={false}
      />
    </>
  );
};
