import React, { useCallback, useEffect, useState } from 'react';
import { OnChangeValue } from 'react-select';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { Field } from 'redux-form';
import { useAppIntl, useAppSelector } from 'app/helpers';
import { Box, Select } from 'app/components';
import { InputComponent } from 'app/shared';
import { DoctorData } from 'app/redux/book-appointment/types';
import { Option } from 'app/types';
import { useFormatOptionLabel } from 'app/features/bookAppointment/bookAppointmentWizard/helpers';
import { NoDataMessage } from 'app/features/bookAppointment/bookAppointmentWizard/shared';
import { DoctorList } from './doctorList/doctorList';
import { useFilterHandlers } from './helpers/useFilterHandlers';
import { SpecialityList } from './specialityList/specialityList';

interface Props {
  onSpecialityChange: (option: Option) => void;
  onDoctorChange: (doctor: DoctorData) => void;
  onBranchChange: (option: Option | null) => void;
  selectedSpeciality: Option | null;
  selectedDoctor: DoctorData | null;
}

export const SpecialityStep = ({
  onSpecialityChange,
  onBranchChange,
  onDoctorChange,
  selectedSpeciality,
  selectedDoctor,
}: Props) => {
  const { formatMessage } = useAppIntl();
  const [filteredSpecialities, setFilteredSpecialities] = useState<Option[]>([]);

  const branchOptions = useAppSelector((state) => state.bookAppointment.branches.data);
  const selectedBranch = useAppSelector((state) => state.bookAppointment.selectedBranch);
  const { data: specialities, loading: loadingSpecialities } = useAppSelector(
    (state) => state.bookAppointment.specialities,
  );
  const { data: doctors, loading: loadingDoctors } = useAppSelector((state) => state.bookAppointment.doctors);

  // Actions
  const formatOptionLabel = useFormatOptionLabel();
  const { filterSpecialities, filterPractitioners } = useFilterHandlers({
    specialities,
    setFilteredSpecialities,
  });

  const handleBranchChange = useCallback(
    (newValue: OnChangeValue<Option, false>) => {
      onBranchChange(newValue);
    },
    [onBranchChange],
  );

  // Effects
  useEffect(() => {
    setFilteredSpecialities(specialities);
  }, [specialities, setFilteredSpecialities]);

  return (
    <>
      <Row>
        <Col>
          <Box className="bookAppointment__lightBorder">
            <FormGroup>
              <Label for="branchSelect">{formatMessage({ id: 'CORE.TABLE.BRANCH' })}</Label>
              <Select
                inputId="branchSelect"
                value={selectedBranch}
                name="branchSelect"
                options={branchOptions}
                onChange={handleBranchChange}
                formatOptionLabel={formatOptionLabel}
                isSearchable
              />
            </FormGroup>
          </Box>
        </Col>
      </Row>

      {!selectedBranch && <NoDataMessage customMessageId="BOOK-APPOINTMENT.TEXT.SELECT-BRANCH-FIRST" />}

      {selectedBranch && (
        <Row>
          <Col lg={4}>
            <Box className="h-100 fadeIn">
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="specialityFilter">
                      {formatMessage({ id: 'BOOK-APPOINTMENT.LABEL.BOOK-BY-SPECIALITY' })}
                    </Label>
                    <Input id="specialityFilter" type="text" onChange={filterSpecialities} />
                  </FormGroup>
                </Col>
              </Row>

              <SpecialityList
                data={filteredSpecialities}
                selectedBranch={selectedBranch}
                selectedDoctor={selectedDoctor}
                selectedSpeciality={selectedSpeciality}
                onSpecialityChange={onSpecialityChange}
              />

              {!loadingSpecialities && filteredSpecialities.length === 0 && <NoDataMessage />}
            </Box>
          </Col>
          <Col lg={8}>
            <Box className="bookAppointment__lightBorder fadeIn">
              <Col>
                <Field
                  component={InputComponent}
                  label={formatMessage({ id: 'BOOK-APPOINTMENT.LABEL.BOOK-BY-PRACTITIONER' })}
                  name="doctorFilter"
                  type="text"
                  onChange={filterPractitioners}
                />
              </Col>
            </Box>

            <DoctorList onDoctorChange={onDoctorChange} selectedDoctor={selectedDoctor} />

            {!loadingDoctors && doctors.length === 0 && <NoDataMessage />}
          </Col>
        </Row>
      )}
    </>
  );
};
