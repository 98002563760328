import React from 'react';
import classNames from 'classnames';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { getTranslation, useAppIntl, useAppSelector } from 'app/helpers';
import { Loader } from 'app/shared';
import { DoctorData } from 'app/redux/book-appointment/types';
import { Option } from 'app/types';

interface Props {
  data: Option[];
  selectedBranch: Option | null;
  selectedDoctor: DoctorData | null;
  selectedSpeciality: Option | null;
  onSpecialityChange: (option: Option) => void;
}

export const SpecialityList = ({
  onSpecialityChange,
  selectedBranch,
  selectedSpeciality,
  selectedDoctor,
  data,
}: Props) => {
  const { locale } = useAppIntl();
  const loading = useAppSelector((state) => state.bookAppointment.specialities.loading);
  const isReferralMode = useAppSelector((state) => !!state.bookAppointment.referralInfo);

  return (
    <>
      {loading && <Loader />}

      {!loading && (
        <Row className="fadeIn">
          {data.map((s: Option, i: number) => {
            return (
              <Col key={i} lg={12}>
                <UncontrolledTooltip target={`specialityListOption-${i}`}>
                  {getTranslation(s, locale)}
                </UncontrolledTooltip>
                <Button
                  block
                  color="primary"
                  className={classNames('mt-2 btn d-flex justify-content-between specialityList__option', {
                    selected: selectedSpeciality?.value === s.value && !selectedDoctor,
                  })}
                  id={`specialityListOption-${i}`}
                  outline={selectedSpeciality?.value !== s.value || !!selectedDoctor}
                  onClick={() => onSpecialityChange(s)}
                  disabled={isReferralMode && !selectedBranch}
                >
                  <div className="text-truncate">{getTranslation(s, locale)}</div>
                  <div className="specialityList__option--clinicyId">{s.clinicyId}</div>
                </Button>
              </Col>
            );
          })}
        </Row>
      )}
    </>
  );
};
