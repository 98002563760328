import React, { useMemo } from 'react';
import { getTranslation, useAppIntl } from 'app/helpers';
import { Item } from 'app/components/descriptionList/types';
import { DoctorData } from 'app/redux/book-appointment/types';

interface Props {
  doctor: DoctorData;
}

export const useItems = ({ doctor }: Props) => {
  const { formatMessage, locale } = useAppIntl();
  const hasAgeRestrictions = !!(doctor.acceptablePatientAgeFrom || doctor.acceptablePatientAgeTo);

  return useMemo((): Item[] => {
    return [
      {
        label: formatMessage({ id: 'CORE.LABEL.SPECIALITY' }),
        value: doctor.speciality ? getTranslation(doctor.speciality, locale) : '---',
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.RANK' }),
        value: getTranslation(doctor.rank, locale),
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.GENDER' }),
        value: getTranslation(doctor.gender, locale),
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.NATIONALITY' }),
        value: getTranslation(doctor.nationality, locale),
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.BOOKING-RESTRICTIONS', defaultMessage: 'Booking Restrictions' }),
        value: hasAgeRestrictions ? (
          <>
            {!doctor.acceptablePatientAgeTo &&
              formatMessage(
                { id: 'CORE.LABEL.YEARS-AGE-OR-ABOVE', defaultMessage: '{ageFrom} years old or above' },
                { ageFrom: doctor.acceptablePatientAgeFrom },
              )}
            {!!doctor.acceptablePatientAgeTo &&
              formatMessage(
                { id: 'CORE.LABEL.YEARS-AGE-RANGE', defaultMessage: '{ageFrom} - {ageTo} years old' },
                { ageFrom: doctor.acceptablePatientAgeFrom, ageTo: doctor.acceptablePatientAgeTo },
              )}
          </>
        ) : null,
        colProps: { lg: 12, xl: 12 },
      },
    ];
  }, [doctor, formatMessage, locale, hasAgeRestrictions]);
};
