import { useMemo } from 'react';
import { useAppSelector } from 'app/helpers';
import { MedicalProfileFormData } from 'app/features/doctorAssessment/components/medicalProfileSection/components/practitionerAssessment/practitionerAssessment';

export const useInitialValues = (isContinue: boolean, isDraftMode: boolean) => {
  const procedure = useAppSelector((state) => state.doctorAssessment.data?.procedure);

  const isContinueOrDraft = isContinue || isDraftMode;

  const initialValues = useMemo<MedicalProfileFormData>(() => {
    return {
      icdTable: [],
    };
  }, []);

  const initialValuesVisit = useMemo<Partial<MedicalProfileFormData> | undefined>(() => {
    if (procedure) {
      return {
        icdTable: [],
        assessmentTable: [
          ...(isContinue ? [] : procedure.assessments),
          ...(procedure.previousAssessments?.flatMap((item) => item.previousData) || []),
        ],
        healthEducationTable: [
          ...(isContinue ? [] : procedure.healthEducations),
          ...(procedure.previousHealthEducations?.flatMap((item) => item.previousData) || []),
        ],
        medicalConditionTable: [
          ...(isContinue ? [] : procedure.medicalConditions),
          ...(procedure.previousMedicalConditions?.flatMap((item) => item.previousData) || []),
        ],
        otherConditionTable: [
          ...(isContinue ? [] : procedure.otherConditions),
          ...(procedure.previousOtherConditions?.flatMap((item) => item.previousData) || []),
        ],
        presentIllnessHistoryTable: [
          ...(isContinue ? [] : procedure.presentIllnessHistories),
          ...(procedure.previousPresentIllnessHistories?.flatMap((item) => item.previousData) || []),
        ],
        systemReviewTable: [
          ...(isContinue ? [] : procedure.systemReviews),
          ...(procedure.previousSystemReviews?.flatMap((item) => item.previousData) || []),
        ],
        chiefComplaintTable: [
          ...(isContinue ? [] : procedure.chiefComplains),
          ...(procedure.previousChiefComplains?.flatMap((item) => item.previousData) || []),
        ],
        commentTable: [
          ...(isContinue ? [] : procedure.comments),
          ...(procedure.previousComments?.flatMap((item) => item.previousData) || []),
        ],
        diagnosisTable: [
          ...(isContinue ? [] : procedure.diagnoses),
          ...(procedure.previousDiagnoses?.flatMap((item) => item.previousData) || []),
        ],
        procedureLocationTable: [
          procedure.planLocations?.[0] && {
            template: procedure.planLocations.slice(-1)[0].template,
            notes: procedure.planLocations,
          },
        ],
        goalLocationTable: [
          procedure.goalLocations?.[0] && {
            template: procedure.goalLocations.slice(-1)[0].template,
            notes: procedure.goalLocations,
          },
        ],
        plan: procedure.plans?.[0]?.formattedText && JSON.parse(procedure.plans[0].formattedText),
        procedureLocation: procedure.planLocations?.[0] && {
          template: procedure.planLocations.slice(-1)[0].template,
          notes: [
            ...procedure.planLocations,
            ...(procedure.previousPlanLocations
              ? procedure.previousPlanLocations.flatMap((item) => item.previousData)
              : []),
          ],
        },
        goal: procedure.goals?.[0]?.formattedText && JSON.parse(procedure.goals[0].formattedText),
        goalLocation: procedure.goalLocations?.[0] && {
          template: procedure.goalLocations.slice(-1)[0].template,
          notes: [
            ...procedure.goalLocations,
            ...(procedure.previousGoalLocations
              ? procedure.previousGoalLocations.flatMap((item) => item.previousData)
              : []),
          ],
        },
        clinicalExaminationTable: [
          ...(isContinue ? [] : procedure.clinicalExaminations),
          ...(procedure.previousClinicalExaminations?.flatMap((item) => item.previousData) || []),
        ],
        procedureUpdateTable: [
          ...(isContinue ? [] : procedure.procedureProgresses?.map((item) => item) || []),
          ...(procedure.previousProcedureProgresses?.flatMap((item) => item.previousData) || []),
        ],
      };
    }
    return undefined;
  }, [procedure, isContinue]);

  const initialValuesDraft = useMemo<Partial<MedicalProfileFormData> | undefined>(() => {
    if (procedure) {
      return {
        medicalCondition:
          procedure.medicalConditions?.[0]?.formattedText && JSON.parse(procedure.medicalConditions[0].formattedText),
        presentIllnessHistory:
          procedure.presentIllnessHistories?.[0]?.formattedText &&
          JSON.parse(procedure.presentIllnessHistories[0].formattedText),
        systemReview:
          procedure.systemReviews?.[0]?.formattedText && JSON.parse(procedure.systemReviews[0].formattedText),

        assessment: procedure.assessments?.[0]?.formattedText && JSON.parse(procedure.assessments[0].formattedText),
        healthEducation:
          procedure.healthEducations?.[0]?.formattedText && JSON.parse(procedure.healthEducations[0].formattedText),
        icdTable: procedure.diagnoses?.[0]?.diagnoses,
        chiefComplaint:
          procedure.chiefComplains?.[0]?.formattedText && JSON.parse(procedure.chiefComplains[0].formattedText),
        comment: procedure.comments?.[0]?.formattedText && JSON.parse(procedure.comments[0].formattedText),
        otherCondition:
          procedure.otherConditions?.[0]?.formattedText && JSON.parse(procedure.otherConditions[0].formattedText),
        diagnosis: procedure.diagnoses?.[0]?.formattedText && JSON.parse(procedure.diagnoses[0].formattedText),
        plan: procedure.plans?.[0]?.formattedText && JSON.parse(procedure.plans[0].formattedText),
        procedureLocation: procedure.planLocations?.[0] && {
          template: procedure.planLocations.slice(-1)[0].template,
          notes: procedure.planLocations,
        },
        goal: procedure.goals?.[0]?.formattedText && JSON.parse(procedure.goals[0].formattedText),
        goalLocation: procedure.goalLocations?.[0] && {
          template: procedure.goalLocations.slice(-1)[0].template,
          notes: procedure.goalLocations,
        },
        procedureUpdate:
          procedure.procedureProgresses?.[0]?.formattedText &&
          JSON.parse(procedure.procedureProgresses[0].formattedText),
        clinicalExamination:
          procedure.clinicalExaminations?.[0]?.formattedText &&
          JSON.parse(procedure.clinicalExaminations[0].formattedText),
      };
    }

    return undefined;
  }, [procedure]);

  const initialValuesWithTable = useMemo<Partial<MedicalProfileFormData> | undefined>(() => {
    if (procedure) {
      return {
        medicalConditions:
          procedure.medicalConditions?.[0]?.formattedText && JSON.parse(procedure.medicalConditions[0].formattedText),
        presentIllnessHistories:
          procedure.presentIllnessHistories?.[0]?.formattedText &&
          JSON.parse(procedure.presentIllnessHistories[0].formattedText),
        systemReviews:
          procedure.systemReviews?.[0]?.formattedText && JSON.parse(procedure.systemReviews[0].formattedText),

        assessment: procedure.assessments?.[0]?.formattedText && JSON.parse(procedure.assessments[0].formattedText),
        healthEducation:
          procedure.healthEducations?.[0]?.formattedText && JSON.parse(procedure.healthEducations[0].formattedText),
        icdTable: procedure.diagnoses?.[0]?.diagnoses,
        chiefComplaint:
          procedure.chiefComplains?.[0]?.formattedText && JSON.parse(procedure.chiefComplains[0].formattedText),
        comment: procedure.comments?.[0]?.formattedText && JSON.parse(procedure.comments[0].formattedText),
        otherCondition:
          procedure.otherConditions?.[0]?.formattedText && JSON.parse(procedure.otherConditions[0].formattedText),
        diagnosis: procedure.diagnoses?.[0]?.formattedText && JSON.parse(procedure.diagnoses[0].formattedText),
        plan: procedure.plans?.[0]?.formattedText
          ? JSON.parse(procedure.plans[0].formattedText)
          : procedure.previousPlans && procedure.previousPlans[0]
          ? JSON.parse(procedure.previousPlans[0]?.previousData[0]?.formattedText)
          : undefined,
        procedureLocation:
          procedure.planLocations?.[0] && !procedure.previousPlanLocations
            ? {
                template: procedure.planLocations.slice(-1)[0].template,
                notes: procedure.planLocations,
              }
            : procedure.previousPlanLocations && {
                notes: [
                  ...procedure.previousPlanLocations.flatMap((item) => item.previousData),
                  ...(procedure.planLocations?.length > 0 ? procedure.planLocations : []),
                ],
                template: procedure.previousPlanLocations[0]?.previousData.slice(-1)[0].template,
              },
        goal: procedure.goals?.[0]?.formattedText
          ? JSON.parse(procedure.goals[0].formattedText)
          : procedure.previousGoals && procedure.previousGoals[0]
          ? JSON.parse(procedure.previousGoals[0]?.previousData[0]?.formattedText)
          : undefined,
        goalLocation:
          procedure.goalLocations?.[0] && !procedure.previousGoalLocations
            ? {
                template: procedure.goalLocations.slice(-1)[0].template,
                notes: procedure.goalLocations,
              }
            : procedure.previousGoalLocations && {
                notes: [
                  ...procedure.previousGoalLocations.flatMap((item) => item.previousData),
                  ...(procedure.goalLocations?.length > 0 ? procedure.goalLocations : []),
                ],
                template: procedure.previousGoalLocations[0]?.previousData.slice(-1)[0].template,
              },
        procedureUpdate:
          procedure.procedureProgresses?.[0]?.formattedText &&
          JSON.parse(procedure.procedureProgresses[0].formattedText),
        clinicalExamination:
          procedure.clinicalExaminations?.[0]?.formattedText &&
          JSON.parse(procedure.clinicalExaminations[0].formattedText),
        assessmentTable: [
          ...(isContinueOrDraft ? [] : procedure.assessments),
          ...(procedure.previousAssessments?.flatMap((item) => item.previousData) || []),
        ],
        healthEducationTable: [
          ...(isContinueOrDraft ? [] : procedure.healthEducations),
          ...(procedure.previousHealthEducations?.flatMap((item) => item.previousData) || []),
        ],
        medicalConditionTable: [
          ...(isContinueOrDraft ? [] : procedure.medicalConditions),
          ...(procedure.previousMedicalConditions?.flatMap((item) => item.previousData) || []),
        ],
        otherConditionTable: [
          ...(isContinueOrDraft ? [] : procedure.otherConditions),
          ...(procedure.previousOtherConditions?.flatMap((item) => item.previousData) || []),
        ],
        presentIllnessHistoryTable: [
          ...(isContinueOrDraft ? [] : procedure.presentIllnessHistories),
          ...(procedure.previousPresentIllnessHistories?.flatMap((item) => item.previousData) || []),
        ],
        systemReviewTable: [
          ...(isContinueOrDraft ? [] : procedure.systemReviews),
          ...(procedure.previousSystemReviews?.flatMap((item) => item.previousData) || []),
        ],
        chiefComplaintTable: [
          ...(isContinueOrDraft ? [] : procedure.chiefComplains),
          ...(procedure.previousChiefComplains?.flatMap((item) => item.previousData) || []),
        ],
        commentTable: [
          ...(isContinueOrDraft ? [] : procedure.comments),
          ...(procedure.previousComments?.flatMap((item) => item.previousData) || []),
        ],
        diagnosisTable: [
          ...(isContinueOrDraft ? [] : procedure.diagnoses),
          ...(procedure.previousDiagnoses?.flatMap((item) => item.previousData) || []),
        ],
        clinicalExaminationTable: [
          ...(isContinueOrDraft ? [] : procedure.clinicalExaminations),
          ...(procedure.previousClinicalExaminations?.flatMap((item) => item.previousData) || []),
        ],
        procedureUpdateTable: [
          ...(isContinueOrDraft ? [] : procedure.procedureProgresses?.map((item) => item) || []),
          ...(procedure.previousProcedureProgresses?.flatMap((item) => item.previousData) || []),
        ],
      };
    }

    return undefined;
  }, [procedure, isContinueOrDraft]);

  return {
    initialValues,
    initialValuesDraft,
    initialValuesVisit,
    initialValuesWithTable,
  };
};
