import { BillingItemSourceType } from 'app/types/BillingItem';
import { ClassBillingItemsGroup } from 'app/types/InsurancePolicies';
import { Option } from 'app/types/Option';
import { AttachmentType } from 'app/types/PatientTreatment';
import { RemoteFile } from 'app/types/RemoteFile';
import { AppointmentOption } from 'app/features/doctorAssessment/components/billingSection/forms/createInsuranceRequest/types';

export enum InsuranceApprovalsTabs {
  Approvals = 'approvals',
  Referrals = 'referrals',
}

export interface InsuranceApprovalPatientOption extends Option {
  details: {
    internalFileNumber: string | null;
    clinicId: string;
  };
}

export interface InsuranceApprovalPractitionerOption extends Option {
  details: {
    clinicId: string;
    doctorTitle: Option;
  };
}

export interface InsuranceReferral {
  appointment: AppointmentOption;
  approvalAmount: string;
  branch: Option;
  clinicId: string;
  expiryDate: string;
  hasMergePatientsBadge: boolean;
  id: string;
  insuranceCompany: Option;
  items: InsuranceApprovalItem[];
  patient: InsuranceApprovalPatientOption;
  practitioner: InsuranceApprovalPractitionerOption;
  referenceNumber: string;
  speciality: Option;
  status: Option<InsuranceReferralStatus>;
  submissionClinicyId: string;
  submissionDate: string;
}

export interface InsuranceApprovalRequest {
  appointmentNumber: string | null;
  approvalAmount: string;
  branch: Option;
  date: string;
  expiryDate: string | null;
  hasMergePatientsBadge: boolean;
  id: string;
  insuranceApprovalResponseId: string | null;
  insuranceCompany: Option;
  isPendingInvoiceRequestExists: boolean;
  items: InsuranceApprovalItem[];
  patient: InsuranceApprovalPatientOption;
  practitioner: InsuranceApprovalPractitionerOption;
  // [i]: 'Approval Number' renamed to 'Reference Number' for unification purposes
  referenceNumber: string | null;
  remark: string | null;
  responseStatus: Option<InsuranceApprovalStatus> | null;
  requestNumber: string;
  speciality: Option;
  status: Option<InsuranceApprovalStatus>;
}

export interface InsuranceApprovalRequestAttachment {
  id: string;
  attachmentType: Option<AttachmentType>;
  otherAttachmentType: string | null;
  attachmentName: string;
  additionalComment: string;
  files: RemoteFile[] | null;
}

export interface DetailedInsuranceApproval extends InsuranceApprovalRequest {
  attachments: InsuranceApprovalRequestAttachment[];
  patientInsurancePolicyNumber: string | null;
  patientInsuranceMembershipNumber: string | null;
  hasMergePatientsBadge: boolean;
}

export interface DetailedInsuranceReferral extends InsuranceReferral {
  internalNotes: string | undefined;
  files: RemoteFile[] | null;
  hasMergePatientsBadge: boolean;
  remarks: string | undefined;
}

// [i]: Billing item inside Approval/Referral request
export interface InsuranceApprovalItem {
  performerBillingItemId: string;
  billingItemSourceType: Option<BillingItemSourceType>;
  item: Option;
  quantity: number;
  code: string | null;
  cost: number;
  approvedAmount: number;
  pricePerItem: number;
}

export enum InsuranceApprovalStatus {
  NotSent = '0',
  Sent = '1',
  Accepted = '2',
  PartiallyApproved = '3',
  Rejected = '4',
  SecondOpinion = '5',
  MoreDetails = '6',
  CanceledByPatient = '7',
}

export enum InsuranceReferralStatus {
  PendingUse = '0',
  PartiallyUsed = '1',
  Accepted = '2',
  Canceled = '3',
}

export enum InsuranceApprovalDateType {
  RequestDate = '0',
  ExpiryDate = '1',
}

export enum InsuranceReferralDateType {
  SubmissionDate = '0',
  ExpiryDate = '1',
}

export type InsuranceApprovalAction = {
  id: string;
  action: string;
  actionTaker: string;
  actionTarget: Option<InsuranceApprovalsActionTarget>;
  date: string;
};

export type InsuranceReferralAction = {
  id: string;
  action: string;
  actionTaker: string;
  date: string;
};

export enum InsuranceApprovalsActionTarget {
  ApprovalRequest = '0',
  ApprovalResponse = '1',
}

export type InsuranceApprovalsPageAction = {
  id: string;
  clinicyId: string;
  action: string;
  actionTaker: string;
  date: string;
  actionTarget: Option<InsuranceApprovalsActionTarget>;
  itemType: Option<ClassBillingItemsGroup>;
  className: string;
};

export type InsuranceReferralsPageAction = {
  id: string;
  action: string;
  actionTaker: string;
  date: string;
};
