import React from 'react';
import { IconMenu2 } from '@tabler/icons-react';
import { Col, Row } from 'reactstrap';
import { useMenu } from 'app/helpers';
import { Box, LanguageSelector } from 'app/components';
import { BookAppointment } from 'app/features/bookAppointment/bookAppointment';
import { BookAppointmentTrigger } from './bookAppointmentTrigger/bookAppointmentTrigger';
import { GeneralNotifications } from './generalNotifications/generalNotifications';
import { UserDropdown } from './userDropdown/userDropdown';
import './header.scss';

export const Header = () => {
  const { expandMenu } = useMenu();

  return (
    <div className="header">
      <Box className="header__content">
        <Row className="h-100 flex-nowrap align-items-center">
          <Col xs={4} className="align-items-center d-flex">
            <div className="me-3 header__menuTrigger" onClick={expandMenu}>
              <IconMenu2 size={24} strokeWidth={1.8} />
            </div>
            <BookAppointmentTrigger />
          </Col>
          <Col className="d-flex justify-content-end">
            <LanguageSelector className="header__item" />
            <GeneralNotifications className="header__item" />
            <UserDropdown />
          </Col>
        </Row>
      </Box>
      <BookAppointment />
    </div>
  );
};
