import { Col, Row } from 'reactstrap';
import { useAppIntl } from 'app/helpers';
import { Appointment } from 'app/types';

interface Props {
  appointment: Appointment;
}

export const Location = ({ appointment }: Props) => {
  const { formatMessage } = useAppIntl();

  return (
    <Row tag="dl" className="mb-3">
      <Col md={4} tag="dt">
        {formatMessage({ id: 'CONFIRMATION.LOCATION' })}:
      </Col>
      <Col md={8} tag="dd">
        <a
          className="align-middle"
          href={`https://maps.google.com/?q=${appointment.branchLocation.lat},${appointment.branchLocation.lng}`}
          rel="noreferrer noopener"
          target="_blank"
        >
          <div className="d-inline-flex align-items-center">
            <span>{appointment.branchAddress || formatMessage({ id: 'CORE.LABEL.SEE-ON-MAP' })}</span>
          </div>
        </a>
      </Col>
    </Row>
  );
};
