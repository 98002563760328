import { ReactNode } from 'react';
import classNames from 'classnames';
import BootstrapTable, {
  BootstrapTableProps,
  ColumnDescription,
  ExpandRowProps,
  PaginationOptions,
  RowEventHandlerProps,
} from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { NoData } from 'app/components/bootstrapTable/components/noData/noData';
import { useCustomColumns } from 'app/components/bootstrapTable/helpers/useCustomColumns';
import { StickyPagination } from 'app/components/stickyPagination/stickyPagination';
import { DEFAULT_PAGINATION_PARAMS, Loader } from 'app/shared';

interface Props {
  classes?: string;
  columns: ColumnDescription[];
  data: any[];
  expandRow?: ExpandRowProps<any>;
  id: string;
  keyField: string;
  loading: boolean;
  noDataComponent?: ReactNode;
  pagination?: PaginationOptions;
  rowClasses?: BootstrapTableProps['rowClasses'];
  rowEvents?: RowEventHandlerProps;
  selectRow?: BootstrapTableProps['selectRow'];
  showRowId?: boolean;
  wrapperClasses?: string;
}

export const LocalBootstrapTable = ({
  classes,
  columns,
  data,
  expandRow,
  id,
  keyField,
  loading,
  noDataComponent,
  pagination,
  rowClasses,
  selectRow,
  rowEvents,
  showRowId = true,
  wrapperClasses,
}: Props) => {
  const hasItems = data.length > 0;
  const customColumns = useCustomColumns({
    columns,
    pageNumber: pagination?.page,
    pageSize: pagination?.sizePerPage,
    showRowId,
  });

  return (
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        hideSizePerPage: true,
        ...(!pagination && { sizePerPage: 1000 }),
        ...pagination,
      })}
    >
      {({ paginationProps, paginationTableProps }) => (
        <>
          <BootstrapTable
            {...paginationTableProps}
            bordered={false}
            classes={classes}
            columns={customColumns}
            data={data}
            expandRow={expandRow}
            id={id}
            keyField={keyField}
            rowClasses={rowClasses}
            rowEvents={rowEvents}
            selectRow={selectRow}
            wrapperClasses={classNames('table-responsive', wrapperClasses)}
          />

          {/* Initial loader */}
          {loading && !hasItems && <Loader />}

          {/* No data message */}
          {!loading && !hasItems ? noDataComponent ? noDataComponent : <NoData /> : null}

          {pagination && paginationProps && (
            <StickyPagination
              loading={loading}
              onPageChange={paginationProps.onPageChange}
              onSizePerPageChange={paginationProps.onSizePerPageChange}
              page={paginationProps.page || 1}
              sizePerPage={paginationProps.sizePerPage || DEFAULT_PAGINATION_PARAMS.sizePerPage}
              totalSize={paginationProps.totalSize || 1}
            />
          )}
        </>
      )}
    </PaginationProvider>
  );
};
