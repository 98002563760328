import React from 'react';
import classNames from 'classnames';
import { Col, Row } from 'reactstrap';
import { useAppSelector } from 'app/helpers';
import { Box } from 'app/components';
import { Loader } from 'app/shared';
import { DoctorData } from 'app/redux/book-appointment/types';
import { DoctorFormatter } from 'app/features/bookAppointment/bookAppointmentWizard/shared';
import './doctorList.scss';

interface Props {
  selectedDoctor: DoctorData | null;
  onDoctorChange: (doctor: DoctorData) => void;
}

export const DoctorList = ({ onDoctorChange, selectedDoctor }: Props) => {
  const { data, loading } = useAppSelector((state) => state.bookAppointment.doctors);

  return (
    <>
      {loading && <Loader />}

      {!loading && (
        <Row className="doctorList fadeIn" xs={1}>
          {data.map((doctor: DoctorData, i: number) => (
            <Col key={i}>
              <Box
                className={classNames('doctorList__option mb-2 pb-1', { selected: selectedDoctor?.id === doctor.id })}
                onClick={() => onDoctorChange(doctor)}
              >
                <DoctorFormatter targetId={`info-doctor-tooltip-${i}`} doctor={doctor} />
              </Box>
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};
