import React, { useCallback, useMemo } from 'react';
import { ColumnDescription, ColumnFormatter } from 'react-bootstrap-table-next';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import { formattedPeriod, useAppDispatch, useAppSelector, useCurrentUser } from 'app/helpers';
import {
  resetAppointment,
  resetPatient,
  setPatient,
  setPreselectedPatient,
} from 'app/redux/appointment/appointment.actions';
import { selectWaitingSlot } from 'app/redux/calendar/calendar.actions';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { CalendarWorkingTime } from 'app/types';
import {
  checkAvailableSlots,
  isPossibleToCreatePhantomWaiting,
  isPossibleToCreateWaiting,
} from 'app/features/calendar/helpers';

const availableAppointmentsFormatter: ColumnFormatter<CalendarWorkingTime, { formatMessage: Function }> = (
  _,
  row,
  rowIndex,
  formatExtraData,
) => {
  if (checkAvailableSlots(row)) {
    return formatExtraData?.formatMessage({ id: 'APPOINTMENTS.TEXT.FREE-SLOTS-AVAILABLE' });
  }

  if (row.waitingAppointmentsCount >= row.waitingAppointmentsLimit) {
    return '0';
  }

  return `${row.waitingAppointmentsLimit - row.waitingAppointmentsCount}`;
};

const actionsFormatter: ColumnFormatter<
  CalendarWorkingTime,
  {
    allowedForBookAppointments?: boolean;
    addWaiting: (workingTime: CalendarWorkingTime, isPhantom: boolean) => void;
    formatMessage: Function;
  }
> = (_, row, rowIndex, formatExtraData) => {
  const isAddPhantomWaitingDisabled =
    !isPossibleToCreatePhantomWaiting(row) || !formatExtraData?.allowedForBookAppointments;
  const isAddWaitingDisabled = !isPossibleToCreateWaiting(row) || !formatExtraData?.allowedForBookAppointments;

  return (
    <>
      <Button
        className="me-2 mb-2 mb-xl-0"
        color="danger"
        disabled={isAddPhantomWaitingDisabled}
        onClick={() => formatExtraData?.addWaiting(row, true)}
        outline
        size="sm"
      >
        {formatExtraData?.formatMessage({ id: 'APPOINTMENTS.TEXT.ADD-PHANTOM-WAITING' })}
      </Button>
      <Button
        color="primary"
        disabled={isAddWaitingDisabled}
        onClick={() => formatExtraData?.addWaiting(row, false)}
        outline
        size="sm"
      >
        {formatExtraData?.formatMessage({ id: 'APPOINTMENTS.TEXT.ADD-WAITING-APPOINTMENT' })}
      </Button>
    </>
  );
};

export const useColumns = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const {
    currentUser: { allowedForBookAppointments },
  } = useCurrentUser();
  const selectedWaitingSlot = useAppSelector((state) => state.calendar.selectedWaitingSlot);
  const patient = useAppSelector((state) => state.bookAppointment.selectedPatient);

  const addWaiting = useCallback(
    (workingTime: CalendarWorkingTime, isPhantom: boolean) => {
      if (selectedWaitingSlot) {
        dispatch(resetPatient());
        dispatch(resetAppointment());
        dispatch(
          selectWaitingSlot({
            ...selectedWaitingSlot,
            isPhantom,
            workingTimeId: workingTime.workingTimeId,
            workingTimeStart: workingTime.workingTimeStart,
            workingTimeEnd: workingTime.workingTimeEnd,
          }),
        );
        if (patient) {
          dispatch(setPreselectedPatient(patient));
          dispatch(setPatient(patient));
        }
        dispatch(toggleModal('addWaitingAppointment'));
      }
    },
    [dispatch, selectedWaitingSlot, patient],
  );

  return useMemo(
    (): ColumnDescription<CalendarWorkingTime>[] => [
      {
        dataField: 'time',
        classes: 'text-nowrap',
        text: formatMessage({ id: 'DOCTORS.TEXT.WORKING-HOURS' }),
        formatter: (_, row) => formattedPeriod(row.workingTimeStart, row.workingTimeEnd),
      },
      {
        dataField: 'availableAppointments',
        text: formatMessage({ id: 'APPOINTMENTS.TEXT.AVAILABLE-WAITING-APPOINTMENTS' }),
        formatter: availableAppointmentsFormatter,
        formatExtraData: { formatMessage },
      },
      {
        dataField: 'actions',
        text: formatMessage({ id: 'CORE.TABLE.ACTIONS' }),
        formatter: actionsFormatter,
        formatExtraData: { allowedForBookAppointments, addWaiting, formatMessage },
      },
    ],
    [addWaiting, allowedForBookAppointments, formatMessage],
  );
};
