import draftToHtml from 'draftjs-to-html';
import { getFormInitialValues } from 'redux-form';
import { useAppSelector } from 'app/helpers';
import { FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM } from 'app/shared';

export const useTableData = () => {
  const initialValues = useAppSelector((state) =>
    (getFormInitialValues(FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM) as any)(state),
  );
  const values = useAppSelector((state) => state.form[FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM]?.values);

  const chiefComplaintData = values?.chiefComplaint?.blocks[0]?.text
    ? [
        {
          text: draftToHtml(values?.chiefComplaint),
        },
        ...initialValues.chiefComplaintTable,
      ]
    : initialValues.chiefComplaintTable;
  const presentIllnessHistoryData = values?.presentIllnessHistory?.blocks[0]?.text
    ? [
        {
          text: draftToHtml(values?.presentIllnessHistory),
        },
        ...initialValues.presentIllnessHistoryTable,
      ]
    : initialValues.presentIllnessHistoryTable;
  const medicalConditionData = values?.presentIllnessHistory?.blocks[0]?.text
    ? [
        {
          text: draftToHtml(values?.medicalCondition),
        },
        ...initialValues.medicalConditionTable,
      ]
    : initialValues.medicalConditionTable;

  const systemReviewData = values?.systemReview?.blocks[0]?.text
    ? [
        {
          text: draftToHtml(values?.systemReview),
        },
        ...initialValues.systemReviewTable,
      ]
    : initialValues.systemReviewTable;

  const clinicalExaminationData = values?.clinicalExamination?.blocks[0]?.text
    ? [
        {
          text: draftToHtml(values?.clinicalExamination),
        },
        ...initialValues.clinicalExaminationTable,
      ]
    : initialValues.clinicalExaminationTable;
  const assessmentsData = values?.clinicalExamination?.blocks[0]?.text
    ? [
        {
          text: draftToHtml(values?.assessment),
        },
        ...initialValues.assessmentTable,
      ]
    : initialValues.assessmentTable;

  const conditionsData = values?.condition?.blocks[0]?.text
    ? [
        {
          text: draftToHtml(values?.condition),
        },
        ...initialValues.conditionsTable,
      ]
    : initialValues.conditionsTable;

  const procedureUpdateData = values?.procedureUpdate?.blocks[0]?.text
    ? [
        {
          text: draftToHtml(values?.procedureUpdate),
        },
        ...initialValues.procedureUpdateTable,
      ]
    : initialValues.procedureUpdateTable;
  const healthEducationData = values?.healthEducation?.blocks[0]?.text
    ? [
        {
          text: draftToHtml(values?.healthEducation),
        },
        ...initialValues.healthEducationTable,
      ]
    : initialValues.healthEducationTable;

  return {
    assessmentsData,
    clinicalExaminationData,
    chiefComplaintData,
    conditionsData,
    healthEducationData,
    presentIllnessHistoryData,
    procedureUpdateData,
    systemReviewData,
    medicalConditionData,
  };
};
