import { put, select } from 'redux-saga/effects';
import { fetchWeekCalendar } from 'app/redux/remoteData/remoteData.actions';
import { RootState } from 'app/types';
import { RefreshCalendarEvent } from 'app/features/calendar/helpers';

export function* wsUpdateWeekView(calendarEvent: RefreshCalendarEvent) {
  const {
    remoteData: {
      weekCalendar: { data, params },
    },
  }: RootState = yield select();

  const hasDoctor = data.some((i) => i.doctor.id === calendarEvent.DoctorId);

  if (params && params.branchId && params.doctorId && params.startDate && params.endDate && hasDoctor) {
    yield put<any>(
      fetchWeekCalendar(
        {
          ...params,
          branchId: params.branchId,
          doctorId: params.doctorId,
          startDate: params.startDate,
          endDate: params.endDate,
          subSpecialityId: params.subSpecialityId,
        },
        { isSilent: true },
      ),
    );
  }
}
