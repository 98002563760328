import { getTotalInvoicesValue } from 'app/redux/patientPayments/helpers/getTotalInvoicesValue';
import { InvoiceWithCalculatedRemaining } from 'app/redux/patientPayments/types';

interface InitializeSettleInvoicePayments {
  invoices: InvoiceWithCalculatedRemaining[];
  remainingCredits: number;
}

export const initializeSettleInvoicePayments = ({ invoices, remainingCredits }: InitializeSettleInvoicePayments) => {
  const calculatedInvoices = invoices.map((invoice) => ({
    ...invoice,
    calculatedRemaining: invoice.remaining,
  }));

  return {
    invoices: calculatedInvoices,
    actualInvoices: invoices,
    invoiceValues: Object.fromEntries(invoices.map((o) => [o.id, o.remaining])),
    debtAmount: getTotalInvoicesValue(invoices),
    remainingCredits,
  };
};
