import { checkIsTemporaryId } from 'app/helpers';
import { Note, NoteStatus, Position, Template } from 'app/components/locationSelector/procedureLocation/types';
import { MedicalProfileFormData } from 'app/features/doctorAssessment/components/medicalProfileSection/components/practitionerAssessment/practitionerAssessment';
import { getPlainText } from 'app/features/doctorAssessment/helpers/getPlainText';
import { getProcedureParams } from 'app/features/doctorAssessment/helpers/getProcedureParams';

export interface ProcedureParams {
  text: string;
  formattedText: string;
}

export interface DiagnoseShortInformation {
  icd10Code: string;
  isExcluded: boolean;
}

export interface DiagnoseParams {
  text: string | null;
  formattedText: string | null;
  diagnoses?: DiagnoseShortInformation[];
}

export interface LocationParams {
  locationTemplate?: Template;
  id: string | null;
  title?: string;
  description?: string;
  element?: string;
  status: NoteStatus | null;
  cameraPosition: Position;
  lookAt: Position;
  billingItemsIds?: string[];
}

export interface MedicalProfileParams {
  assessment: ProcedureParams | null;
  chiefComplain: ProcedureParams | null;
  clinicalExamination: ProcedureParams | null;
  comment: ProcedureParams | null;
  diagnose: DiagnoseParams | null;
  goal: ProcedureParams | null;
  goalLocations?: LocationParams[] | null;
  healthEducation: ProcedureParams | null;
  medicalCondition: ProcedureParams | null;
  otherCondition: ProcedureParams | null;
  plan: ProcedureParams | null;
  planLocations?: LocationParams[] | null;
  presentIllnessHistory: ProcedureParams | null;
  procedureProgress: ProcedureParams | null;
  systemReview: ProcedureParams | null;
}

const getLocationParams = (notes: Note[]): LocationParams[] =>
  notes.map((item) => ({
    locationTemplate: item.template,
    id: checkIsTemporaryId(item.id) ? null : item.id,
    title: item.title,
    description: item.description,
    element: item.element,
    status: item.status,
    cameraPosition: item.cameraPosition,
    lookAt: item.lookAt,
    billingItemIds: item.billingItems?.map((item) => item.value),
  }));

export const medicalProfileValuesAdapter = (values: MedicalProfileFormData | undefined): MedicalProfileParams => {
  return {
    assessment: getProcedureParams(values?.assessment),
    chiefComplain: getProcedureParams(values?.chiefComplaint),
    clinicalExamination: getProcedureParams(values?.clinicalExamination),
    comment: getProcedureParams(values?.comment),
    goal: getProcedureParams(values?.goal),
    goalLocations: values?.goalLocation?.notes ? getLocationParams(values.goalLocation.notes) : null,
    healthEducation: getProcedureParams(values?.healthEducation),
    medicalCondition: getProcedureParams(values?.medicalCondition),
    otherCondition: getProcedureParams(values?.otherCondition),
    plan: getProcedureParams(values?.plan),
    planLocations: values?.procedureLocation?.notes ? getLocationParams(values.procedureLocation.notes) : null,
    presentIllnessHistory: getProcedureParams(values?.presentIllnessHistory),
    procedureProgress: getProcedureParams(values?.procedureUpdate),
    systemReview: getProcedureParams(values?.systemReview),
    diagnose: {
      text:
        values?.diagnosis?.blocks && !!getPlainText(values?.diagnosis?.blocks)
          ? getPlainText(values.diagnosis.blocks)
          : null,
      formattedText:
        values?.diagnosis?.blocks && !!getPlainText(values?.diagnosis?.blocks)
          ? JSON.stringify(values.diagnosis)
          : null,
      diagnoses: values?.icdTable?.map((item) => ({
        icd10Code: item.icd10Code,
        isExcluded: item.isExcluded,
      })),
    },
  };
};
