import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { Field, touch } from 'redux-form';
import { useAppDispatch, useAppIntl, useAppSelector, useTimeOptions } from 'app/helpers';
import { Box } from 'app/components';
import { AsyncSelectComponent, SelectComponent } from 'app/shared';
import { SelectedDay } from 'app/redux/calendar/types';
import { CalendarItem, Option, RemoteDataParams } from 'app/types';
import { NoDataMessage } from 'app/features/bookAppointment/bookAppointmentWizard/shared';
import { getCustomTimeSlotOptions } from 'app/features/bookAppointment/bookAppointmentWizard/steps/serviceWithDetailsStep/helpers/getCustomTimeSlotOptions';
import { formConfig } from 'app/features/bookAppointment/shared';
import { DoctorWorkingDayList } from './doctorWorkingDayList/doctorWorkingDayList';
import { DoctorWorkingDaySlots } from './doctorWorkingDaySlots/doctorWorkingDaySlots';
import { useFilterHandlers } from './helpers/useFilterHandlers';
import { ServicesList } from './servicesList/servicesList';

interface Props {
  onCustomizeAppointmentSlot: (option?: Option) => void;
  onDoctorFilterChange: (e: ChangeEvent, option?: Option) => void;
  onFetchCalendarItems: (params: RemoteDataParams) => void;
  onFetchDoctorFilterOptions: (params: RemoteDataParams) => void;
  onSelectDate: (day: SelectedDay) => void;
  onServiceChange: (option: Option) => void;
  onTimeChange: (timeChange: { value?: string; name: string }) => void;
  selectedDayItem: CalendarItem | null;
  selectedService: Option | null;
  subSpeciality: Option | null;
}

export const ServiceWithDetailsStep = ({
  onCustomizeAppointmentSlot,
  onDoctorFilterChange,
  onFetchCalendarItems,
  onFetchDoctorFilterOptions,
  onSelectDate,
  onServiceChange,
  onTimeChange,
  selectedDayItem,
  selectedService,
  subSpeciality,
}: Props) => {
  const dispatch = useAppDispatch();
  const [filteredServices, setFilteredServices] = useState<Option[]>([]);
  const { formatMessage } = useAppIntl();

  const { data: services, loading: loadingServices } = useAppSelector((state) => state.bookAppointment.products);
  const selectedDoctor = useAppSelector((state) => state.bookAppointment.selectedDoctor);
  const timeFromValue = useAppSelector((state) => state.form[formConfig.form]?.values?.timeFrom?.value);
  const customSlotOptions = useMemo(() => getCustomTimeSlotOptions(), []);
  const timeOptions = useTimeOptions();
  const timeToOptions = useTimeOptions(timeFromValue);

  const { filterServices } = useFilterHandlers({ services, setFilteredServices });

  const handleCustomizeAppointmentSlot = useCallback(
    (e: ChangeEvent, o?: Option) => {
      onCustomizeAppointmentSlot(o);
    },
    [onCustomizeAppointmentSlot],
  );

  const handleTimeChange = useCallback(
    (event: ChangeEvent<any>, value?: Option, prevValue?: Option, name?: string) => {
      if (name) {
        onTimeChange({ value: value?.value, name: name });
      }
      dispatch(touch(formConfig.form, 'timeTo'));
    },
    [onTimeChange, dispatch],
  );

  useEffect(() => {
    setFilteredServices(services);
  }, [services, setFilteredServices]);

  return (
    <>
      {!subSpeciality && <div>Select sub-speciality first</div>}
      {subSpeciality && (
        <Row>
          <Col lg={5} xl={3}>
            <Box className="h-100">
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="serviceFilter">
                      {formatMessage({ id: 'BOOK-APPOINTMENT.LABEL.SEARCH-FOR-SERVICE' })}
                    </Label>
                    <Input id="serviceFilter" type="text" onChange={filterServices} />
                  </FormGroup>
                </Col>
              </Row>

              <ServicesList
                selectedService={selectedService}
                onServiceChange={onServiceChange}
                serviceOptions={filteredServices}
                loading={loadingServices}
              />
              {!loadingServices && filteredServices.length === 0 && <NoDataMessage />}
            </Box>
          </Col>
          <Col lg={7} xl={9} className="mt-md-3 mt-lg-0">
            <Box className="bookAppointment__lightBorder">
              {selectedService && !selectedDoctor && (
                <Field
                  name="doctor"
                  component={AsyncSelectComponent}
                  isClearable
                  fetchOptions={onFetchDoctorFilterOptions}
                  label={formatMessage({ id: 'CORE.LABEL.DOCTOR' })}
                  noOptionsMessage={formatMessage({ id: 'CORE.TEXT.NO-OPTIONS' })}
                  placeholder={formatMessage({ id: 'CORE.LABEL.DOCTOR' })}
                  cacheUniqs={[selectedService?.value]}
                  onChange={onDoctorFilterChange}
                />
              )}
              <Row>
                <Col xl={6}>
                  <Field
                    name="customAppointmentSlots"
                    component={SelectComponent}
                    isClearable
                    label={formatMessage({ id: 'CORE.LABEL.CUSTOMIZE-APPOINTMENT-SLOT' })}
                    noOptionsMessage={formatMessage({ id: 'CORE.TEXT.NO-OPTIONS' })}
                    options={customSlotOptions}
                    placeholder={formatMessage({ id: 'CORE.LABEL.SERVICE-DURATION' })}
                    onChange={handleCustomizeAppointmentSlot}
                  />
                </Col>
                <Col>
                  <Field
                    name="timeFrom"
                    component={SelectComponent}
                    isSearchable
                    isClearable
                    label={formatMessage({ id: 'CORE.LABEL.FROM' })}
                    noOptionsMessage={formatMessage({ id: 'CORE.TEXT.NO-OPTIONS' })}
                    options={timeOptions}
                    placeholder={formatMessage({ id: 'CORE.PLACEHOLDER.SELECT-TIME' })}
                    onChange={handleTimeChange}
                  />
                </Col>
                <Col>
                  <Field
                    name="timeTo"
                    component={SelectComponent}
                    isSearchable
                    isClearable
                    label={formatMessage({ id: 'CORE.LABEL.TO' })}
                    noOptionsMessage={formatMessage({ id: 'CORE.TEXT.NO-OPTIONS' })}
                    options={timeToOptions}
                    placeholder={formatMessage({ id: 'CORE.PLACEHOLDER.SELECT-TIME' })}
                    onChange={handleTimeChange}
                  />
                </Col>
              </Row>
            </Box>

            <div>
              {!selectedService && (
                <NoDataMessage className="mb-0" customMessageId="BOOK-APPOINTMENT.TEXT.SELECT-SERVICE-FIRST" />
              )}

              {selectedService && (
                <>
                  {!selectedDayItem ? (
                    <DoctorWorkingDayList onSelectDate={onSelectDate} onFetchCalendarItems={onFetchCalendarItems} />
                  ) : (
                    <DoctorWorkingDaySlots item={selectedDayItem} onSelectDate={onSelectDate} />
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};
