import { useMemo } from 'react';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import { getTranslation } from 'app/helpers';
import { DayCalendar, DayCalendarBusyTime, DayCalendarWorkingTime, Locale } from 'app/types';
import { CalendarDayResource } from 'app/features/calendar/types';

export const useResources = (data: DayCalendar[], locale: Locale, isWeekView?: boolean) => {
  return useMemo((): CalendarDayResource[] | undefined => {
    let customBusyTimes: DayCalendarBusyTime[] = [];
    let customWorkingTimes: DayCalendarWorkingTime[] = [];

    if (!data.length) {
      return;
    }

    if (isWeekView) {
      customBusyTimes = uniqBy(data.map((d) => d.busyTimes).flat(), 'id');
      // Working Times excluding those which ended the day before
      customWorkingTimes = data
        .map((d) => d.workingTimes)
        .flat()
        .filter((wt) => moment().startOf('day').isBefore(wt.end));
    }

    const resources = data.map(
      (dayCalendar): CalendarDayResource => ({
        resourceBranchId: dayCalendar.branch.value,
        resourceBusyTimes: !!customBusyTimes.length ? customBusyTimes : dayCalendar.busyTimes,
        resourceDescription: getTranslation(dayCalendar.doctor.speciality, locale),
        resourceId: dayCalendar.doctor.id,
        resourceImage: dayCalendar.doctor.image,
        resourceTitle: getTranslation(dayCalendar.doctor.name, locale),
        resourceWorkingTimes: !!customWorkingTimes.length ? customWorkingTimes : dayCalendar.workingTimes,
      }),
    );

    return isWeekView ? uniqBy(resources, 'resourceId') : resources;
  }, [data, isWeekView, locale]);
};
