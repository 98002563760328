import React from 'react';
import { Col, Form, Row } from 'reactstrap';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { useAppIntl, useAppSelector } from 'app/helpers';
import { CheckboxComponent, FORMS_ASSESSMENT_PRINT_MODAL_FORM } from 'app/shared';
import { PatientMedicalIllnessTable, PreviousSurgeryTable } from 'app/types';
import { MedicalHistoryField } from 'app/features/doctorAssessment/components/medicalProfileSection/components/modals/printModal/helpers/medicalHistoryField';
import { SurgicalHistoryField } from 'app/features/doctorAssessment/components/medicalProfileSection/components/modals/printModal/helpers/surgicalHistoryField';

const spacingProps = { className: 'mb-2' };

interface Props {
  canPrintReferrals: boolean;
}

const FormComponent = ({ handleSubmit, canPrintReferrals }: InjectedFormProps<PrintModalFormData, Props> & Props) => {
  const { formatMessage } = useAppIntl();
  const isPastMedicalHistorySelected = !!useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_PRINT_MODAL_FORM]?.values?.pastMedicalHistory,
  );
  const isSurgicalHistorySelected = !!useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_PRINT_MODAL_FORM]?.values?.surgicalHistory,
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Row xs={2} lg={3}>
        <Col {...spacingProps}>
          <Field
            name="vitals"
            component={CheckboxComponent}
            label={formatMessage({ id: 'TREATMENT-PLAN-PAGE.TEXT.VITALS' })}
          />
        </Col>
        <Col {...spacingProps}>
          <Field
            name="healthEducation"
            component={CheckboxComponent}
            label={formatMessage({ id: 'TREATMENT-PLAN.LABEL.HEALTH-EDUCATION' })}
          />
        </Col>
        <Col {...spacingProps}>
          <Field
            name="pastMedicalHistory"
            component={CheckboxComponent}
            label={formatMessage({ id: 'TREATMENT-PLAN.LABEL.PAST-MEDICAL-HISTORY' })}
          />
        </Col>
        <Col {...spacingProps}>
          <Field
            name="surgicalHistory"
            component={CheckboxComponent}
            label={formatMessage({ id: 'TREATMENT-PLAN.LABEL.SURGICAL-HISTORY' })}
          />
        </Col>
        <Col {...spacingProps}>
          <Field
            name="plan"
            component={CheckboxComponent}
            label={formatMessage({ id: 'TREATMENT-PLAN.LABEL.PLAN-SHORT-TERM' })}
          />
        </Col>
        <Col {...spacingProps}>
          <Field name="goal" component={CheckboxComponent} label={formatMessage({ id: 'TREATMENT-PLAN.LABEL.GOAL' })} />
        </Col>
        {canPrintReferrals && (
          <Col {...spacingProps}>
            <Field
              name="referrals"
              component={CheckboxComponent}
              label={formatMessage({ id: 'CORE.TEXT.REFERRALS' })}
            />
          </Col>
        )}
      </Row>
      {isPastMedicalHistorySelected && (
        <Row {...spacingProps}>
          <hr className="mt-2" />
          <h4>{formatMessage({ id: 'TREATMENT-PLAN.LABEL.PAST-MEDICAL-HISTORY' })}</h4>
          <FieldArray name="pastMedicalHistoryTable" component={MedicalHistoryField} />
        </Row>
      )}
      {isSurgicalHistorySelected && (
        <Row {...spacingProps}>
          <hr className="mt-2" />
          <h4>{formatMessage({ id: 'TREATMENT-PLAN.LABEL.SURGICAL-HISTORY' })}</h4>
          <FieldArray name="surgicalHistoryTable" component={SurgicalHistoryField} />
        </Row>
      )}
    </Form>
  );
};

export const PrintModalBody = reduxForm<PrintModalFormData, Props>({
  form: FORMS_ASSESSMENT_PRINT_MODAL_FORM,
})(FormComponent);

export interface PrintModalFormData {
  vitals: boolean;
  referrals: boolean;
  healthEducation: boolean;
  pastMedicalHistory: boolean;
  surgicalHistory: boolean;
  plan: boolean;
  goal: boolean;
  pastMedicalHistoryTable: PatientMedicalIllnessTable[];
  surgicalHistoryTable: PreviousSurgeryTable[];
}
