import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector, useCurrentUser } from 'app/helpers';
import { fetchClinic } from 'app/redux/clinic/clinic.actions';
import { MedicalInstitution } from 'app/types';

type UseRequiredPatientDataValidate = (clinicId?: string) => {
  checkingPatientDataRequirements: boolean;
  isNationalIdRequired: boolean;
  isFatherNameRequired: boolean;
  isGrandFatherNameRequired: boolean;
};

export const useCheckRequiredPatientData: UseRequiredPatientDataValidate = (clinicId?: string) => {
  const dispatch = useAppDispatch();

  // User data
  const { currentUser, isAdmin } = useCurrentUser();
  const selectedClinicId = isAdmin ? clinicId : currentUser.clinic?.value;

  // Clinic data
  const clinicLoading = useAppSelector((state) => state.clinic.loading);
  const clinicData: MedicalInstitution | undefined = useAppSelector((state) => state.clinic.item);
  const isNationalIdRequired = !!clinicData?.requireSaudiIdDuringPatientRegistration;
  const isGrandFatherNameRequired = !!clinicData?.isGrandFatherNameRequiredForPatients;
  const isFatherNameRequired = !!clinicData?.isFatherNameRequiredForPatients;

  useEffect(() => {
    if (selectedClinicId) {
      dispatch(fetchClinic(selectedClinicId));
    }
  }, [dispatch, selectedClinicId]);

  return useMemo(() => {
    if (selectedClinicId) {
      return {
        checkingPatientDataRequirements: clinicLoading,
        isNationalIdRequired: isNationalIdRequired,
        isGrandFatherNameRequired: isGrandFatherNameRequired,
        isFatherNameRequired: isFatherNameRequired,
      };
    }

    // User is not assigned to any clinic
    return {
      checkingPatientDataRequirements: false,
      isNationalIdRequired: false,
      isGrandFatherNameRequired: false,
      isFatherNameRequired: false,
    };
  }, [selectedClinicId, clinicLoading, isNationalIdRequired, isGrandFatherNameRequired, isFatherNameRequired]);
};
