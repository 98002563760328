import React, { useCallback, useRef } from 'react';
import { Badge, Modal, ModalBody, ModalHeader } from 'reactstrap';
import {
  formattedDate,
  getApiDate,
  useAppDispatch,
  useAppIntl,
  useAppSelector,
  useModalCleaner,
  useSilentRefresh,
} from 'app/helpers';
import { fetchDay } from 'app/redux/appointment/appointment.actions';
import { selectWaitingSlot } from 'app/redux/calendar/calendar.actions';
import { CalendarDayParams } from 'app/redux/calendar/types';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { fetchBookedWaitingAppointments } from 'app/redux/remoteData/remoteData.actions';
import { AppointmentTicketModalContainer } from 'app/modals/appointment-ticket/appointment-ticket-modal.container';
import { DoctorSection } from 'app/features/calendar/components';
import { AvailableWaitingAppointments } from 'app/features/calendar/modals/manageWaitingAppointments/availableWaitingAppointments/availableWaitingAppointments';
import { BookedWaitingAppointments } from 'app/features/calendar/modals/manageWaitingAppointments/bookedWaitingAppointments/bookedWaitingAppointments';

export const ManageWaitingAppointmentsModal = () => {
  const dispatch = useAppDispatch();
  const modalRef = useRef(null);
  const { formatMessage } = useAppIntl();

  // Selectors
  const isOpen = useAppSelector((state) => state.modal.manageWaitingAppointments.isOpen);
  const { selectedWaitingSlot, tableViewItems } = useAppSelector((state) => state.calendar);
  const { params: bookedAppointmentsParams } = useAppSelector((state) => state.remoteData.bookedAppointments);

  // Selected customDurationInMinutes value
  const customDurationInMinutes = tableViewItems.params?.productRelatedFilters.customDurationInMinutes;

  // Actions
  const toggle = useCallback(() => dispatch(toggleModal('manageWaitingAppointments')), [dispatch]);
  const onClosed = useCallback(() => dispatch(selectWaitingSlot(null)), [dispatch]);

  // Refresh Function
  const refreshData = useCallback(() => {
    if (selectedWaitingSlot && bookedAppointmentsParams) {
      const calendarDayParams: CalendarDayParams = {
        branchId: selectedWaitingSlot.branchId,
        date: getApiDate(selectedWaitingSlot.date),
        doctorId: selectedWaitingSlot.doctor.id,
        productRelatedFilters: {
          customDurationInMinutes,
          productId: selectedWaitingSlot.product.value,
          specialityId: selectedWaitingSlot.speciality.value,
          subSpecialityId: selectedWaitingSlot.subSpeciality.value,
        },
      };

      dispatch(fetchDay(calendarDayParams, true));
      dispatch(fetchBookedWaitingAppointments(bookedAppointmentsParams, calendarDayParams, true));
    }
  }, [bookedAppointmentsParams, customDurationInMinutes, dispatch, selectedWaitingSlot]);

  // Effects
  useModalCleaner('manageWaitingAppointments', modalRef);
  useSilentRefresh(refreshData);

  return (
    <Modal ref={modalRef} isOpen={isOpen} onClosed={onClosed} size="xl" toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {formatMessage({ id: 'APPOINTMENTS.TEXT.MANAGE-WAITING' })}
        {selectedWaitingSlot && (
          <Badge className="fs-6 ms-2" color="light-primary">
            {formattedDate(selectedWaitingSlot.date)}
          </Badge>
        )}
      </ModalHeader>
      <ModalBody>
        {selectedWaitingSlot && <DoctorSection doctor={selectedWaitingSlot.doctor} />}
        <AvailableWaitingAppointments />
        <BookedWaitingAppointments />
        {/* @ts-ignore */}
        <AppointmentTicketModalContainer />
      </ModalBody>
    </Modal>
  );
};
