import React, { useCallback } from 'react';
import { OnChangeValue } from 'react-select';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { Field } from 'redux-form';
import { useAppIntl, useAppSelector } from 'app/helpers';
import { Box, Select } from 'app/components';
import { InputComponent } from 'app/shared';
import { DoctorData } from 'app/redux/book-appointment/types';
import { Option } from 'app/types';
import { useFormatOptionLabel } from 'app/features/bookAppointment/bookAppointmentWizard/helpers';
import { NoDataMessage } from 'app/features/bookAppointment/bookAppointmentWizard/shared';
import { DoctorList } from 'app/features/bookAppointment/bookAppointmentWizard/steps/specialityStep/doctorList/doctorList';
import { useFilterHandlers } from 'app/features/bookAppointment/bookAppointmentWizard/steps/specialityStep/helpers/useFilterHandlers';
import { SpecialityList } from 'app/features/bookAppointment/bookAppointmentWizard/steps/specialityStep/specialityList/specialityList';
import { BookAppointmentWizardField } from 'app/features/bookAppointment/types';

interface Props {
  onSpecialityChange: (option: Option) => void;
  onDoctorChange: (doctor: DoctorData) => void;
  onBranchChange: (option: Option | null) => void;
  selectedSpeciality: Option | null;
  selectedDoctor: DoctorData | null;
}

export const ReferralSpecialityStep = ({
  onSpecialityChange,
  onBranchChange,
  onDoctorChange,
  selectedSpeciality,
  selectedDoctor,
}: Props) => {
  const { formatMessage } = useAppIntl();

  const { data: doctors, loading: loadingDoctors } = useAppSelector((state) => state.bookAppointment.doctors);
  const branchOptions = useAppSelector((state) => state.bookAppointment.branches.data);
  const selectedBranch = useAppSelector((state) => state.bookAppointment.selectedBranch);
  const specialities = useAppSelector((state) => {
    return state.bookAppointment.referralInfo ? [state.bookAppointment.referralInfo?.speciality] : [];
  });

  // Actions
  const formatOptionLabel = useFormatOptionLabel();
  const { filterPractitioners } = useFilterHandlers({ specialities });
  const handleBranchChange = useCallback(
    (newValue: OnChangeValue<Option, false>) => {
      onBranchChange(newValue);
    },
    [onBranchChange],
  );

  return (
    <>
      <Row>
        <Col>
          <Box className="bookAppointment__lightBorder">
            <FormGroup>
              <Label for="branchSelect">
                {formatMessage({ id: 'CORE.TABLE.BRANCH' })}
                <span className="text-danger">&nbsp;*</span>
              </Label>
              <Select
                inputId="branchSelect"
                value={selectedBranch}
                name="branchSelect"
                options={branchOptions}
                onChange={handleBranchChange}
                formatOptionLabel={formatOptionLabel}
                isSearchable
              />
            </FormGroup>
          </Box>
        </Col>
      </Row>

      {!selectedBranch && <NoDataMessage customMessageId="BOOK-APPOINTMENT.TEXT.SELECT-BRANCH-FIRST" />}

      <Row>
        <Col>
          <Box className="bookAppointment__lightBorder">
            <span className="form-label">{formatMessage({ id: 'BOOK-APPOINTMENT.LABEL.BOOK-BY-SPECIALITY' })}</span>
            <SpecialityList
              data={specialities}
              selectedBranch={selectedBranch}
              selectedDoctor={selectedDoctor}
              selectedSpeciality={selectedSpeciality}
              onSpecialityChange={onSpecialityChange}
            />
          </Box>
        </Col>
      </Row>

      {selectedBranch && (
        <Row>
          <Col>
            <Box className="bookAppointment__lightBorder fadeIn">
              <Col>
                <Field
                  component={InputComponent}
                  label={formatMessage({ id: 'BOOK-APPOINTMENT.LABEL.BOOK-BY-PRACTITIONER' })}
                  name={BookAppointmentWizardField.doctorFilter}
                  type="text"
                  onChange={filterPractitioners}
                />
              </Col>
            </Box>

            <DoctorList onDoctorChange={onDoctorChange} selectedDoctor={selectedDoctor} />

            {!loadingDoctors && doctors.length === 0 && <NoDataMessage />}
          </Col>
        </Row>
      )}
    </>
  );
};
