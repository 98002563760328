import { generatePath } from 'react-router';
import {
  doctorAssessmentDraftPath,
  doctorAssessmentPath,
  generalTreatmentPlanClinicPath,
  generalTreatmentPlanPath,
  treatmentPlanPath,
} from 'app/routes/treatment/constants';

export const generateTreatmentPlanPath = (appointmentId: string) =>
  generatePath(treatmentPlanPath, {
    appointmentId,
  });

export const generateDoctorAssessmentPath = (
  appointmentId: string,
  assessmentId: string,
  phaseType: string,
  treatmentPageType?: string,
) =>
  generatePath(doctorAssessmentPath, {
    appointmentId,
    assessmentId,
    phaseType,
    treatmentPageType,
  });

export const generateDoctorAssessmentDraftPath = (
  appointmentId: string,
  phaseType: string,
  treatmentPageType?: string,
) =>
  generatePath(doctorAssessmentDraftPath, {
    appointmentId,
    phaseType,
    treatmentPageType,
  });

export const generateGeneralTreatmentPlanPath = (patientId: string) =>
  generatePath(generalTreatmentPlanPath, {
    patientId,
  });

export const generateGeneralTreatmentPlanClinicPath = (clinicId: string, patientId: string) =>
  generatePath(generalTreatmentPlanClinicPath, {
    clinicId,
    patientId,
  });
