import { Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { getFormValues } from 'redux-form';
import { getPriceLocaleString, useAppIntl, useAppSelector } from 'app/helpers';
import { InvoiceItemVat } from 'app/types';
import { getPriceAfterDiscount } from 'app/features/invoicingProcess/process/items/helpers';
import { ItemFormData } from 'app/features/invoicingProcess/process/items/itemForm/types';

interface Props {
  form: string;
}

export const TotalPrice = ({ form }: Props) => {
  const { formatMessage } = useAppIntl();
  const formValues = useAppSelector((state) => getFormValues(form)(state)) as Partial<ItemFormData> | undefined;
  const discountValue = Number(formValues?.discountValue || 0);

  const value =
    formValues && formValues.discountType
      ? getPriceAfterDiscount({
          discount: {
            discountTypeView: { label: '', value: formValues.discountType }, // label is not required
            value: discountValue < 0 ? 0 : discountValue,
          },
          price: formValues.item?.details.price || 0,
          quantity: formValues.quantity ? Number(formValues.quantity) : 0,
          vat: InvoiceItemVat.Default,
        })
      : '';

  const formattedValue = getPriceLocaleString(value);

  return (
    <>
      <Label>{formatMessage({ id: 'CORE.LABEL.TOTAL-PRICE', defaultMessage: 'Total Price' })}</Label>
      <InputGroup className="mb-2 mb-lg-0">
        <Input disabled value={formattedValue} />
        <InputGroupText>{formatMessage({ id: 'CORE.TEXT.CURRENCY-SAR' })}</InputGroupText>
      </InputGroup>
    </>
  );
};
