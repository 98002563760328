import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { useAppIntl } from 'app/helpers';
import { CalendarTabs } from 'app/types';

export const Tabs = () => {
  const { push } = useHistory();
  const { formatMessage } = useAppIntl();
  const { tabId } = useParams<{ tabId?: CalendarTabs }>();

  const onClick = useCallback(
    (tab: CalendarTabs) => {
      push(`/calendar/${tab}`);
    },
    [push],
  );

  return (
    <Nav pills>
      <NavItem>
        <NavLink active={tabId === CalendarTabs.Day} onClick={() => onClick(CalendarTabs.Day)} data-cy="calendarDayTab">
          {formatMessage({ id: 'CALENDAR-TABS.DAY' })}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          active={tabId === CalendarTabs.Week}
          onClick={() => onClick(CalendarTabs.Week)}
          data-cy="calendarWeekTab"
        >
          {formatMessage({ id: 'CALENDAR-TABS.WEEK' })}
        </NavLink>
      </NavItem>
    </Nav>
  );
};
