import { PayloadAction } from '@reduxjs/toolkit';
import { change, FormAction } from 'redux-form';
import { put, race, select, take } from 'redux-saga/effects';
import { FORMS_ASSESSMENT_ORDERS } from 'app/shared';
import { fetchUpdatedOrdersStatuses } from 'app/redux/doctorAssessment/doctorAssessment.actions';
import {
  UPDATE_ORDERS_STATUSES_FAILURE,
  UPDATE_ORDERS_STATUSES_SUCCESS,
} from 'app/redux/doctorAssessment/doctorAssessment.types';
import { FetchUpdatedOrdersStatusesResponse } from 'app/redux/doctorAssessment/types';
import { RootState } from 'app/types';
import { AssessmentOrdersFormData } from 'app/features/doctorAssessment/components/ordersSection/forms/ordersForm/types';
import { WsRefreshOrdersStatusesPayload } from 'app/sagas/assessment/types';

export function* fetchOrdersStatuses({ payload }: PayloadAction<WsRefreshOrdersStatusesPayload>) {
  const { appointmentId } = payload;
  const { form }: RootState = yield select();
  const formValues: AssessmentOrdersFormData | undefined = form[FORMS_ASSESSMENT_ORDERS]?.values;
  const items = formValues?.assessmentOrders;

  if (appointmentId) {
    yield put<any>(fetchUpdatedOrdersStatuses(appointmentId));

    const {
      ordersStatuses,
      ordersStatusesError,
    }: { ordersStatuses?: PayloadAction<FetchUpdatedOrdersStatusesResponse>; ordersStatusesError?: PayloadAction } =
      yield race({
        ordersStatuses: take(UPDATE_ORDERS_STATUSES_SUCCESS),
        ordersStatusesError: take(UPDATE_ORDERS_STATUSES_FAILURE),
      });

    if (!ordersStatusesError && items && ordersStatuses) {
      const updatedItems = items.map((item) => {
        const associatedItem = ordersStatuses.payload.data.find((updatedStatus) => updatedStatus.id === item?.id);

        return {
          ...item,
          status: associatedItem?.status || item.status,
          number: item.number || associatedItem?.number,
        };
      });

      yield put<FormAction>(change(FORMS_ASSESSMENT_ORDERS, 'assessmentOrders', updatedItems));
    }
  }
}
