import { useMemo } from 'react';
import { useAppIntl, useCurrentUserPermissions } from 'app/helpers';
import { InvoicePayBy, Option } from 'app/types';

export const usePayByOptions = () => {
  const { formatMessage } = useAppIntl();
  const { allowedForInsuranceManagement } = useCurrentUserPermissions();

  return useMemo<Option<InvoicePayBy>[]>(() => {
    return [
      ...(allowedForInsuranceManagement
        ? [
            {
              label: formatMessage({ id: 'CORE.LABEL.INSURANCE', defaultMessage: 'Insurance' }),
              value: InvoicePayBy.Insurance,
            },
          ]
        : []),
      {
        label: formatMessage({ id: 'CORE.LABEL.PATIENT', defaultMessage: 'Patient' }),
        value: InvoicePayBy.Patient,
      },
    ];
  }, [allowedForInsuranceManagement, formatMessage]);
};
