import { useCallback } from 'react';
import { getApiDate, useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { RemoteTable } from 'app/shared';
import { fetchBookedWaitingAppointments } from 'app/redux/remoteData/remoteData.actions';
import { useColumns } from 'app/features/calendar/modals/manageWaitingAppointments/bookedWaitingAppointments/helpers';

export const BookedWaitingAppointments = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const columns = useColumns();

  const { data, loading, params } = useAppSelector((state) => state.remoteData.bookedWaitingAppointments);
  const selectedWaitingSlot = useAppSelector((state) => state.calendar.selectedWaitingSlot);
  const customDurationInMinutes = useAppSelector(
    (state) => state.calendar.tableViewItems.params?.productRelatedFilters.customDurationInMinutes,
  );

  const fetchData = useCallback(
    (newParams) => {
      if (selectedWaitingSlot) {
        dispatch(
          fetchBookedWaitingAppointments(newParams, {
            branchId: selectedWaitingSlot.branchId,
            date: getApiDate(selectedWaitingSlot.date),
            doctorId: selectedWaitingSlot.doctor.id,
            productRelatedFilters: {
              customDurationInMinutes,
              productId: selectedWaitingSlot.product.value,
              specialityId: selectedWaitingSlot.speciality.value,
              subSpecialityId: selectedWaitingSlot.subSpeciality.value,
            },
          }),
        );
      }
    },
    [customDurationInMinutes, dispatch, selectedWaitingSlot],
  );

  return (
    <>
      <h5 className="mb-3">{formatMessage({ id: 'CORE.TEXT.BOOKED-WAITING-APPOINTMENTS' })}</h5>
      <RemoteTable
        columns={columns}
        data={data}
        fetchData={fetchData}
        id="bookedWaitingAppointments"
        initialFetch
        keyField="id"
        loading={loading}
        params={params}
      />
    </>
  );
};
