import { PayloadAction } from '@reduxjs/toolkit';
import { select } from 'redux-saga/effects';
import { RootState } from 'app/types';
import { RefreshCalendarEvent } from 'app/features/calendar/helpers';
import { wsUpdateDayView, wsUpdateTableView, wsUpdateWeekView } from 'app/sagas/calendar/generators';

export function* wsUpdateCalendar({ payload }: PayloadAction<RefreshCalendarEvent>) {
  const {
    router: {
      location: { pathname },
    },
    modal: { bookAppointment },
  }: RootState = yield select();

  if (pathname.includes('calendar')) {
    if (pathname.includes('calendar/day')) {
      // Update Day View
      yield wsUpdateDayView(payload);
    }
    if (pathname.includes('calendar/week')) {
      // Update Week View
      yield wsUpdateWeekView(payload);
    }
  } else if (bookAppointment.isOpen) {
    yield wsUpdateTableView(payload);
  }
}
