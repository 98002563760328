import { RawDraftContentState } from 'draft-js';
import { getPlainText } from 'app/features/doctorAssessment/helpers/getPlainText';
import { ProcedureParams } from './medicalProfileValuesAdapter';

export const getProcedureParams = (medicalProfileProcedure?: RawDraftContentState): ProcedureParams | null => {
  if (medicalProfileProcedure?.blocks[0].text) {
    return {
      text: getPlainText(medicalProfileProcedure.blocks),
      formattedText: JSON.stringify(medicalProfileProcedure),
    };
  }

  return null;
};
