import { InvoicePaymentMethodType, PatientInvoicesToPay } from 'app/types';

export enum PaymentType {
  None = 'NONE',
  ByInvoices = 'BY-INVOICES',
  ByAmount = 'BY-AMOUNT',
}

export interface SettleInvoicesPayment extends PatientInvoicesToPay {
  paymentId: string;
  paymentAmount: number;
  generalPaymentDetails: PatientPayment;
}

export interface Payment extends PatientPayment {
  paymentId: string;
}

export interface PaymentAllocation {
  invoiceId: string;
  amount: number;
}

export interface InvoiceWithCalculatedRemaining extends PatientInvoicesToPay {
  calculatedRemaining?: number;
}

export interface AddSettleInvoicesPaymentPayload {
  paymentType: PaymentType;
  generalPaymentDetails: PatientPayment;
  selected?: PatientInvoicesToPay[];
}

export interface SettleInvoicesState {
  invoices: InvoiceWithCalculatedRemaining[];
  payments: SettleInvoicesPayment[];
  paymentAllocations: PaymentAllocation[];
  actualInvoices: InvoiceWithCalculatedRemaining[];
  paymentType: PaymentType;
  invoiceValues: Record<string, number>;
  debtAmount: number;
  remainingCredits: number;
}

export interface AddAccountCreditState {
  payments: Payment[];
}

export interface WithdrawAccountCreditState {
  payments: Payment[];
}

export interface PatientPaymentsState {
  settleInvoices: SettleInvoicesState;
  addAccountCredit: AddAccountCreditState;
  withdrawAccountCredit: WithdrawAccountCreditState;
}

export interface PatientPayment {
  amount: number | string;
  paymentMethod?: InvoicePaymentMethodType;
  referenceNo?: string;
  details?: string;
  otherPaymentMethod?: string;
}

export interface PatientSettleInvoicesPayment extends PatientPayment {
  invoiceId: string;
}
