import toast from 'react-hot-toast';
import { LANGUAGES_EN_CODE, saveFile } from 'app/shared';
import { DownloadFileException } from 'app/shared/downloads/exceptions';
import { reportException } from 'app/features/sentry/sentry';
import { DOWNLOAD_FILE_FAILURE, DOWNLOAD_FILE_REQUEST, DOWNLOAD_FILE_SUCCESS } from './downloads.action-types';

export const downloadFileRequest = () => ({ type: DOWNLOAD_FILE_REQUEST });
export const downloadFileSuccess = () => ({ type: DOWNLOAD_FILE_SUCCESS });
export const downloadFileFailure = (error) => ({ type: DOWNLOAD_FILE_FAILURE, payload: error });

export const download =
  ({ token, locale = LANGUAGES_EN_CODE, endpoint, body, withOpening }) =>
  (dispatch) => {
    const handleFailure = (resolve, exception) =>
      Promise.resolve(resolve()).then(() => {
        if (exception.name !== 'AbortError') {
          dispatch(downloadFileFailure(exception));

          reportException(exception);
        }
      });

    const handleSuccess = (resolve) =>
      Promise.resolve(resolve()).then(() => {
        dispatch(downloadFileSuccess());
      });

    return new Promise(async (resolve) => {
      try {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'Accept-Language': locale,
        };

        await dispatch(downloadFileRequest());
        const url = endpoint.startsWith('http') ? endpoint : `${process.env.REACT_APP_API_URL}/${endpoint}`;
        const response = await fetch(url, {
          headers: headers,
          method: !!body ? 'POST' : 'GET',
          body: !!body ? JSON.stringify(body) : undefined,
        });

        if (response.status !== 200) {
          const body = await response.json();

          toast.error(body.message);

          throw new DownloadFileException(body.message);
        }

        await saveFile(response, withOpening);

        await handleSuccess(resolve);
      } catch (exception) {
        await handleFailure(resolve, exception);
      }
    });
  };
