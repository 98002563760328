import { SelectedDay } from 'app/redux/calendar/types';
import { CalendarItem, Doctor } from 'app/types';
import { DateFormatter } from 'app/features/bookAppointment/bookAppointmentWizard/shared/dateFormatter/dateFormatter';
import { DoctorFormatter } from '../doctorFormatter/doctorFormatter';

interface Props {
  doctor: Doctor;
  item: CalendarItem;
  onSelectDate: (day: SelectedDay) => void;
  targetId: string;
}

export const DoctorWorkingDayFormatter = ({ doctor, item, onSelectDate, targetId }: Props) => {
  return (
    <div className="doctorWorkingDayFormatter">
      <DoctorFormatter doctor={doctor} targetId={targetId} workingDaySection />
      <hr className="mt-0" />

      {DateFormatter(null, item, -1, { onSelectDate })}
    </div>
  );
};
