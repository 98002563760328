import React, { useCallback, useEffect, useState } from 'react';
import { OnChangeValue } from 'react-select';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { useAppIntl, useAppSelector, useCurrentUser } from 'app/helpers';
import { Box, Select } from 'app/components';
import { Option } from 'app/types';
import { useFormatOptionLabel } from 'app/features/bookAppointment/bookAppointmentWizard/helpers';
import { NoDataMessage } from 'app/features/bookAppointment/bookAppointmentWizard/shared';
import { useFilterHandlers } from './helpers/useFilterHandlers';
import { SubSpecialityList } from './subSpecialityList/subSpecialityList';
import './subSpecialityStep.scss';

interface Props {
  selectedSubSpeciality: Option | null;
  onSubSpecialityChange: (option: Option) => void;
  onBranchChange: (option: Option | null) => void;
}

export const SubSpecialityStep = ({ selectedSubSpeciality, onSubSpecialityChange, onBranchChange }: Props) => {
  const { formatMessage } = useAppIntl();
  const [filteredSubSpecialities, setFilteredSubSpecialities] = useState<Option[]>([]);
  const { isDoctor } = useCurrentUser();

  const branchOptions = useAppSelector((state) => state.bookAppointment.branches.data);
  const selectedBranch = useAppSelector((state) => state.bookAppointment.selectedBranch);
  const { data: subSpecialities, loading } = useAppSelector((state) => state.bookAppointment.subSpecialities);

  // Actions
  const formatOptionLabel = useFormatOptionLabel();
  const { filterSubSpecialities } = useFilterHandlers({ subSpecialities, setFilteredSubSpecialities });
  const handleBranchChange = useCallback(
    (newValue: OnChangeValue<Option, false>) => {
      onBranchChange(newValue);
    },
    [onBranchChange],
  );

  // Effects
  useEffect(() => {
    setFilteredSubSpecialities(subSpecialities);
  }, [subSpecialities, setFilteredSubSpecialities]);

  return (
    <>
      {isDoctor && (
        <Row>
          <Col>
            <Box className="bookAppointment__lightBorder">
              <FormGroup>
                <Label for="branchSelect">{formatMessage({ id: 'CORE.TABLE.BRANCH' })}</Label>
                <Select
                  inputId="branchSelect"
                  value={selectedBranch}
                  name="branchSelect"
                  options={branchOptions}
                  onChange={handleBranchChange}
                  formatOptionLabel={formatOptionLabel}
                  isSearchable
                />
              </FormGroup>
            </Box>
          </Col>
        </Row>
      )}
      <Box className="bookAppointment__lightBorder">
        <Row>
          <Col>
            <FormGroup>
              <Label for="subSpecialityFilter">
                {formatMessage({ id: 'BOOK-APPOINTMENT.LABEL.SEARCH-FOR-SUB-SPECIALITY' })}
              </Label>
              <Input id="subSpecialityFilter" type="text" onChange={filterSubSpecialities} />
            </FormGroup>
          </Col>
        </Row>

        <div className="subSpecialityStep__optionsContainer">
          {!loading && filteredSubSpecialities.length === 0 ? (
            <NoDataMessage />
          ) : (
            <SubSpecialityList
              data={filteredSubSpecialities}
              loading={loading}
              selectedSubSpeciality={selectedSubSpeciality}
              onSubSpecialityChange={onSubSpecialityChange}
            />
          )}
        </div>
      </Box>
    </>
  );
};
