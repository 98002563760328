import React, { ChangeEvent } from 'react';
import { Col, Row } from 'reactstrap';
import { Field } from 'redux-form';
import { EventWithDataHandler } from 'redux-form/lib/Field';
import { useAppIntl, useAppSelector, useCurrentUser } from 'app/helpers';
import { Box } from 'app/components';
import { AsyncSelectComponent } from 'app/shared';
import { useFetchPatientSourceOptions } from 'app/appointment/form/helpers/useFetchPatientSourceOptions';
import { useClinicId } from 'app/features/bookAppointment/helpers';
import { BookAppointmentWizardField } from 'app/features/bookAppointment/types';
import { useSourceOfPatientFormatter } from 'app/features/patient/patientForm/helpers/useSourceOfPatientFormatter';

interface Props {
  onPatientSourceChange: EventWithDataHandler<ChangeEvent<any>>;
}

export const PatientSourceStep = ({ onPatientSourceChange }: Props) => {
  const { formatMessage } = useAppIntl();
  const { isDoctor, currentUser } = useCurrentUser();
  const referralInfo = useAppSelector((state) => state.bookAppointment.referralInfo);

  const clinicId = useClinicId();
  const formatPatientSourceLabel = useSourceOfPatientFormatter();
  const fetchPatientSources = useFetchPatientSourceOptions({
    clinicId,
    specialityIds: referralInfo?.speciality?.value,
    practitionerIds: isDoctor ? currentUser.id : undefined,
  });

  return (
    <Row>
      <Col>
        <Box className="bookAppointment__lightBorder">
          <Field
            name={BookAppointmentWizardField.patientSource}
            component={AsyncSelectComponent}
            fetchOptions={fetchPatientSources}
            formatOptionLabel={formatPatientSourceLabel}
            label={formatMessage({ id: 'CORE.LABEL.PATIENT-SOURCE', defaultMessage: 'Patient Source' })}
            onChange={onPatientSourceChange}
            showClinicyId={false}
            isClearable
          />
        </Box>
      </Col>
    </Row>
  );
};
