import { RawDraftContentState } from 'draft-js';
import { formValueSelector, getFormInitialValues, isPristine } from 'redux-form';
import { useAppSelector } from 'app/helpers';
import { Values } from 'app/components/locationSelector/procedureLocation/types';
import {
  FORMS_ASSESSMENT_BILLING,
  FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM,
  FORMS_ASSESSMENT_ORDERS,
  FORMS_ASSESSMENT_PRESCRIPTIONS,
  FORMS_ASSESSMENT_REFERRALS,
  FORMS_ASSESSMENT_SIGNATURE_FORM,
} from 'app/shared';
import { ICD10Code, RemoteFile } from 'app/types';
import { MedicalProfileFormData } from 'app/features/doctorAssessment/components/medicalProfileSection/components/practitionerAssessment/practitionerAssessment';
import { isMedicalProfileLocationsPristine } from 'app/features/doctorAssessment/helpers/isMedicalProfileLocationsPristine';
import { isMedicalProfileTablePristine } from 'app/features/doctorAssessment/helpers/isMedicalProfileTablePristine';
import { isRichTextBoxPristine } from 'app/features/doctorAssessment/helpers/isRichTextBoxPristine';
import { useCheckViewStatus } from 'app/features/doctorAssessment/helpers/useCheckViewStatus';

interface AssessmentController {
  isCompletedDisabled: boolean;
  isSaveForLaterDisabled: boolean;
  isUpdateDisabled: boolean;
}

// Global Variables - Sections Values
const medicalProfile = formValueSelector(FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM);
const signature = formValueSelector(FORMS_ASSESSMENT_SIGNATURE_FORM);

// Global Variables - Sections Initial Values
const selectMedicalProfileInitial = getFormInitialValues(FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM);

export const useAssessmentFormController = (): AssessmentController => {
  // Selectors - Sections Pristine
  const isSignaturePristine = useAppSelector((state) => isPristine(FORMS_ASSESSMENT_SIGNATURE_FORM)(state));
  const isBillingPristine = useAppSelector((state) => isPristine(FORMS_ASSESSMENT_BILLING)(state));
  const isOrderPristine = useAppSelector((state) => isPristine(FORMS_ASSESSMENT_ORDERS)(state));
  const isPrescriptionPristine = useAppSelector((state) => isPristine(FORMS_ASSESSMENT_PRESCRIPTIONS)(state));
  const isReferralSectionPristine = useAppSelector((state) => isPristine(FORMS_ASSESSMENT_REFERRALS)(state));

  // Selectors - Form Current
  const currentChiefComplaint: RawDraftContentState | undefined = useAppSelector((state) =>
    medicalProfile(state, 'chiefComplaint'),
  );
  const currentAssessment: RawDraftContentState | undefined = useAppSelector((state) =>
    medicalProfile(state, 'assessment'),
  );
  const currentHealthEducation: RawDraftContentState | undefined = useAppSelector((state) =>
    medicalProfile(state, 'healthEducation'),
  );
  const currentMedicalCondition: RawDraftContentState | undefined = useAppSelector((state) =>
    medicalProfile(state, 'medicalCondition'),
  );
  const currentSystemReview: RawDraftContentState | undefined = useAppSelector((state) =>
    medicalProfile(state, 'systemReview'),
  );
  const currentPresentIllnessHistory: RawDraftContentState | undefined = useAppSelector((state) =>
    medicalProfile(state, 'presentIllnessHistory'),
  );
  const currentSignificantSigns: RawDraftContentState | undefined = useAppSelector((state) =>
    medicalProfile(state, 'clinicalExamination'),
  );
  const currentDiagnosis: RawDraftContentState | undefined = useAppSelector((state) =>
    medicalProfile(state, 'diagnosis'),
  );
  const currentPlan: RawDraftContentState | undefined = useAppSelector((state) => medicalProfile(state, 'plan'));
  const currentGoal: RawDraftContentState | undefined = useAppSelector((state) => medicalProfile(state, 'goal'));
  const currentOtherCondition: RawDraftContentState | undefined = useAppSelector((state) =>
    medicalProfile(state, 'otherCondition'),
  );
  const currentComment: RawDraftContentState | undefined = useAppSelector((state) => medicalProfile(state, 'comment'));
  const currentProcedureUpdate: RawDraftContentState | undefined = useAppSelector((state) =>
    medicalProfile(state, 'procedureUpdate'),
  );

  const currentIcdTable: ICD10Code[] = useAppSelector((state) => medicalProfile(state, 'icdTable'));

  // Selectors - Form Initials
  const initialMedicalProfile: Partial<MedicalProfileFormData> = useAppSelector((state) =>
    selectMedicalProfileInitial(state),
  );

  // Selectors
  const signatureFile: RemoteFile | undefined = useAppSelector((state) => signature(state, 'signatureFile'));
  const procedureLocation: Values | undefined = useAppSelector((state) => medicalProfile(state, 'procedureLocation'));
  const goalLocation: Values | undefined = useAppSelector((state) => medicalProfile(state, 'goalLocation'));
  const doctorAssessment = useAppSelector((state) => state.doctorAssessment.data);
  const previousDoctorAssessmentId = useAppSelector((state) => state.doctorAssessment.data?.previousDoctorAssessmentId);
  const billingStatusesRefreshing = useAppSelector((state) => state.doctorAssessment.billingStatusesRefreshing);

  // Helper Variables
  const { isContinue, isDraftMode } = useCheckViewStatus(doctorAssessment?.viewStatus, doctorAssessment?.completedAt);

  const initialChiefComplaint = initialMedicalProfile?.chiefComplaint;
  const initialAssessment = initialMedicalProfile?.assessment;
  const initialHealthEducation = initialMedicalProfile?.healthEducation;
  const initialMedicalCondition = initialMedicalProfile?.medicalCondition;
  const initialSystemReview = initialMedicalProfile?.systemReview;
  const initialPresentIllnessHistory = initialMedicalProfile?.presentIllnessHistory;
  const initialSignificantSigns = initialMedicalProfile?.clinicalExamination;
  const initialDiagnosis = initialMedicalProfile?.diagnosis;
  const initialPlan = initialMedicalProfile?.plan;
  const initialGoal = initialMedicalProfile?.goal;
  const initialOtherCondition = initialMedicalProfile?.otherCondition;
  const initialComment = initialMedicalProfile?.comment;
  const initialProcedureUpdate = initialMedicalProfile?.procedureUpdate;
  const initialProcedureLocation = initialMedicalProfile?.procedureLocation;
  const initialGoalLocation = initialMedicalProfile?.goalLocation;

  const initialIcdTable = initialMedicalProfile?.icdTable;

  const isInitialAssessmentPristine = isRichTextBoxPristine({
    initialRich: initialAssessment,
    currentRich: currentAssessment,
  });
  const isHealthEducationPristine = isRichTextBoxPristine({
    initialRich: initialHealthEducation,
    currentRich: currentHealthEducation,
  });
  const isMedicalConditionPristine = isRichTextBoxPristine({
    initialRich: initialMedicalCondition,
    currentRich: currentMedicalCondition,
  });
  const isSystemReviewPristine = isRichTextBoxPristine({
    initialRich: initialSystemReview,
    currentRich: currentSystemReview,
  });
  const isPresentIllnessHistoryPristine = isRichTextBoxPristine({
    initialRich: initialPresentIllnessHistory,
    currentRich: currentPresentIllnessHistory,
  });
  const isChiefComplaintPristine = isRichTextBoxPristine({
    initialRich: initialChiefComplaint,
    currentRich: currentChiefComplaint,
  });
  const isSignificantSignsPristine = isRichTextBoxPristine({
    initialRich: initialSignificantSigns,
    currentRich: currentSignificantSigns,
  });
  const isDiagnosisPristine = isRichTextBoxPristine({ initialRich: initialDiagnosis, currentRich: currentDiagnosis });
  const isPlanPristine = isRichTextBoxPristine({ initialRich: initialPlan, currentRich: currentPlan });
  const isGoalPristine = isRichTextBoxPristine({ initialRich: initialGoal, currentRich: currentGoal });
  const isConditionPristine = isRichTextBoxPristine({
    initialRich: initialOtherCondition,
    currentRich: currentOtherCondition,
  });
  const isCommentPristine = isRichTextBoxPristine({ initialRich: initialComment, currentRich: currentComment });
  const isProcedureUpdatePristine = isRichTextBoxPristine({
    initialRich: initialProcedureUpdate,
    currentRich: currentProcedureUpdate,
  });

  const isIcdArrayPristine = isMedicalProfileTablePristine({
    initialTable: initialIcdTable,
    currentTable: currentIcdTable,
  });

  const isPlanArrayPristine = isMedicalProfileLocationsPristine({
    initialTable: initialProcedureLocation,
    currentTable: procedureLocation,
  });

  const isGoalArrayPristine = isMedicalProfileLocationsPristine({
    initialTable: initialGoalLocation,
    currentTable: goalLocation,
  });

  const isMedicalProfilePristine =
    isInitialAssessmentPristine &&
    isHealthEducationPristine &&
    isMedicalConditionPristine &&
    isSystemReviewPristine &&
    isPresentIllnessHistoryPristine &&
    isChiefComplaintPristine &&
    isSignificantSignsPristine &&
    isDiagnosisPristine &&
    isPlanPristine &&
    isConditionPristine &&
    isCommentPristine &&
    isProcedureUpdatePristine &&
    isIcdArrayPristine &&
    isPlanArrayPristine &&
    isGoalPristine &&
    isGoalArrayPristine;

  // Conditions - Addition
  const isSignatureFilled = !!signatureFile?.id;
  const isAssessmentPristine =
    isMedicalProfilePristine &&
    isSignaturePristine &&
    isBillingPristine &&
    isOrderPristine &&
    isPrescriptionPristine &&
    isReferralSectionPristine;

  // Conditions - Main
  const isCompletedDisabled =
    (previousDoctorAssessmentId && (isContinue || isDraftMode) ? !isSignatureFilled : !isSignatureFilled) ||
    billingStatusesRefreshing;
  const isSaveForLaterDisabled = isAssessmentPristine || billingStatusesRefreshing;
  const isUpdateDisabled = isAssessmentPristine || billingStatusesRefreshing;

  return {
    isCompletedDisabled,
    isSaveForLaterDisabled,
    isUpdateDisabled,
  };
};
