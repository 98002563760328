import { PayloadAction } from '@reduxjs/toolkit';
import { change, FormAction } from 'redux-form';
import { put, select } from 'redux-saga/effects';
import { FORMS_ASSESSMENT_BILLING } from 'app/shared';
import { UpdatedStatusesResponse } from 'app/redux/doctorAssessment/doctorAssessment.actions';
import { RootState } from 'app/types';
import { BillingFormData } from 'app/features/doctorAssessment/components/billingSection/forms/billingForm/billingForm';

export function* refreshLocalStatuses({ payload }: PayloadAction<UpdatedStatusesResponse>) {
  const { form }: RootState = yield select();

  // Helper Variables
  const formValues: BillingFormData | undefined = form[FORMS_ASSESSMENT_BILLING]?.values;
  const items = formValues?.assessmentBillingItems;

  if (formValues && items) {
    const updatedItems = items.map((item) => ({
      ...item,
      status:
        payload.data.find((updatedStatus) =>
          updatedStatus.invoicePackageItemSnapshotId
            ? updatedStatus.performerBillingItemId === item.performerBillingItemId &&
              updatedStatus.invoicePackageItemSnapshotId === item.invoicePackageItemSnapshotId
            : updatedStatus.performerBillingItemId === item.performerBillingItemId &&
              !item.invoicePackageItemSnapshotId,
        )?.status || item.status,
    }));

    yield put<FormAction>(change(FORMS_ASSESSMENT_BILLING, 'assessmentBillingItems', updatedItems));
  }
}
