import { AnyAction } from 'redux';
import * as types from 'app/redux/doctorAssessment/doctorAssessment.types';
import {
  AssessmentReferral,
  DiagnosesResponse,
  DoctorAssessment,
  ProcedureResponse,
} from 'app/redux/doctorAssessment/types';
import { AssessmentOrder, RemoteFile } from 'app/types';
import { AssessmentBillingItem } from 'app/features/doctorAssessment/components/billingSection/forms/types';

interface DoctorAssessmentReducer {
  billingStatusesRefreshing: boolean;
  clinicLogo: RemoteFile | null;
  data: DoctorAssessment | null;
  information: {
    description: DiagnosesResponse | ProcedureResponse;
    title: string;
  } | null;
  loading: boolean;
  selectedItem: {
    data: AssessmentBillingItem | null;
    rowIndex: number;
  };
  selectedReferral: {
    data: AssessmentReferral | null;
    rowIndex: number;
  };
  selectedAssessmentOrder: {
    assessmentOrder: AssessmentOrder;
    rowIndex: number;
  } | null;
  assessmentOrderTicketId: string | null;
}

const initialState: DoctorAssessmentReducer = {
  billingStatusesRefreshing: false,
  clinicLogo: null,
  data: null,
  information: null,
  loading: false,
  selectedItem: {
    data: null,
    rowIndex: -1,
  },
  selectedReferral: {
    data: null,
    rowIndex: -1,
  },
  selectedAssessmentOrder: null,
  assessmentOrderTicketId: null,
};

export const doctorAssessmentReducer = (state = initialState, action: AnyAction): DoctorAssessmentReducer => {
  switch (action.type) {
    case types.FETCH_EMPTY_DOCTOR_ASSESSMENT_REQUEST:
    case types.FETCH_DOCTOR_ASSESSMENT_REQUEST:
    case types.FETCH_CONTINUED_DOCTOR_ASSESSMENT_REQUEST:
      return {
        ...state,
        data: initialState.data,
        loading: true,
      };
    case types.FETCH_EMPTY_DOCTOR_ASSESSMENT_SUCCESS:
    case types.FETCH_DOCTOR_ASSESSMENT_SUCCESS:
    case types.FETCH_CONTINUED_DOCTOR_ASSESSMENT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case types.FETCH_EMPTY_DOCTOR_ASSESSMENT_FAILURE:
    case types.FETCH_DOCTOR_ASSESSMENT_FAILURE:
    case types.FETCH_CONTINUED_DOCTOR_ASSESSMENT_FAILURE:
      return {
        ...state,
        data: initialState.data,
        loading: false,
      };
    case types.FETCH_CLINIC_LOGO_SUCCESS:
      return {
        ...state,
        clinicLogo: action.payload.data,
      };
    case types.UPDATE_NOT_COVERED_BILLING_STATUS_REQUEST:
    case types.UPDATE_COVERED_BILLING_STATUS_REQUEST:
      return {
        ...state,
        billingStatusesRefreshing: true,
      };
    case types.UPDATE_NOT_COVERED_BILLING_STATUS_SUCCESS:
    case types.UPDATE_NOT_COVERED_BILLING_STATUS_FAILURE:
    case types.UPDATE_COVERED_BILLING_STATUS_SUCCESS:
    case types.UPDATE_COVERED_BILLING_STATUS_FAILURE:
      return {
        ...state,
        billingStatusesRefreshing: false,
      };
    case types.SET_ASSESSMENT_BILLING_ITEM:
      return {
        ...state,
        selectedItem: {
          data: action.payload.assessmentBillingItem,
          rowIndex: action.payload.rowIndex,
        },
      };
    case types.RESET_ASSESSMENT_BILLING_ITEM:
      return {
        ...state,
        selectedItem: {
          ...initialState.selectedItem,
        },
      };
    case types.SET_INFORMATION:
      return {
        ...state,
        information: action.payload,
      };
    case types.SET_ASSESSMENT_REFERRAL:
      return {
        ...state,
        selectedReferral: {
          data: action.payload.assessmentReferral,
          rowIndex: action.payload.rowIndex,
        },
      };
    case types.SET_ASSESSMENT_ORDER:
      return {
        ...state,
        selectedAssessmentOrder: {
          assessmentOrder: action.payload.assessmentOrder,
          rowIndex: action.payload.rowIndex,
        },
      };
    case types.RESET_ASSESSMENT_ORDER:
      return {
        ...state,
        selectedAssessmentOrder: initialState.selectedAssessmentOrder,
      };
    case types.RESET_ASSESSMENT_REFERRAL:
      return {
        ...state,
        selectedReferral: {
          ...initialState.selectedReferral,
        },
      };
    case types.RESET_DOCTOR_ASSESSMENT_DATA:
      return {
        ...state,
        data: initialState.data,
      };
    case types.SET_ASSESSMENT_ORDER_TICKET_ID:
      return {
        ...state,
        assessmentOrderTicketId: action.payload,
      };
    case types.RESET_ASSESSMENT_ORDER_TICKET_ID:
      return {
        ...state,
        assessmentOrderTicketId: initialState.assessmentOrderTicketId,
      };
    default:
      return state;
  }
};
