import React, { useCallback, useState } from 'react';
import { IconEdit } from '@tabler/icons-react';
import { Button } from 'reactstrap';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { LocalBootstrapTable } from 'app/components/bootstrapTable/localBootstrapTable/localBootstrapTable';
import { DiagnosesResponse } from 'app/redux/doctorAssessment/types';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { useDiagnosisColumns } from 'app/features/doctorAssessment/components/medicalProfileSection/components/helpers/columns/useDiagnosisColumns';
import { DiagnosisWrapper } from 'app/features/doctorAssessment/components/medicalProfileSection/components/helpers/wrappers/diagnosisWrapper/diagnosisWrapper';

interface Props {
  initialValues: DiagnosesResponse[];
  isContinue: boolean;
  isReadOnly?: boolean;
}

export const VisitDiagnosisWrapper = ({ initialValues, isContinue, isReadOnly }: Props) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();

  // Local State
  const [isUpdateMode, setIsUpdateMode] = useState(false);

  // Selectors
  const { loading } = useAppSelector((state) => state.remoteData.visitPractitionerAssessment);

  // Helper Hooks
  const diagnosisColumns = useDiagnosisColumns();

  // Handlers
  const onUpdate = useCallback(() => {
    setIsUpdateMode(true);
    dispatch(toggleModal('addInsuranceDiagnosis', true));
  }, [dispatch]);

  return (
    <div className="my-5">
      <div>
        <h5>{formatMessage({ id: 'CORE.LABEL.PREVIOUS-DIAGNOSES' })}</h5>
        <LocalBootstrapTable
          classes="table-sm mb-2"
          columns={diagnosisColumns}
          data={initialValues}
          id="diagnosisTable"
          keyField="createdAt"
          loading={loading}
          wrapperClasses="w-100"
        />
        <div className="text-center">
          {isContinue || isReadOnly
            ? false
            : !isUpdateMode && (
                <Button color="primary-gradient" size="sm" onClick={onUpdate}>
                  <IconEdit className="buttonIcon buttonIcon--left" size={16} strokeWidth={2.5} />
                  {formatMessage({ id: 'TREATMENT-PLAN.TEXT.ADD-DIAGNOSIS' })}
                </Button>
              )}
        </div>
      </div>
      {(isUpdateMode || isContinue) && (
        <>
          <hr className="my-3" />
          <DiagnosisWrapper />
        </>
      )}
    </div>
  );
};
