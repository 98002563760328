import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { addNewSettleInvoicesPayment } from 'app/redux/patientPayments/helpers/addNewSettleInvoicesPayment';
import { deletePayment } from 'app/redux/patientPayments/helpers/deletePayment';
import { initializeSettleInvoicePayments } from 'app/redux/patientPayments/helpers/initializeSettleInvoicePayments';
import {
  AddSettleInvoicesPaymentPayload,
  InvoiceWithCalculatedRemaining,
  PatientPayment,
  PatientPaymentsState,
  PaymentType,
} from 'app/redux/patientPayments/types';

const initialState: PatientPaymentsState = {
  settleInvoices: {
    invoices: [],
    payments: [],
    paymentAllocations: [],
    paymentType: PaymentType.None,
    actualInvoices: [],
    invoiceValues: {},
    debtAmount: 0,
    remainingCredits: 0,
  },
  addAccountCredit: {
    payments: [],
  },
  withdrawAccountCredit: {
    payments: [],
  },
};

const patientPaymentsSlice = createSlice({
  name: 'patientPaymentsSlice',
  initialState,
  reducers: {
    // Settle Invoices
    initSettleInvoices: (
      state,
      action: PayloadAction<{ invoices: InvoiceWithCalculatedRemaining[]; remainingCredits: number }>,
    ) => {
      state.settleInvoices = {
        ...initialState.settleInvoices,
        ...initializeSettleInvoicePayments(action.payload),
      };
    },
    addSettleInvoicesPayment: (state, action: PayloadAction<AddSettleInvoicesPaymentPayload>) => {
      state.settleInvoices = {
        ...state.settleInvoices,
        ...addNewSettleInvoicesPayment(state.settleInvoices, action.payload),
      };
    },
    deleteSettleInvoicesPayment: (state, action: PayloadAction<{ paymentIndex: number }>) => {
      const { paymentIndex } = action.payload;
      state.settleInvoices = deletePayment(state.settleInvoices, paymentIndex);
    },
    resetSettleInvoicesPayments: (state) => {
      state.settleInvoices = initialState.settleInvoices;
    },

    // Add Account Credit
    addAccountCreditPayment: (state, action: PayloadAction<PatientPayment>) => {
      if (!action.payload.paymentMethod) return;

      const paymentId = uuid();
      state.addAccountCredit.payments = [...state.addAccountCredit.payments, { ...action.payload, paymentId }];
    },
    deleteAccountCreditPayment: (state, action: PayloadAction<{ paymentIndex: number }>) => {
      const { paymentIndex } = action.payload;
      state.addAccountCredit.payments = state.addAccountCredit.payments.filter((_, i) => i !== paymentIndex);
    },
    resetAccountCreditPayments: (state) => {
      state.addAccountCredit = initialState.addAccountCredit;
    },

    // Withdraw Account Credit
    addWithdrawAccountCreditPayment: (state, action: PayloadAction<PatientPayment>) => {
      const paymentId = uuid();
      state.withdrawAccountCredit.payments = [
        ...state.withdrawAccountCredit.payments,
        { ...action.payload, paymentId },
      ];
    },
    deleteWithdrawAccountCreditPayment: (state, action: PayloadAction<{ paymentIndex: number }>) => {
      const { paymentIndex } = action.payload;
      state.withdrawAccountCredit.payments = state.withdrawAccountCredit.payments.filter((_, i) => i !== paymentIndex);
    },
    resetWithdrawAccountCreditPayments: (state) => {
      state.withdrawAccountCredit = initialState.withdrawAccountCredit;
    },
  },
});

export const {
  initSettleInvoices,
  addSettleInvoicesPayment,
  deleteSettleInvoicesPayment,
  resetSettleInvoicesPayments,

  addAccountCreditPayment,
  deleteAccountCreditPayment,
  resetAccountCreditPayments,

  addWithdrawAccountCreditPayment,
  deleteWithdrawAccountCreditPayment,
  resetWithdrawAccountCreditPayments,
} = patientPaymentsSlice.actions;

export default patientPaymentsSlice.reducer;
