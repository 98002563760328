import { Col, Row } from 'reactstrap';
import { Box } from 'app/components';
import { SelectedDay } from 'app/redux/calendar/types';
import { CalendarItem } from 'app/types';
import { DoctorWorkingDayFormatter, TimeFormatter } from 'app/features/bookAppointment/bookAppointmentWizard/shared';

interface Props {
  item?: CalendarItem;
  onSelectDate: (day: SelectedDay) => void;
}

export const DoctorWorkingDaySlots = ({ item, onSelectDate }: Props) => {
  if (!item) {
    return <></>;
  }

  return (
    <Box style={{ overflowY: 'scroll' }}>
      <Row>
        <Col>
          <DoctorWorkingDayFormatter
            targetId="info-doctor-tooltip"
            doctor={item.doctor}
            item={item}
            onSelectDate={onSelectDate}
          />
        </Col>
        <Col>{TimeFormatter(null, item, -1, null)}</Col>
      </Row>
    </Box>
  );
};
