import { useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { BaseFieldProps, Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { useAppDispatch, useAppIntl, useAppSelector, useCurrentUser } from 'app/helpers';
import { AsyncSelectComponent, DatePickerComponent, DatePickerComponentProps, required } from 'app/shared';
import { reset as resetRemoteData } from 'app/redux/remoteData/remoteData.actions';
import { Option } from 'app/types';
import { FiltersFormData } from 'app/features/calendar/components/calendar/filters/types';
import { useFetchOptions } from 'app/features/calendar/components/calendar/helpers';

const rowProps = { lg: 3, md: 2, xs: 1 };

const FiltersForm = ({ change, form, handleSubmit, touch }: InjectedFormProps<FiltersFormData>) => {
  const { tabId } = useParams<{ tabId: string }>();
  const { formatMessage } = useAppIntl();
  const dispatch = useAppDispatch();
  const { isDoctor } = useCurrentUser();
  const minDate = useMemo(() => moment().startOf('day').toDate(), []);
  const maxDate = useMemo(() => moment().add(1, 'year').startOf('day').toDate(), []);
  const date: Date | undefined = useAppSelector((state) => state.form[form]?.values?.date);
  const branchId: string | undefined = useAppSelector((state) => state.form[form]?.values?.branch?.value);
  const doctors: Option[] | undefined = useAppSelector((state) => state.form[form]?.values?.doctors);
  const doctor: Option | undefined = useAppSelector((state) => state.form[form]?.values?.doctor);
  const specialityId: string | undefined | null = useAppSelector(
    (state) => state.form[form]?.values?.speciality?.value,
  );
  const subSpecialityId: string | undefined = useAppSelector((state) => state.form[form]?.values?.subSpeciality?.value);
  const isFullEdit = useAppSelector((state) => state.appointment.fullEdit.isActive);
  const clinicId = useAppSelector(
    (state) => state.appointment.rebook.data?.clinic.value || state.appointment.preselectedPatient?.clinicId,
  );
  const isWeekFiltersTab = tabId === 'week';

  const arrayOfDoctorIds: Option[] | undefined = useMemo(() => {
    if (!isWeekFiltersTab) return doctors;
    return doctor ? [doctor] : [];
  }, [doctor, doctors, isWeekFiltersTab]);

  const {
    fetchBranchOptions,
    fetchPractitionerOptions,
    fetchPractitionersOptions,
    fetchSpecialityOptions,
    fetchWeekSpecialityOptions,
    fetchSubSpecialityOptions,
    fetchServiceOptions,
  } = useFetchOptions(date, branchId, clinicId, specialityId, subSpecialityId, arrayOfDoctorIds);
  const onBranchChange = useCallback(() => {
    // It is not possible to have the following field values after changing Branch, that's why we reset these fields.
    change('speciality', null);
    change('subSpeciality', null);
    change('service', null);

    if (!isDoctor) {
      if (isWeekFiltersTab) {
        change('doctor', null);
        dispatch(resetRemoteData('weekCalendar'));
      } else {
        change('doctors', null);
      }
    }
  }, [dispatch, change, isDoctor, isWeekFiltersTab]);

  const onSpecialityChange = useCallback(() => {
    if (!isDoctor) {
      if (isWeekFiltersTab) {
        change('doctor', null);
        change('subSpeciality', null);
        change('service', null);
        dispatch(resetRemoteData('weekCalendar'));
      } else {
        change('doctors', null);
        change('subSpeciality', null);
        change('service', null);
      }
    }
  }, [dispatch, change, isDoctor, isWeekFiltersTab]);

  const onDoctorChange = useCallback(() => {
    if (!isDoctor) {
      change('service', null);
      if (isWeekFiltersTab) {
        dispatch(resetRemoteData('weekCalendar'));
      }
    }
  }, [dispatch, isDoctor, isWeekFiltersTab, change]);

  useEffect(() => {
    touch('branch');

    if (isWeekFiltersTab) {
      touch('doctor');
    }
  }, [isWeekFiltersTab, touch]);

  return (
    <Form onSubmit={handleSubmit}>
      <Row {...rowProps}>
        <Col className="d-block d-xl-none">
          <Field<BaseFieldProps<DatePickerComponentProps>>
            name="date"
            component={DatePickerComponent}
            props={{
              isRequired: true,
              label: formatMessage({ id: 'CORE.LABEL.DATE' }),
              maxDate: maxDate,
              minDate: minDate,
              placeholder: formatMessage({ id: 'CORE.PLACEHOLDER.SELECT-MONTH' }),
            }}
            validate={[required]}
          />
        </Col>
        <Col>
          <Field
            name="branch"
            component={AsyncSelectComponent}
            onChange={onBranchChange}
            props={{
              cacheUniqs: [clinicId],
              fetchOptions: fetchBranchOptions,
              isRequired: true,
              label: formatMessage({ id: 'CORE.TABLE.BRANCH' }),
              placeholder: formatMessage({ id: 'CORE.PLACEHOLDER.SELECT' }),
              showClinicyId: false,
            }}
            disabled={isFullEdit}
            validate={[required]}
          />
        </Col>
        <Col>
          <Field
            name="speciality"
            component={AsyncSelectComponent}
            onChange={onSpecialityChange}
            props={{
              cacheUniqs: [branchId, date],
              disabled: !branchId,
              fetchOptions: isWeekFiltersTab ? fetchWeekSpecialityOptions : fetchSpecialityOptions,
              isClearable: true,
              label: formatMessage({ id: 'CORE.LABEL.MEDICAL-SPECIALITY' }),
              placeholder: formatMessage({ id: 'CORE.PLACEHOLDER.SELECT' }),
              showClinicyId: false,
            }}
          />
        </Col>
        <Col>
          <Field
            name="subSpeciality"
            component={AsyncSelectComponent}
            onChange={onSpecialityChange}
            props={{
              cacheUniqs: [specialityId],
              disabled: !specialityId,
              fetchOptions: fetchSubSpecialityOptions,
              isClearable: true,
              label: formatMessage({ id: 'CORE.LABEL.MEDICAL-SERVICE' }),
              placeholder: formatMessage({ id: 'CORE.PLACEHOLDER.SELECT' }),
              showClinicyId: false,
            }}
          />
        </Col>
        <Col>
          <Field
            name="service"
            component={AsyncSelectComponent}
            props={{
              cacheUniqs: [subSpecialityId, arrayOfDoctorIds],
              disabled: !subSpecialityId,
              fetchOptions: fetchServiceOptions,
              isClearable: true,
              label: formatMessage({ id: 'CORE.TEXT.SERVICES' }),
              placeholder: formatMessage({ id: 'CORE.PLACEHOLDER.SELECT' }),
              showClinicyId: false,
            }}
          />
        </Col>
        <Col>
          {isWeekFiltersTab ? (
            <Field
              name="doctor"
              component={AsyncSelectComponent}
              onChange={onDoctorChange}
              props={{
                cacheUniqs: [branchId, date, specialityId, subSpecialityId],
                disabled: !branchId || isDoctor,
                fetchOptions: fetchPractitionerOptions,
                isClearable: true,
                isRequired: true,
                label: formatMessage({ id: 'CORE.LABEL.DOCTOR' }),
                placeholder: formatMessage({ id: 'CORE.PLACEHOLDER.SELECT' }),
                showClinicyId: false,
              }}
              validate={[required]}
            />
          ) : (
            <Field
              name="doctors"
              component={AsyncSelectComponent}
              onChange={onDoctorChange}
              props={{
                cacheUniqs: [branchId, date, specialityId, subSpecialityId],
                disabled: !branchId || isDoctor,
                fetchOptions: fetchPractitionersOptions,
                isClearable: true,
                isMulti: true,
                label: formatMessage({ id: 'CORE.LABEL.DOCTORS' }),
                placeholder: formatMessage({ id: 'CORE.PLACEHOLDER.SELECT' }),
                showClinicyId: false,
              }}
            />
          )}
        </Col>
      </Row>
    </Form>
  );
};

export const Filters = reduxForm<FiltersFormData>({
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
})(FiltersForm);
