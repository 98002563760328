import { useMemo } from 'react';
import uniq from 'lodash/uniq';
import moment from 'moment';
import { DayCalendar } from 'app/types';
import { CALENDAR_STEP, CALENDAR_TIMESLOTS } from 'app/features/calendar/components/calendar/calendar.constants';

export const useCalendarTimeLimit = (dayCalendar: DayCalendar[]) => {
  return useMemo((): { max: Date | undefined; min: Date | undefined } => {
    const timeFormat = 'HHmm';
    const hasConflictingAppointments = dayCalendar.some((dayCalendar) =>
      dayCalendar.appointments.some((appointment) => appointment.isConflicting),
    );

    if (hasConflictingAppointments) {
      // Show whole day because there are conflicting appointments
      return { max: undefined, min: undefined };
    }

    const workingTimes = dayCalendar.map((d) => d.workingTimes).flat();

    // Start and end times
    const startTimes = uniq(workingTimes.map((wt) => moment(wt.start).format(timeFormat))).sort();
    const endTimes = uniq(workingTimes.map((wt) => moment(wt.end).format(timeFormat))).sort();

    // Min and max
    let min = startTimes[0] ? moment(startTimes[0], timeFormat).toDate() : undefined;
    let max = endTimes[endTimes.length - 1] ? moment(endTimes[endTimes.length - 1], timeFormat).toDate() : undefined;
    const minByTimeSlotGroup = min && min.getMinutes() / (CALENDAR_STEP * CALENDAR_TIMESLOTS);

    if (minByTimeSlotGroup && !Number.isInteger(minByTimeSlotGroup)) {
      // Match min to time slot group size
      if (minByTimeSlotGroup > 1) {
        min = moment(min).set('minutes', 30).toDate();
      } else {
        min = moment(min).set('minutes', 0).toDate();
      }
    }

    if (workingTimes.some((wt) => !moment(wt.start).isSame(wt.end, 'day'))) {
      // Unset max if working time passes through midnight
      max = undefined;
    }

    return { max, min };
  }, [dayCalendar]);
};
