import { useCallback, useState } from 'react';
import { IconLayoutGridAdd } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import { useCurrentUser, useModalState, useQueryParams } from 'app/helpers';
import { BoxHeader, IconButton } from 'app/components';
import { RemoteBootstrapTable } from 'app/components/bootstrapTable/remoteBootstrapTable/remoteBootstrapTable';
import { ConfirmationModal } from 'app/shared';
import {
  useDeletePatientInsuranceDetailsMutation,
  useGetClinicInsuranceDetailsQuery,
} from 'app/services/patients/patients';
import { InsuranceDetailsItem } from 'app/types';
import { medicalInsuranceTableId } from 'app/features/patient/constants';
import { AddMedicalInsuranceModal } from 'app/features/patient/medicalInstitutionPatientProfile/insuranceDetails/modals/addMedicalInsuranceModal/addMedicalInsuranceModal';
import { EditMedicalInsuranceModal } from 'app/features/patient/medicalInstitutionPatientProfile/insuranceDetails/modals/editMedicalInsuranceModal/editMedicalInsuranceModal';
import { useColumns } from 'app/features/patient/patientInsuranceDetailsForm/helpers/useColumns';

interface Props {
  branchId?: string;
  clinicId?: string;
  isMergedOrPatientDeleted: boolean;
  patientId?: string;
}

export const InsuranceDetails = (props: Props) => {
  const urlParams = useParams<{ clinicId?: string; patientId: string }>();
  const clinicId = props.clinicId || urlParams.clinicId;
  const patientId = props.patientId || urlParams.patientId;
  const { isOpen, close, open } = useModalState();
  const { params, setParams } = useQueryParams('insuranceDetails');
  const [insuranceDetailsItem, setInsuranceDetailsItem] = useState<InsuranceDetailsItem | null>(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const openAddModal = () => setIsAddModalOpen(true);
  const toggleAddModal = () => setIsAddModalOpen(!isAddModalOpen);
  const toggleEditModal = () => setIsEditModalOpen(!isEditModalOpen);
  const { data, isLoading, isFetching } = useGetClinicInsuranceDetailsQuery({
    patientId,
    params: { ...params, clinicBranchId: props.branchId },
  });
  const [deleteInsuranceDetails, { isLoading: isDeleteInsuranceDetailsLoading }] =
    useDeletePatientInsuranceDetailsMutation();
  const {
    currentUser: { allowedForEditPatientProfile, allowedForInsuranceManagement },
  } = useCurrentUser();
  const addInsuranceIsVisible =
    allowedForEditPatientProfile && !props.isMergedOrPatientDeleted && allowedForInsuranceManagement;

  const onEdit = useCallback((insuranceDetailsItem: InsuranceDetailsItem) => {
    setInsuranceDetailsItem(insuranceDetailsItem);
    setIsEditModalOpen(true);
  }, []);

  const onDelete = useCallback(
    (insuranceDetailsItem: InsuranceDetailsItem) => {
      setInsuranceDetailsItem(insuranceDetailsItem);
      open();
    },
    [open],
  );

  const onCloseDeleteConfirmation = useCallback(() => {
    close();
  }, [close]);

  const onConfirmDelete = useCallback(async () => {
    if (insuranceDetailsItem && patientId)
      try {
        await deleteInsuranceDetails({
          patientId,
          insuranceId: insuranceDetailsItem.id,
        }).unwrap();
        toast.success('CORE.TEXT.ITEM-REMOVED-SUCCESSFULLY');
        close();
      } catch (e) {
        toast.error('CORE.TEXT.GENERAL-ERROR');
      }
  }, [close, deleteInsuranceDetails, insuranceDetailsItem, patientId]);

  const columns = useColumns({
    onEdit,
    onDelete,
    isMergedOrPatientDeleted: props.isMergedOrPatientDeleted,
  });

  return (
    <>
      <BoxHeader messageId="INSURANCES.TEXT.INSURANCE-DETAILS">
        {addInsuranceIsVisible && (
          <IconButton
            icon={<IconLayoutGridAdd size={22} strokeWidth={1.8} />}
            onClick={openAddModal}
            tooltipMessageId="INSURANCES.BUTTON.ADD"
          />
        )}
      </BoxHeader>
      <RemoteBootstrapTable
        classes="table-striped"
        columns={columns}
        data={data?.data || []}
        id={medicalInsuranceTableId}
        isFetching={isFetching}
        isLoading={isLoading}
        keyField="id"
        pagination={data?.pagination}
        params={params}
        setParams={setParams}
      />
      <AddMedicalInsuranceModal
        branchId={props.branchId}
        clinicId={clinicId}
        isOpen={isAddModalOpen}
        patientId={patientId}
        toggle={toggleAddModal}
      />
      <EditMedicalInsuranceModal
        branchId={props.branchId}
        clinicId={clinicId}
        isOpen={isEditModalOpen}
        patientId={patientId}
        toggle={toggleEditModal}
        patientInsuranceDetails={insuranceDetailsItem}
      />
      <ConfirmationModal
        description="CORE.TEXT.DELETE-CONFIRM-MESSAGE"
        disabled={isDeleteInsuranceDetailsLoading}
        isOpen={isOpen}
        onCancel={onCloseDeleteConfirmation}
        onClose={onCloseDeleteConfirmation}
        onConfirm={onConfirmDelete}
      />
    </>
  );
};
