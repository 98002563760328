import * as Sentry from '@sentry/browser';
import pick from 'lodash/pick';
import { configuration } from './configuration';

const findEmptyConfigVariables = (config) =>
  Object.entries(config).reduce((empty, [key, value]) => {
    if (!value) {
      return (empty += ` ${key}`);
    }
    return empty;
  }, '');

export const registerSentry = (config) => {
  //TODO - add 'release' to config elements when build env variables are added
  const emptyKeys = findEmptyConfigVariables(pick(config, ['dsn', 'environment']));

  if (!configuration.dsn) {
    return false;
  }

  if (!!emptyKeys.length) {
    console.warn(
      `Some configuration variables are not provided:%c${emptyKeys}. Sentry is not enabled`,
      'font-weight: bold',
    );
    return;
  }

  try {
    Sentry.init({
      dsn: config.dsn,
      release: config.release,
      environment: config.environment,
    });
  } catch (e) {
    console.error(e);
  }
};

export const reportMessage = (message, user = {}, extra = {}) => {
  Sentry.withScope((scope) => {
    Object.entries(extra).forEach(([key, value]) => {
      scope.setExtra(key, value);
    });
    scope.setUser({ id: user.id ? user.id : 'unknown', email: user.email ? user.email : 'unknown' });

    Sentry.captureMessage(message);
  });
};

export const reportException = (error, extra = {}) => {
  Sentry.withScope((scope) => {
    scope.setExtra('componentStack', extra);
  });

  Sentry.captureException(error);
};
