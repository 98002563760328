import React, { useCallback, useMemo } from 'react';
import uniqBy from 'lodash/uniqBy';
import { Modal } from 'reactstrap';
import { change } from 'redux-form';
import { useAppDispatch, useAppSelector, useModalCleaner } from 'app/helpers';
import { FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM } from 'app/shared';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { ICD10Code } from 'app/types';
import { InsuranceApprovalAddDiagnosisForm } from 'app/features/doctorAssessment/components/billingSection/forms/createInsuranceRequest/forms/addInsuranceDiagnosisForm';
import { AddInsuranceDiagnosisFormData } from 'app/features/doctorAssessment/components/billingSection/forms/createInsuranceRequest/forms/types';

export const AddDiagnosisModal = () => {
  const dispatch = useAppDispatch();

  // Selectors
  const insuranceId = useAppSelector((state) => state.doctorAssessment?.data?.billingSection?.patientInsuranceId);
  const diagnosisItems: ICD10Code[] | undefined = useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM]?.values?.icdTable,
  );

  // Conditions
  const isOpen = useAppSelector((state) => state.modal.addInsuranceDiagnosis.isOpen);

  // Helper Variables
  const diagnosisTableItems = useMemo(() => (diagnosisItems ? diagnosisItems : []), [diagnosisItems]);

  // Handlers
  const onToggle = useCallback(() => {
    dispatch(toggleModal('addInsuranceDiagnosis'));
  }, [dispatch]);

  const onSubmit = useCallback(
    (values: AddInsuranceDiagnosisFormData) => {
      const newValues = uniqBy(
        [
          ...diagnosisTableItems,
          ...values.icd10Code.map(
            (code): ICD10Code => ({
              icd10Code: code.label,
              description: code.details.description,
              isExcluded: code.details.isExcluded,
            }),
          ),
        ],
        'icd10Code',
      );
      dispatch(change(FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM, 'icdTable', newValues));
    },
    [diagnosisTableItems, dispatch],
  );

  const onSubmitSuccess = useCallback(() => {
    dispatch(toggleModal('addInsuranceDiagnosis', false));
  }, [dispatch]);

  useModalCleaner('addInsuranceDiagnosis');

  return (
    <Modal backdrop="static" keyboard={false} size="md" isOpen={isOpen} toggle={onToggle}>
      <InsuranceApprovalAddDiagnosisForm
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
        insuranceId={insuranceId}
      />
    </Modal>
  );
};
