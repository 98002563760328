import React from 'react';
import classnames from 'classnames';
import { useAppIntl } from 'app/helpers';
import styles from 'app/features/doctorAssessment/components/signaturePrintField/signaturePrintField.module.scss';

interface Props {
  doctorName?: string;
  signatureUrl?: string;
}

export const SignaturePrintField = ({ signatureUrl, doctorName }: Props) => {
  const { formatMessage } = useAppIntl();
  return (
    <div className={styles.pageBreak}>
      {signatureUrl && (
        <div className="d-flex flex-column align-items-end justify-content-center mt-5 w-100">
          <div className={classnames(styles.doctor, 'fw-bold')}>{formatMessage({ id: 'CORE.LABEL.SIGNATURE' })}:</div>
          <div className={styles.doctor}>{doctorName}</div>
          <div>
            <img src={signatureUrl} className={styles.signature} alt={`${doctorName} signature`} />
          </div>
        </div>
      )}
    </div>
  );
};
