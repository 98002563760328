import {
  PrescriptionSectionBodyParams,
  WrappedPrescriptionFormData,
} from 'app/features/doctorAssessment/components/prescriptionSection/types';

export const prescriptionValuesAdapter = (values: WrappedPrescriptionFormData[]): PrescriptionSectionBodyParams[] => {
  return values?.map((prescription) => ({
    prescriptionId: prescription.prescriptionId || undefined,
    assessmentPrescriptionDrugs: prescription.assessmentPrescriptionDrugs?.map((drug) => ({
      id: drug.id || undefined,
      drugId: drug.drugId || drug.sfdaCode?.value,
      drugScientificName: drug.drugScientificName.label,
      drugScientificNameId: drug.drugScientificName.value,
      drugTradeName: drug.drugTradeName?.label,
      drugDose: drug.drugDose && typeof drug.drugDose !== 'string' ? drug.drugDose.value : drug.drugDose,
      drugDoseUnit:
        drug.drugDoseUnit && typeof drug.drugDoseUnit !== 'string' ? drug.drugDoseUnit.label : drug.drugDoseUnit,
      drugRoute: drug.drugRoute?.value,
      drugFrequencyValue: drug.drugFrequencyValue,
      drugFrequencyUnit: drug.drugFrequencyUnit.value,
      drugDurationValue: drug.drugDurationValue,
      drugDurationUnit: drug.drugDurationUnit.value,
      drugIndication: drug.indication,
      drugOrderInstructions: drug.orderInstructions,
      prn: drug.prn?.value === 'true',
      sfdaCode: drug.sfdaCode?.label,
    })),
  }));
};
